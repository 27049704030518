import React, { useState } from "react";
import { FieldControl } from "react-reactive-form";
import info_tooltip from '../../assets/icons/Info-tooltip.svg';


const FormField = ({ name, label, id, placeholder, required, errorMessage, patternMessage, disabled, patternRequired, maxlength = 100, onlyCharacters, onlyNumbers, tooltipEnabled, tooltipContent, setMinValidation = false, setMaxValidation = false, maxValue, minValue }) => {

  return (
    <FieldControl
      name={name}
      render={({ handler, touched, hasError, meta }) => {
        const handlerProps = handler();

        const handleChange = (e) => {
          let rawValue = e.target.value;

          // Allow only letters and spaces, or numbers based on the condition
          if (onlyCharacters) {
            rawValue = rawValue.replace(/[^a-zA-Z\s]/g, ''); // Allow only letters and spaces
          } else if (onlyNumbers) {
            rawValue = rawValue.replace(/[^0-9]/g, ''); // Allow only numbers (digits)
          }

          e.target.value = rawValue;

          // Trigger the onChange handler
          if (handlerProps.onChange) {
            if (setMaxValidation && rawValue.replace(/[^0-9.-]/g, '') > maxValue) {
              handlerProps.onChange(maxValue.toString());
            } else if (setMinValidation && rawValue.replace(/[^0-9.-]/g, '') < minValue) {
              handlerProps.onChange("");
            } else {
              handlerProps.onChange(e.target.value);
            }
          };
        };

        return (
          <div className="form-group">
            <label htmlFor={name}>
              {label}{required && <span className="asterisk">*</span>}
              {
                tooltipEnabled && (
                  <span className="lm-tooltip ml-1"
                    data-tooltip={tooltipContent}
                    data-tooltip-pos="up" data-tooltip-length="cutsom"> <img src={info_tooltip} alt="" />
                  </span>
                )
              }
            </label>

            <input
              type="text"
              className="form-control"
              id={id}
              placeholder={placeholder}
              {...handler()}
              disabled={disabled ? true : false}
              maxLength={maxlength} // Set maxLength attribute on the input
              onChange={handleChange}
            />

            {/* Error messages below */}
            {required && touched && hasError("required") && <><span className="error-message">{`${errorMessage}`}</span><br /></>}
            {required && touched && hasError("pattern") && <span className="error-message">{`${patternMessage}`}</span>}
            {required && touched && hasError("email") && <span className="error-message">Email is incorrect</span>}

            {patternRequired && touched && hasError("invalidNumber") && <span className="error-message">{`${patternMessage}`}</span>}
            {required && touched && hasError("extraSpaces") && <span className="error-message">Please remove unwanted space</span>}
            {patternRequired && touched && hasError("contactNumberIssu") && <span className="error-message">{`${patternMessage}`}</span>}
            {patternRequired && touched && hasError("invalidEmail") && <span className="error-message">Email is incorrect</span>}
            {patternRequired && touched && hasError("zipCodeTooShort") && <span className="error-message">Zip code is incorrect</span>}

            {patternRequired && touched && hasError("mustBeUnique") && <span className="error-message">Value must be unique</span>}
            {patternRequired && touched && hasError("shouldNotEmpty") && <span className="error-message">Name is required</span>}

          </div>
        )
      }
      }
      meta={{ placeholder: placeholder }}
    />
  );
}

export default FormField;
