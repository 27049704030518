import React, { useState, useCallback, useEffect } from 'react';
import axios from 'axios';
import { Modal } from 'react-bootstrap';
import { useDispatch } from 'react-redux';
import reducersAndActions from '../../../_redux/slices';
import Constants from '../../../helpers/Constants';
import pass_eye from '../../../assets/img/pass_eye.svg';
import close_popup from '../../../assets/icons/close_popup.svg';
import axiosInstance from '../../../helpers/axiosConfig';
import { renderPasswordHelperText } from '../../../helpers/DynamicFunctions';
import warning_icon from '../../../assets/icons/warning_icon.svg';

const ChangePasswordModal = ({ changePassPopupShow, setChangePassPopup, userdetails }) => {
  const dispatch = useDispatch();

  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [preloader, setPreloader] = useState(false);

  useEffect(() => {
    dispatch(reducersAndActions.actions.sessionActions.setLoading(preloader));
  }, [preloader]);

  const [passwordsMatch, setPasswordsMatch] = useState(true);
  const [passwordErrors, setPasswordErrors] = useState([]);

  const [passwordValidations, setPasswordValidations] = useState({
    length: false,
    uppercase: false,
    lowercase: false,
    numeric: false,
    special: false,
  });

  const showPassword = (inputId, eyeId) => {
    const input = document.getElementById(inputId);
    const eyeIcon = document.getElementById(eyeId);
    input.type = input.type === 'password' ? 'text' : 'password';
    eyeIcon.classList.toggle('active_pass');
  };

  const closeModal = () => setChangePassPopup(false);

  const validatePassword = (password) => {
    const validations = {
      length: password.length >= 8,
      uppercase: /[A-Z]/.test(password),
      lowercase: /[a-z]/.test(password),
      numeric: /\d/.test(password),
      special: /[@$%^&*!#%]/.test(password),
    };

    setPasswordValidations(validations);
    setPasswordErrors(Object.entries(validations).filter(([_, valid]) => !valid).map(([rule]) => rule));
  };

  const handleSave = async () => {
    if (password !== confirmPassword) {
      setPasswordsMatch(false);
      return;
    }

    if (passwordErrors.length > 0) {
      console.error('Validation errors:', passwordErrors);
      return;
    }

    setPreloader(true);
    const storedUserId = localStorage.getItem('user');
    const parsedUserId = JSON.parse(storedUserId);
    const storedSessionId = localStorage.getItem('sessionId');

    if (storedUserId && parsedUserId && storedSessionId) {
      const apiUrl = '/api/User/ChangePassword';
      const queryParams = `?userId=${userdetails?.id}&NewPassword=${encodeURIComponent(password)}&RoleName=${userdetails?.roleName}&SessionId=${storedSessionId}`;

      try {
        const response = await axiosInstance.post(`${apiUrl}${queryParams}`);
        const { status, message } = response.data;
        if (status === 200) {
          dispatch(reducersAndActions.actions.toasterActions.showToaster({ visible: true, message, type: "success" }));
          setTimeout(() => dispatch(reducersAndActions.actions.toasterActions.hideToaster()), Constants.TOASTERHIDETIME);
          closeModal();
        }
      } catch (error) {
        dispatch(reducersAndActions.actions.toasterActions.showToaster({ visible: true, message: error.response?.data?.message || 'An error occurred', type: "error" }));
        setTimeout(() => dispatch(reducersAndActions.actions.toasterActions.hideToaster()), Constants.TOASTERHIDETIME);
        console.error('Error:', error);
      } finally {
        setPreloader(false);
      }
    } else {
      console.error('User ID, Session ID, or token is not available');
    }
  };

  const handlePasswordChange = useCallback((e) => {
    const newPassword = e.target.value;
    setPassword(newPassword);
    validatePassword(newPassword);
  }, []);

  const handleConfirmPasswordChange = useCallback((e) => {
    const newConfirmPassword = e.target.value;
    setConfirmPassword(newConfirmPassword);
    setPasswordsMatch(newConfirmPassword === password);
  }, [password]);

  return (
    <Modal
      size="md"
      show={changePassPopupShow}
      onHide={closeModal}
      aria-labelledby="changePassPopupLabel"
      backdrop="static"
      keyboard={false}
      centered
    >
      <div className="modal-content">
        <div className="modal-header">
          <h5 className="modal-title" id="ChangePasswordPopupLabel">Change Password</h5>
          <button type="button" className="close" onClick={closeModal}>
            <span aria-hidden="true"><img src={close_popup} alt="Close" /></span>
          </button>
        </div>
        <form>
          <Modal.Body>
            {
              password.length > 0 && !passwordsMatch && (
                <div className="password-match-toast d-flex justify-content-center">
                  <img className="mr-2" src={warning_icon} alt="" />
                  Passwords must match
                </div>
              )
            }

            {
              passwordErrors.length > 0 && (
                <div className="password-match-toast d-flex justify-content-center">
                  <img className="mr-2" src={warning_icon} alt="" />
                  Password validations failed
                </div>
              )
            }

            <div className="form-group input-password">
              <label htmlFor="password">Password</label>
              <input
                type="password"
                className="form-control"
                id="password"
                value={password}
                onChange={handlePasswordChange}
                placeholder="Enter a password"
              />
              <img
                onClick={() => showPassword("password", "pass_eye")}
                id="pass_eye"
                className="pass_eye"
                src={pass_eye}
                alt="Toggle Password Visibility"
              />
              {renderPasswordHelperText(passwordValidations)}
            </div>
            <div className="form-group input-password">
              <label htmlFor="confirm-password">Confirm Password</label>
              <input
                type="password"
                className="form-control"
                id="confirm-password"
                value={confirmPassword}
                onChange={handleConfirmPasswordChange}
                placeholder="Confirm your password"
              />
              <img
                onClick={() => showPassword("confirm-password", "confirm_pass_eye")}
                id="confirm_pass_eye"
                className="pass_eye"
                src={pass_eye}
                alt="Toggle Confirm Password Visibility"
              />
            </div>
          </Modal.Body>
          <div className="modal-footer">
            <button type="button" className="lm-button2" onClick={closeModal}>Cancel</button>
            <button type="button" className="lm-button1" onClick={handleSave}>Save</button>
          </div>
        </form>
      </div>
    </Modal>
  );
};

export default ChangePasswordModal;
