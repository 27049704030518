
import axios from '../helpers/axiosConfig';

export function convertToFormData(data) {
    const formData = new FormData();
    for (const element of Object.keys(data)) {
        const key = element;
        formData.append(key, data[key]);
    }
    return formData;
}

export const GetsNotesListByLoanId = async (data) => {
    try {
        const response = await axios.get(`/api/LoanApplication/GetNotesListByLoanId`, { params: data });
        if (response.data) {
            return response.data;
        }
    } catch (err) {
        console.log(err);
        throw err;
    }
}

export const GetsMyProfile = async (data) => {
    try {
        const response = await axios.get(`/api/User/MyProfile`, { params: data });
        if (response.data) {
            return response.data;
        }
    } catch (err) {
        console.log(err);
        throw err;
    }
}

