import React, { useState, useEffect, useCallback, useMemo } from 'react';
import Select from 'react-select';
import DatePicker from "react-datepicker";
import moment from 'moment';
import { useDispatch, useSelector } from 'react-redux';
import debounce from 'lodash.debounce';

import no_data_icon from '../assets/img/no_data_icon.svg';
import { SidePanel, ProfileHeader } from "@components";
import Preloader from '../plugins/Preloader';
import { LogsHeader } from '../helpers/DropdownsAndMappers';
import DynamicTableLogs from '../plugins/DynamicTableLogs';
import { GetLMLogList, GetUserList } from '../services/logsServices';
import './styles/Users.css';
import Constants from "../helpers/Constants";
import reducersAndActions from "../_redux/slices/index";
import { AllcheckformatDate } from './loan-app-tabs/personalProfile/DatesCheck';

const typeOptions = [
    { value: -1, label: "All Types" },
    { value: 1, label: 'Login' },
    { value: 2, label: 'Other' },
];

const roleNameOptions = [
    { value: "Admin", label: "All Types" },
    { value: 'Admin', label: 'Admin' },
    { value: 'Advisor', label: 'Advisors' },
    { value: 'Borrower', label: 'Borrower' },
    { value: 'Partner', label: 'Partner' },
    { value: 'Lender', label: 'Lender' },
    { value: 'BusinessPartnerOwner', label: 'Business Partner Owner' },
];

const roleTypeMapping = {
    'Admin': 1,
    'Advisor': 2,
    'Partner': 4,
    'Borrower': 10,
    'Lender': 14,
    'BusinessPartnerOwner': 15,
};

const formatDateTime = (dateTimeString) => {
    return moment.utc(dateTimeString).local().format('MMM D, YYYY, h:mm A');
};

const formatDate = (date) => {
    return date ? moment(date).format('MM/DD/YYYY') : '';
};

const useFetchData = (initialFetchFunction, dependencies = [], dispatch) => {
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        dispatch(reducersAndActions.actions.sessionActions.setLoading(loading));
    }, [loading]);

    const [data, setData] = useState([]);
    useEffect(() => {
        const fetchData = async () => {
            setLoading(true);
            const result = await initialFetchFunction();
            setData(result || []);
            setLoading(false);
        };
        fetchData();
    }, dependencies);

    return [data, loading];
};

function Logs() {
    const dispatch = useDispatch();
    const user = useSelector((state) => state.auth.user);

    const [searchText, setSearchText] = useState("");
    const [selectedType, setSelectedType] = useState(typeOptions[0]);
    const [selectedUserType, setSelectedUserType] = useState(roleNameOptions[0]);
    const [selectValue, setSelectValue] = useState({ value: '-1', label: 'All users' });
    const [dateRange, setDateRange] = useState([null, null]);
    const [pageIndex, setPageIndex] = useState(1);
    const [pageSize, setPageSize] = useState(10);
    const [totalData, setTotalData] = useState(0);
    const [data, setData] = useState([]);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        dispatch(reducersAndActions.actions.sessionActions.setLoading(loading));
    }, [loading]);
    // Add loading state


    const handleDate = useCallback(() => {
        const startDateFormatted = dateRange[0] ? AllcheckformatDate(moment(dateRange[0]).format('YYYY-MM-DD')) : null;
        const endDateFormatted = dateRange[1] ? AllcheckformatDate(moment(dateRange[1]).format('YYYY-MM-DD')) : null;
        return {
            startDateNew: startDateFormatted,
            endDateNew: endDateFormatted,
        };
    }, [dateRange]);

    const fetchUsers = async () => {
        const storedSessionId = localStorage.getItem('sessionId');
        const response = await GetUserList({
            PageIndex: 1,
            PageSize: 500,
            roleName: selectedUserType.value !== 'All' ? selectedUserType.value : user.roleName,
            SessionId: storedSessionId,
        });
        return response?.resultObject || [];
    };

    const fetchInquiries = async () => {
        try {
            const storedUserId = JSON.parse(localStorage.getItem('user'))?.id;
            const storedSessionId = localStorage.getItem('sessionId');

            const queryObj = {
                PageIndex: pageIndex,
                PageSize: pageSize,
                UserId: storedUserId,
                RoleName: user.roleName,
                DateFrom: handleDate().startDateNew || formatDate(moment().subtract(1, 'months').endOf('month')),
                DateTo: handleDate().endDateNew || formatDate(new Date()),
                type: selectedType.value !== -1 ? selectedType.value : -1,
                IsBulk: 0,
                SelectedUser: selectValue.value !== '-1' ? selectValue.value : "-1",
                SessionId: storedSessionId,
                RoleType: roleTypeMapping[selectedUserType.value] || -1,
                SearchText: searchText,
            };

            const response = await GetLMLogList(queryObj);

            if (response?.status === 404) {
                dispatch(
                    reducersAndActions.actions.toasterActions.showToaster({
                        visible: true,
                        message: "Resource not found. Please check the URL or try again later.",
                        type: "error",
                    })
                );
                setTimeout(
                    () => dispatch(reducersAndActions.actions.toasterActions.hideToaster()),
                    Constants.TOASTERHIDETIME
                );
                return;
            }

            const totalRecords = response?.message.match(/Total (\d+) Records/)?.[1] || 0;
            setTotalData(Number(totalRecords));

            return JSON.parse(response?.resultObject || '[]');
        } catch (error) {
            dispatch(
                reducersAndActions.actions.toasterActions.showToaster({
                    visible: true,
                    message: "An error occurred while fetching data. Please try again later.",
                    type: "error",
                })
            );
            setTimeout(
                () => dispatch(reducersAndActions.actions.toasterActions.hideToaster()),
                Constants.TOASTERHIDETIME
            );
            return;
        } finally {
            setLoading(false); // Reset loading state
        }
    };

    // Debounce function for search input
    const debouncedFetchInquiries = useCallback(debounce(async () => {
        setLoading(true); // Set loading state
        const inquiries = await fetchInquiries();
        setData(inquiries);
        setLoading(false); // Reset loading state
    }, 1000), [searchText, selectedType, selectedUserType, selectValue, pageIndex, pageSize, dateRange]);

    const [users, usersLoading] = useFetchData(fetchUsers, [selectedUserType], dispatch);

    useEffect(() => {
        if (dateRange[0] && dateRange[1]) {
            fetchInquiries();
        }
    }, [dateRange]);

    useEffect(() => {
        debouncedFetchInquiries();
        return () => {
            debouncedFetchInquiries.cancel();
        };
    }, [debouncedFetchInquiries]);

    const userOptions = useMemo(() => [
        { value: '-1', label: 'All users' },
        ...users.map(user => ({ value: user.id, label: `${user.firstName} ${user.lastName}` }))
    ], [users]);

    const dynamicTableDisplay = (data) => (
        <tr className={data.statusDesc === "Active" ? "active" : "inactive"} key={data.id}>
            <td><b className="user_name">{data.Name || "NA"}</b></td>
            <td>{data.BusinessName || "NA"}</td>
            <td>{data.UserType || "NA"}</td>
            <td>{data.applicationNumber || "NA"}</td>
            <td>{formatDateTime(data.DATE)}</td>
            <td className="text-light">{data.Details || "NA"}</td>
        </tr>
    );

    const handleSetList = (newList) => {
        setData(newList);
    };

    const handlePageIndexChange = (newPageIndex) => {
        setLoading(true); // Set loading state
        setPageIndex(newPageIndex);
    };

    return (
        <>
            <SidePanel />
            <ProfileHeader />

            {usersLoading || loading ? <Preloader /> : (
                <div>
                    <div className="dashboard-wrap users-screen">
                        <div className="d-flex justify-content-between filter-search-group">
                            <div className='d-flex'>
                                {user.roleName === 'Admin' && (
                                    <>
                                        <Select
                                            className="basic-single mr-3"
                                            classNamePrefix="select"
                                            menuPortalTarget={document.body}
                                            styles={{ menuPortal: base => ({ ...base, zIndex: 9999 }) }}
                                            options={typeOptions}
                                            value={selectedType}
                                            onChange={(e) => {
                                                setPageIndex(1);
                                                setSelectedType(e);
                                            }}
                                        />
                                        <Select
                                            className="basic-single mr-3"
                                            classNamePrefix="select"
                                            menuPortalTarget={document.body}
                                            styles={{ menuPortal: base => ({ ...base, zIndex: 9999 }) }}
                                            options={roleNameOptions}
                                            value={selectedUserType}
                                            onChange={(e) => {
                                                setPageIndex(1);
                                                setSelectedUserType(e);
                                            }}
                                        />
                                        <Select
                                            className="basic-single"
                                            classNamePrefix="select"
                                            menuPortalTarget={document.body}
                                            styles={{ menuPortal: base => ({ ...base, zIndex: 9999 }) }}
                                            value={selectValue}
                                            options={userOptions}
                                            onChange={(e) => {
                                                setPageIndex(1);
                                                setSelectValue(e);
                                            }}
                                        />
                                    </>
                                )}
                            </div>
                            <div className='d-flex' style={user.roleName !== 'Admin' ? { marginLeft: '55%' } : {}}>
                                <div className="input-date two-month-datepicker mr-0 mr-sm-3">
                                    <DatePicker
                                        showIcon
                                        toggleCalendarOnIconClick
                                        selected={dateRange[0]}
                                        selectsRange={true}
                                        startDate={dateRange[0]}
                                        endDate={dateRange[1]}
                                        onChange={(update) => setDateRange(update)}
                                        onCalendarClose={() => {
                                            if (dateRange[0] && dateRange[1]) {
                                                setPageIndex(1);
                                                fetchInquiries();
                                            }
                                        }}
                                        onKeyDown={(e) => {
                                            e.preventDefault();
                                        }}
                                        monthsShown={2}
                                        isClearable={true}
                                        dateFormat="MMM d, yyyy"
                                        placeholderText="Month DD, YYYY - Month DD, YYYY"
                                    />
                                </div>
                                <div className="lm-search-bar">
                                    <input
                                        type="text"
                                        id="searchInput"
                                        placeholder="Search by number, name, or other"
                                        value={searchText}
                                        onChange={(e) => {
                                            setSearchText(e.target.value)
                                            setPageIndex(1)
                                        }}
                                    />
                                </div>
                            </div>
                        </div>
                        {data.length > 0 ? (
                            <DynamicTableLogs
                                data={data}
                                dynamicTableDisplay={dynamicTableDisplay}
                                columns={LogsHeader}
                                changePageSize={setPageSize}
                                pageSize={pageSize}
                                setList={handleSetList}
                                pageIndex={pageIndex}
                                onPageIndexChange={handlePageIndexChange} // Use the new handler
                                setPageIndex={setPageIndex}
                                total={totalData}
                            />
                        ) : (
                            <div className="text-center no-data-table">
                                <img src={no_data_icon} alt="no-data" className="m-auto" />
                                <p className="text-light mt-3">No Data Found</p>
                            </div>
                        )}
                    </div>
                </div>
            )}
        </>
    );
}

export default Logs;