import { useEffect, useState } from "react";
import Select from "react-select";
import Dropdown from 'react-bootstrap/Dropdown'
import { get12HourTimeOptions, get24HourTimeOptions } from "./TimeOptionsData";
import { useDispatch } from "react-redux";
import reducersAndActions from "../../_redux/slices";
import Constants from "../../helpers/Constants";

const WeeklyTimeComponent = ({ weekday, value = {}, setDayTimeSchedule, industryType, serviceType = "" }) => {
    const dispatch = useDispatch();

    const [isDropdownOpen, setDropdownOpen] = useState(false);

    const [timeFormate, setTimeFormate] = useState('12-hour');
    const [isWeekdayEnable, setIsWeekdayEnable] = useState(true);
    const [timeOptionData, setTimeOptionData] = useState(get12HourTimeOptions);

    const [selectedFromTime, setSelectedFromTime] = useState();
    const [selectedFromAmPm, setSelectedFromAmPm] = useState();
    const [selectedToTime, setSelectedToTime] = useState();
    const [selectedToAmPm, setSelectedToAmPm] = useState();

    useEffect(() => {
        const dayData = value[weekday];
        if (Object.keys(dayData).length === 0) {
            updateTimeSchedule("09:00 AM", "06:00 PM", "12:00", true, weekday);
        } else {
            setIsWeekdayEnable(dayData.status);
            setSelectedFromTime(dayData?.startDate.split(' ')[0]);
            setSelectedFromAmPm(dayData?.startDate.split(' ')[1]);
            setSelectedToTime(dayData?.endDate.split(' ')[0]);
            setSelectedToAmPm(dayData?.endDate.split(' ')[1]);
            if (dayData?.timeHour === '24:00') {
                setTimeFormate('24-hour');
                setTimeOptionData(get24HourTimeOptions);
                setSelectedTimeRange(`${dayData?.startDate.split(' ')[0]} - ${dayData?.endDate.split(' ')[0]}`)
            } else {
                setTimeFormate('12-hour');
                setTimeOptionData(get12HourTimeOptions);
                setSelectedTimeRange(`${dayData?.startDate} - ${dayData?.endDate}`)
            }
        }
    }, [value]);

    const checkForTimeValidation = ({
        from = selectedFromTime,
        to = selectedToTime,
        fap = selectedFromAmPm,
        tap = selectedToAmPm,
        updateValueIn,
        value,
    }) => {
        const parse12HourTime = (time, period) => {
            const [hours, minutes] = time.split(":").map(num => parseInt(num, 10));
            if (isNaN(hours) || isNaN(minutes)) {
                return null; // Invalid time
            }
            let totalMinutes = hours * 60 + minutes;
            if (period === "PM" && hours !== 12) {
                totalMinutes += 12 * 60; // Add 12 hours for PM, except for 12 PM
            } else if (period === "AM" && hours === 12) {
                totalMinutes -= 12 * 60; // Adjust for 12 AM (midnight)
            }
            return totalMinutes;
        };

        const parse24HourTime = time => {
            const [hours, minutes] = time.split(":").map(num => parseInt(num, 10));
            if (isNaN(hours) || isNaN(minutes)) {
                return null; // Invalid time
            }
            return hours * 60 + minutes;
        };

        const fromTimeInMinutes = timeFormate === "12-hour" ? parse12HourTime(from, fap) : parse24HourTime(from);
        const toTimeInMinutes = timeFormate === "12-hour" ? parse12HourTime(to, tap) : parse24HourTime(to);

        // Validate time range
        if (fromTimeInMinutes >= toTimeInMinutes) {
            dispatch(reducersAndActions.actions.toasterActions.showToaster({ visible: true, message: "From time should be less than To time.", type: "error", }));
            setTimeout(() => dispatch(reducersAndActions.actions.toasterActions.hideToaster()), Constants.TOASTERHIDETIME);
            return;
        }

        // Update value if validation passes
        updateValueIn(value);
    };


    const getDefaultTime = () => {
        const time = timeFormate === '12-hour' ? `${selectedFromTime} ${selectedFromAmPm} - ${selectedToTime} ${selectedToAmPm}` : `${selectedFromTime} - ${selectedToTime}`;
        return time;
    }
    const [selectedTimeRange, setSelectedTimeRange] = useState(getDefaultTime());

    const AmPmOptions = [
        { value: 'AM', label: 'AM' },
        { value: 'PM', label: 'PM' }
    ]

    const handleConfirmDateRange = () => {
        const time = timeFormate === '12-hour' ? `${selectedFromTime} ${selectedFromAmPm} - ${selectedToTime} ${selectedToAmPm}` : `${selectedFromTime} - ${selectedToTime}`;

        const StartDate = timeFormate === '12-hour' ? `${selectedFromTime} ${selectedFromAmPm}` : selectedFromTime
        const EndDate = timeFormate === '12-hour' ? `${selectedToTime} ${selectedToAmPm}` : selectedToTime
        const TimeHour = timeFormate === '12-hour' ? "12:00" : "24:00"
        const Status = true

        updateTimeSchedule(StartDate, EndDate, TimeHour, Status, weekday);
        setSelectedTimeRange(time)
        toggleDropdown();
    }

    const toggleDropdown = () => {
        setDropdownOpen((prevState) => !prevState);
    };

    const clearAllDateRange = () => {
        setTimeFormate('12-hour');
        setTimeOptionData(get12HourTimeOptions);
        setSelectedFromTime("09:00");
        setSelectedFromAmPm("AM");
        setSelectedToTime("06:00");
        setSelectedToAmPm("PM");
    }

    const convertTimeAccordingToFormat = (format) => {
        if (format === '24-hour') {
            // Convert 24-hour to 12-hour format
            const [fromHour, fromMinute] = selectedFromTime.split(':');
            const fromAmpm = parseInt(fromHour) >= 12 ? 'PM' : 'AM';
            const newFromHour = parseInt(fromHour) % 12 || 12; // Adjust for 12-hour format

            const [toHour, toMinute] = selectedToTime.split(':');
            const toAmpm = parseInt(toHour) >= 12 ? 'PM' : 'AM';
            const newToHour = parseInt(toHour) % 12 || 12;

            setSelectedFromTime(`${newFromHour}:${fromMinute}`);
            setSelectedFromAmPm(fromAmpm);
            setSelectedToTime(`${newToHour}:${toMinute}`);
            setSelectedToAmPm(toAmpm);
        } else {
            // Convert 12-hour to 24-hour format
            const [fromHour, fromMinute] = selectedFromTime.split(':');
            const fromNewHour =
                selectedFromAmPm === 'PM' && parseInt(fromHour) !== 12
                    ? parseInt(fromHour) + 12
                    : selectedFromAmPm === 'AM' && parseInt(fromHour) === 12 ? 0 : parseInt(fromHour);

            const [toHour, toMinute] = selectedToTime.split(':');
            const toNewHour =
                selectedToAmPm === 'PM' && parseInt(toHour) !== 12
                    ? parseInt(toHour) + 12
                    : selectedToAmPm === 'AM' && parseInt(toHour) === 12 ? 0 : parseInt(toHour);

            setSelectedFromTime(`${String(fromNewHour).padStart(2, '0')}:${fromMinute}`);
            setSelectedToTime(`${String(toNewHour).padStart(2, '0')}:${toMinute}`);
        }
    };

    const updateTimeSchedule = (startDate, endDate, timeHour, status, weekday) => {
        const previousWeekData = {
            ...value[weekday],
            id: value[weekday].id || 0,
            industryId: value[weekday].industryId || 0,
            serviceType: value[weekday]?.serviceType || serviceType,
            industryType: value[weekday].industryType || industryType,
        };
        setDayTimeSchedule((prev) => ({ ...prev, [weekday]: { ...previousWeekData, startDate, endDate, timeHour, status, daysName: weekday, serviceType: previousWeekData.serviceType } }));
    }


    return (
        <>
            <div className='col-3'>
                <div className='operation-weeks mt-3'>
                    <div className='operation-day'>
                        <div className="lm-toggle-switch d-flex mb-2">
                            <input
                                type="checkbox"
                                id={weekday + "_" + serviceType}
                                checked={isWeekdayEnable}
                                onChange={(e) => {
                                    setIsWeekdayEnable((prev) => !prev);
                                    if (!e.target.checked) {
                                        clearAllDateRange();
                                        setSelectedTimeRange("");
                                        updateTimeSchedule("", "", "", false, weekday);
                                    } else {
                                        setSelectedFromTime("09:00");
                                        setSelectedFromAmPm("AM");
                                        setSelectedToTime("06:00");
                                        setSelectedToAmPm("PM");
                                        setSelectedTimeRange("09:00 AM - 06:00 PM");
                                        updateTimeSchedule("09:00 AM", "06:00 PM", "12:00", true, weekday);
                                    }
                                }}
                            />
                            <label htmlFor={weekday + "_" + serviceType}></label>
                            <b className='ml-2 day-label'>{weekday.slice(0, 1).toLocaleUpperCase() + weekday.slice(1)}</b>
                        </div>
                        {
                            isWeekdayEnable && (
                                <Dropdown show={isDropdownOpen} onToggle={() => setDropdownOpen(false)}>
                                    <Dropdown.Toggle onClick={toggleDropdown}>
                                        <input className='form-control time-range-picker' value={selectedTimeRange} />
                                    </Dropdown.Toggle>
                                    <Dropdown.Menu>
                                        <div className='timepicker-box'>
                                            <div className='d-flex justify-content-between'>
                                                <div className="lm-toggle-switch d-flex mt-2 mb-2">
                                                    <input
                                                        type="checkbox"
                                                        id={`${weekday + ' 24Hour ' + serviceType}`}
                                                        checked={timeFormate === '24-hour'}
                                                        onChange={(e) => {
                                                            if (e.target.checked) {
                                                                setTimeFormate('24-hour');
                                                                setTimeOptionData(get24HourTimeOptions);
                                                                convertTimeAccordingToFormat('12-hour');
                                                            } else {
                                                                setTimeFormate('12-hour');
                                                                setTimeOptionData(get12HourTimeOptions);
                                                                convertTimeAccordingToFormat('24-hour');
                                                            }
                                                        }}
                                                    />
                                                    <label htmlFor={`${weekday + ' 24Hour ' + serviceType}`}></label>
                                                    <b className='ml-2 day-label'>24 Hours</b>
                                                </div>
                                                <a className='clear-time-button mt-2' onClick={clearAllDateRange}>
                                                    Clear All
                                                </a>
                                            </div>
                                            <div className='d-flex'>
                                                <div>
                                                    <label>From</label>
                                                    <div className='d-flex gap-10'>
                                                        <Select
                                                            className="basic-single"
                                                            classNamePrefix="select"
                                                            id="fromTime"
                                                            name="fromTime"
                                                            options={timeOptionData.filter(opt => opt.value !== '11:59' || opt.value !== '23:59')}
                                                            defaultValue={timeOptionData[0]}
                                                            value={timeOptionData.find(option => option.value === selectedFromTime)}
                                                            onChange={(e) => {
                                                                checkForTimeValidation({ from: e.value, updateValueIn: setSelectedFromTime, value: e.value });
                                                            }}
                                                        />
                                                        {
                                                            timeFormate === '12-hour' &&
                                                            <Select
                                                                className="basic-single"
                                                                classNamePrefix="select"
                                                                id="fromAmPm"
                                                                name="fromAmPm"
                                                                options={AmPmOptions}
                                                                defaultValue={AmPmOptions[0]}
                                                                value={AmPmOptions.find(option => option.value === selectedFromAmPm)}
                                                                onChange={(e) => {
                                                                    checkForTimeValidation({ fap: e.value, updateValueIn: setSelectedFromAmPm, value: e.value });
                                                                }}
                                                            />
                                                        }

                                                    </div>
                                                </div>
                                                <div className='place-content-end pb-1 mx-3'>
                                                    -
                                                </div>
                                                <div>
                                                    <label>To</label>
                                                    <div className='d-flex gap-10'>
                                                        <Select
                                                            className="basic-single"
                                                            classNamePrefix="select"
                                                            id="toTime"
                                                            name="toTime"
                                                            options={timeOptionData}
                                                            defaultValue={timeOptionData[0]}
                                                            value={timeOptionData.find(option => option.value === selectedToTime)}
                                                            onChange={(e) => {
                                                                checkForTimeValidation({ to: e.value, updateValueIn: setSelectedToTime, value: e.value });
                                                            }}
                                                        />
                                                        {
                                                            timeFormate === '12-hour' &&
                                                            <Select
                                                                className="basic-single"
                                                                classNamePrefix="select"
                                                                id="toAmPm"
                                                                name="toAmPm"
                                                                options={AmPmOptions}
                                                                defaultValue={AmPmOptions[0]}
                                                                value={AmPmOptions.find(option => option.value === selectedToAmPm)}
                                                                onChange={(e) => {
                                                                    checkForTimeValidation({ tap: e.value, updateValueIn: setSelectedToAmPm, value: e.value });
                                                                }}
                                                            />
                                                        }
                                                    </div>
                                                </div>
                                            </div>
                                            <button type="button" className="lm-button2 w-100 mt-3" onClick={handleConfirmDateRange}>
                                                Confirm
                                            </button>
                                        </div>
                                    </Dropdown.Menu>
                                </Dropdown>
                            )
                        }
                    </div>
                </div>
            </div>
        </>
    );
}

export default WeeklyTimeComponent;