import pass_sugn_tick from '../../src/assets/icons/pass-sugn-tick.svg';
import pass_sugn_cross from '../../src/assets/icons/pass-sugn-cross.svg';

const secretKey = 'ba3be1d6cdc455d085fabeafa3b5f12c'; // Replace 'your_secret_key' with the actual secret key

export const formatNumberWithCommas = (number) => {
  if (number) {
    let flagCheck = (typeof number == "string" ? number.includes(".") : number.toString().includes("."));
    if (flagCheck) {
      let [integerPart, decimalPart] = number.toString().split('.');
      integerPart = integerPart.replace(/\B(?=(\d{3})+(?!\d))/g, ',');
      if (decimalPart) {
        // limit the decimalPart digits upto 2 is its more than 2
        if (decimalPart.length > 2) {
          decimalPart = decimalPart.substring(0, 2);
        }
        return integerPart + "." + decimalPart;
      } else {
        return integerPart + ".";
      }
    } else {
      let integerPart = number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
      return integerPart;
    }
  } else {
    return "";
  }
}

export const FormatPhoneNumber = (number) => {
  const output = number.replace(/^(\d{0,3})(\d{0,3})(\d{0,4})$/, (match, p1, p2, p3) => {
    let formatted = '';
    if (p1) formatted += `(${p1}`;
    if (p1.length === 3) formatted += ') ';
    if (p2) formatted += p2;
    if (p2.length === 3) formatted += '-';
    if (p3) formatted += p3;
    return formatted;
  });
  return output;
}

export const getInitials = (name) => {
  const words = name.split(' ');
  const initials = words.map(word => word[0].toUpperCase()).join('');
  return initials;
}

// Function to encrypt text
export const encryptText = (text) => {
  let key = secretKey;
  let result = '';
  if (text) {
    for (let i = 0; i < text.length; i++) {
      const charCode = text.charCodeAt(i) ^ key.charCodeAt(i % key.length);
      result += String.fromCharCode(charCode);
    }
    // Convert the encrypted result to Base64 to remove special characters
    return btoa(unescape(encodeURIComponent(result))); // Encode result to Base64 and make it URL-safe
  }
};

// Function to decrypt text
export const decryptText = (encryptedText) => {
  let key = secretKey;
  // Convert Base64 to encrypted text
  const encrypted = decodeURIComponent(escape(atob(encryptedText))); // Decode Base64 to original encrypted text
  let result = '';
  for (let i = 0; i < encrypted.length; i++) {
    const charCode = encrypted.charCodeAt(i) ^ key.charCodeAt(i % key.length);
    result += String.fromCharCode(charCode);
  }
  return result;
};

// Function to filter data based on role
export const filterDataByRole = (data, role) => {
  let filteredData = data.map(keys => {
    let finalObj = {
      ...keys
    };

    if (role == "Admin") {
      delete finalObj["BusinessCategory"];
    }

    if (role == "Borrower") {
      delete finalObj["SelectedPartners"];
      delete finalObj["SelectedAdvisors"];
      delete finalObj["SelectedLenders"];
    }

    if (role == "Partner") {
      delete finalObj["BusinessCategory"];
      delete finalObj["SelectedAdvisors"];
    }

    if (role == "Lender") {
      delete finalObj["BorrowerName"];
      delete finalObj["SelectedPartners"];
      delete finalObj["SelectedLenders"];
    }

    return finalObj;
  });

  return filteredData;
}

// conver date format mm/dd/yyyy
export const formatDate = (inputDate) => {
  // Create a Date object from the input string
  const date = new Date(inputDate);

  // Extract year, month, and day from the date object
  const year = date.getFullYear();
  const month = (1 + date.getMonth()).toString().padStart(2, '0'); // Month is zero-indexed
  const day = date.getDate().toString().padStart(2, '0');

  // Return formatted date
  return `${month}/${day}/${year}`;
};

export const borrowAmountValidator = (control) => {
  if (!control.value) {
    return null;
  } else if (control.value && Number(control.value.split(",").join("")) <= 25000) {
    return { borrowAmountIssue: true };
  } else if (control.value && Number(control.value.split(",").join("")) > 10000000000) {
    return { borrowAmountMaxIssue: true };
  }
  return null;
};

export const businessNameValidator = (control) => {
  // check if control.value only contains space or not
  if (control.value && control.value.trim() === "") {
    return { extraSpaces: true };
  }
  if (!control.value) {
    return null;
  } else if (control.value && control.value.length > 100) {
    return { businessNameIssue: true };
  }
  return null;
};

export const contactNumberValidate = (control) => {
  // check if control.value only contains space or not
  let phoneValue = control.value && control.value.split("(").join("").split(")").join("").split("-").join("").split(" ").join("");
  if (phoneValue && phoneValue.trim() === "") {
    return null
  }
  if (!control.value) {
    return null;
  }
  if (phoneValue && phoneValue.length !== 10) {
    return { contactNumberIssue: true };
  }
  return null;
};


export const ssnValidate = (control) => {
  let ssnValue = control.value && control.value.split("-").join("").split(" ").join("").split("(").join("").split(")").join("");

  if (ssnValue && ssnValue.trim() === "") {
    return null;
  }
  if (!control.value) {
    return null;
  }

  // SSN should have exactly 9 digits after removing any formatting characters
  if (ssnValue && ssnValue.length !== 9) {
    return { ssnIssue: true };
  }

  return null;
};

export const driverLicenseValidate = (control) => {
  let licenseValue = control.value ? control.value.trim() : "";

  if (licenseValue === "") {
    return { required: true };
  }

  if (!control.value) {
    return null;
  }

  // Check if the driver's license number length is between 6 and 20 characters (common range, can be adjusted)
  if (licenseValue.length < 6 || licenseValue.length > 20) {
    return { driverLicenseIssue: true };
  }

  return null;
};


export const validateNumber = (value) => {
  // Allow negative numbers, zero, and positive numbers
  const pattern = /^-?\d*\.?\d*$/;
  return pattern.test(value);
};

export const emailValidator = (control) => {
  const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
  if (!control.value) {
    return null;
  } else if (!emailPattern.test(control.value)) {
    return { invalidEmail: true };
  }
  return null;
};

export const zipCodeValidator = (control) => {
  if (!control.value) {
    return null;
  } else if (control.value && control.value.length !== 5) {
    return { zipCodeTooShort: true };
  }
  return null;
};

// Helper text to guide users on password requirements
export const renderPasswordHelperText = (passwordValidations) => (
  <div className="password-suggestion-box">
    <div className="d-flex">
      <img src={passwordValidations.length ? pass_sugn_tick : pass_sugn_cross} alt="" />
      {passwordValidations.length ? "8 characters minimum." : "At least 8 characters."}
    </div>
    <div className="d-flex">
      <img src={passwordValidations.uppercase ? pass_sugn_tick : pass_sugn_cross} alt="" />
      {passwordValidations.uppercase ? "One uppercase letter." : "One uppercase letter is missing."}
    </div>
    <div className="d-flex">
      <img src={passwordValidations.lowercase ? pass_sugn_tick : pass_sugn_cross} alt="" />
      {passwordValidations.lowercase ? "One lowercase letter." : "One lowercase letter is missing."}
    </div>
    <div className="d-flex">
      <img src={passwordValidations.numeric ? pass_sugn_tick : pass_sugn_cross} alt="" />
      {passwordValidations.numeric ? "One numeric digit." : "One numeric digit is missing."}
    </div>
    <div className="d-flex">
      <img src={passwordValidations.special ? pass_sugn_tick : pass_sugn_cross} alt="" />
      {passwordValidations.special ? "One special character (!, #, %)." : "One special character is missing."}
    </div>
    {/* <div className="d-flex">
      <img src={pass_sugn_cross} alt="" />
      Personal Information not allowed.
    </div> */}
  </div>
);

export const validStringValidator = (control) => {
  if (control.value && control.value.trim() === "") {
    return { extraSpaces: true };
  }
  return null;
};
