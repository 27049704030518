import React, { useState, useEffect } from 'react';
import { SidePanel, ProfileHeader } from "@components";
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { decryptText } from "../../helpers/DynamicFunctions";
import Preloader from '../../plugins/Preloader';
import FormTextArea from "../../reusablecomponets/FormTextArea";
import FormRadio from "../../reusablecomponets/FormRadio";
import FormSelect from "../../reusablecomponets/FormSelect";
import CreatableSelect from "../../reusablecomponets/CreatableSelect";
import { actions } from '../../_redux/store';
import { FieldGroup, FormBuilder, Validators } from "react-reactive-form";
import reducersAndActions from "../../_redux/slices/index";
import Constants from '../../helpers/Constants';
import { PeakSeasonOptions, LowSeasonOptions, TablesSeatingOptions } from '../../screens/loan-app-tabs/personalProfile/commonFunction';
import FormField from '../../components/FormField/FormField';
import OpenAIComponent from '../../components/OpenAI/OpenAIComponent';
import { JSONParseApiResponse } from '../../services/utilServices';
import WeeklyTimeComponent from '../../components/WeeklyTime/WeeklyTImeComponent';
import { validStringValidator } from '../../helpers/DynamicFunctions';


let tempDaySchedule = {}
function IndustryRestaurant() {
    const dispatch = useDispatch();
    const params = useParams();
    const parsedLoanId = decryptText(params.loanId);

    useEffect(() => {
        document.title = "Restaurant Information - Loan Application";
        setSelectOptions({ geographicMarket: [], customerVendors: [], peakSeason: [], lowSeason: [] });
    }, []);

    const [form] = React.useState(
        FormBuilder.group({
            id: [0],
            loanId: [parsedLoanId],
            geographicMarket: ["", [Validators.required, validStringValidator]],
            suppliersVendorTerms: ["", [Validators.required, validStringValidator]],
            competitiveAdvantages: ["", [Validators.required, validStringValidator]],
            seasonalBusiness: [""],
            restaurantSpecialty: ["", [Validators.required, validStringValidator]],
            tablesSeating: ["", [Validators.required, validStringValidator]],
            privateDiningRoom: [""],
            turnsWeekdayLunch: ["", [Validators.required, validStringValidator]],
            isWeekendLunch: [""],
            isBarArea: [""],
            cateringPerformed: [""],
            comments: [""],
            modifiedBy: [0],
            customerVendors: ["", [Validators.required, validStringValidator]],
            keyCompetitors: ["", [Validators.required, validStringValidator]],
            peakSeason: [""],
            lowSeason: [""],
            isWeekendDinner: [""],
            isDailyBreakfast: [""],
        })
    );

    useEffect(() => {
        dispatch(actions.restaurantForm.fetchRestaurantData({ loanId: parsedLoanId, type: 'Restaurant' }));
    }, [dispatch, parsedLoanId]);

    const { data, loading, error } = useSelector((state) => state.restaurantForm);

    const [dayTimeSchedule, setDayTimeSchedule] = useState({ monday: {}, tuesday: {}, wednesday: {}, thursday: {}, friday: {}, saturday: {}, sunday: {} });
    const [selectOptions, setSelectOptions] = useState({ geographicMarket: [], customerVendors: [], peakSeason: [], lowSeason: [] });
    const [showErrorMessage, setShowErrorMessage] = useState(false);

    useEffect(() => {
        try {
            if (data && data.resultObject && Object.keys(data.resultObject).length > 0) {
                const resultObject = data.resultObject
                console.log(resultObject)

                const geographicMarketOptions = resultObject.geographicMarket ? resultObject.geographicMarket.split(',').map(value => ({ label: value, value })) : [];
                const customerVendorsOptions = resultObject.customerVendors ? resultObject.customerVendors.split(',').map(value => ({ label: value, value })) : [];
                const peakSeasonOptions = resultObject.peakSeason ? resultObject.peakSeason.split(',').map(value => ({ value, label: PeakSeasonOptions.find(option => option.value === value)?.label || value })) : [];
                const lowSeasonOptions = resultObject.lowSeason ? resultObject.lowSeason.split(',').map(value => ({ value, label: LowSeasonOptions.find(option => option.value === value)?.label || value })) : [];

                setSelectOptions({
                    geographicMarket: geographicMarketOptions,
                    customerVendors: customerVendorsOptions,
                    peakSeason: peakSeasonOptions,
                    lowSeason: lowSeasonOptions
                });

                form.patchValue({
                    ...resultObject,
                    loanId: resultObject.loanId || parsedLoanId,
                    isWeekendDinner: resultObject.isWeekendDinner === 'true' ? 'true' : 'false',
                    isDailyBreakfast: resultObject.isDailyBreakfast === 'true' ? 'true' : 'false',
                    seasonalBusiness: resultObject.seasonalBusiness === 'true' ? 'true' : 'false',
                    privateDiningRoom: resultObject.privateDiningRoom === 'true' ? 'true' : 'false',
                    isWeekendLunch: resultObject.isWeekendLunch === 'true' ? 'true' : 'false',
                    isBarArea: resultObject.isBarArea === 'true' ? 'true' : 'false',
                    cateringPerformed: resultObject.cateringPerformed === 'true' ? 'true' : 'false',
                });
                const dayTimeSchedule = resultObject.lstHoursOperation.reduce((acc, item) => {
                    acc[item.daysName] = item;
                    return acc;
                }, {});

                setDayTimeSchedule((prev) => ({ ...prev, ...dayTimeSchedule }));
            } else {
                form.reset({ id: 0, loanId: parsedLoanId, modifiedBy: 0, isWeekendDinner: 'false', isDailyBreakfast: 'false', seasonalBusiness: 'false', privateDiningRoom: 'false', turnsWeekdayLunch: 'false', isWeekendLunch: 'false', isBarArea: 'false', cateringPerformed: 'false' });
            }
        } catch (error) {
            form.reset({ id: 0, loanId: parsedLoanId, modifiedBy: 0, isWeekendDinner: 'false', isDailyBreakfast: 'false', seasonalBusiness: 'false', privateDiningRoom: 'false', turnsWeekdayLunch: 'false', isWeekendLunch: 'false', isBarArea: 'false', cateringPerformed: 'false' });
        }
    }, [data, setSelectOptions, form]);


    useEffect(() => {
        tempDaySchedule = { ...dayTimeSchedule };
    }, [dayTimeSchedule]);

    const handleSubmit = () => {
        setShowErrorMessage(true);

        if (form.status !== "INVALID") {
            const weekDays = ['monday', 'tuesday', 'wednesday', 'thursday', 'friday', 'saturday', 'sunday'];
            weekDays.forEach(day => {
                if (!tempDaySchedule.hasOwnProperty(day)) {
                    tempDaySchedule[day] = { startDate: '09:00 AM', endDate: '06:00 PM', timeHour: '12:00', status: true, daysName: day, id: 0, industryId: 0 };
                }
            })

            const finalData = { ...form.value, lstHoursOperation: Object.values(tempDaySchedule) };

            finalData['peakSeason'] = finalData['seasonalBusiness'] === 'true' ? finalData['peakSeason'] : '';
            finalData['lowSeason'] = finalData['seasonalBusiness'] === 'true' ? finalData['lowSeason'] : '';

            dispatch(actions.restaurantForm.submitRestaurantForm(finalData))
                .then((response) => {
                    dispatch(reducersAndActions.actions.toasterActions.showToaster({ visible: true, message: response.payload.message, type: "success" }));
                    dispatch(actions.restaurantForm.fetchRestaurantData({ loanId: parsedLoanId, type: 'Restaurant' }));
                    setTimeout(() => dispatch(reducersAndActions.actions.toasterActions.hideToaster()), Constants.TOASTERHIDETIME);
                    setShowErrorMessage(false);
                })
                .catch(() => {
                    dispatch(reducersAndActions.actions.toasterActions.showToaster({ visible: true, message: "Error submitting form", type: "error" }));
                    setTimeout(() => dispatch(reducersAndActions.actions.toasterActions.hideToaster()), Constants.TOASTERHIDETIME);
                });
        } else {
            markAllAsTouched(form);
            dispatch(reducersAndActions.actions.toasterActions.showToaster({ visible: true, message: "Please filled required fields", type: "error" }));
            setTimeout(() => dispatch(reducersAndActions.actions.toasterActions.hideToaster()), Constants.TOASTERHIDETIME);
        }
    }


    const handleSelectChange = (name, selectedOption) => {
        const currentSelectedValues = selectedOption || [];
        const selectedValues = currentSelectedValues.map(option => option.value);

        // Allow removal if the new selection is less than or equal to the current one
        if (currentSelectedValues.length <= 10) {
            setSelectOptions(prevOptions => ({ ...prevOptions, [name]: currentSelectedValues.filter(opt => opt.value.trim() !== "" && opt.value.length <= 50) }));
            form.controls[name].setValue(selectedValues.join(','));
        } else {
            if (selectOptions[name]?.length < currentSelectedValues.length) {
                dispatch(reducersAndActions.actions.toasterActions.showToaster({ visible: true, message: "You can't select more than 10 options", type: "error" }));
                setTimeout(() => dispatch(reducersAndActions.actions.toasterActions.hideToaster()), Constants.TOASTERHIDETIME);

                setSelectOptions(prevOptions => ({ ...prevOptions, [name]: prevOptions[name] }));
            }
        }
    };

    const markAllAsTouched = (formGroup) => {
        Object.keys(formGroup.controls).forEach((controlName) => {
            const control = formGroup.get(controlName);
            if (control && control.status === "INVALID") {
                control.markAsTouched();
                control.updateValueAndValidity();
                if (document.getElementById(controlName)) {
                    document.getElementById(controlName).focus();
                }
            }
        });
    };


    return (
        <>
            {/* SidePanel */}
            <SidePanel />

            {/* ProfileHeader */}
            <ProfileHeader />

            {loading ? (<Preloader />) : (<div>


                <div className="lm-form-box pb-5">
                    <FieldGroup
                        control={form}
                        render={({ get, invalid }) => (
                            <form>
                                <div className="lm-card">
                                    <div className="row">
                                        <div className="col-12">
                                            <h3 className="lm-card-heading">Restaurant Information </h3>
                                        </div>
                                        <div className="col-md-6">
                                            <CreatableSelect
                                                name="geographicMarket"
                                                label="Geographic markets"
                                                value={selectOptions.geographicMarket}
                                                onChange={handleSelectChange}
                                                placeholder="+ Add"
                                                isClearable
                                                required={true}
                                            />
                                            {showErrorMessage && !form.value.geographicMarket && <div className='error-message mt-2 mb-3'>Required field</div>}
                                        </div>

                                        <div className="col-md-6">
                                            <CreatableSelect
                                                name="customerVendors"
                                                label="Major customers"
                                                value={selectOptions.customerVendors}
                                                onChange={handleSelectChange}
                                                placeholder="+ Add"
                                                isClearable
                                                required={true}
                                            />
                                            {showErrorMessage && !form.value.customerVendors && <div className='error-message mt-2 mb-3'>Required field</div>}
                                        </div>

                                        <div className="col-md-6 mt-3">
                                            <div className="form-group AI_btn_field">
                                                <FormField
                                                    id="keyCompetitors"
                                                    label="Key competitors"
                                                    name="keyCompetitors"
                                                    placeholder="Enter Text"
                                                    required={true}
                                                    errorMessage="Required field"
                                                />
                                                <OpenAIComponent
                                                    fieldName="keyCompetitors"
                                                    formGroup={form}
                                                />
                                            </div>
                                        </div>
                                        <div className="col-md-6">
                                        </div>

                                        <div className="col-md-6">
                                            <div className="form-group AI_btn_field">
                                                <FormTextArea
                                                    id="suppliersVendorTerms"
                                                    label="Major suppliers & terms"
                                                    name="suppliersVendorTerms"
                                                    placeholder="Enter Text"
                                                    required={true}
                                                    errorMessage="Required field"
                                                />
                                                <OpenAIComponent
                                                    fieldName="suppliersVendorTerms"
                                                    formGroup={form}
                                                    type='textarea'
                                                />
                                            </div>
                                        </div>

                                        <div className="col-md-6">
                                            <div className="form-group AI_btn_field">
                                                <FormTextArea
                                                    id="competitiveAdvantages"
                                                    label="Competitive advantages"
                                                    name="competitiveAdvantages"
                                                    placeholder="Enter Text"
                                                    required={true}
                                                    errorMessage="Required field"
                                                />
                                                <OpenAIComponent
                                                    fieldName="competitiveAdvantages"
                                                    formGroup={form}
                                                    type='textarea'
                                                />
                                            </div>
                                        </div>

                                        <div className='col-12'>
                                            <h3 className="lm-card-heading mt-2">Hours of operation</h3>
                                            <div className='row'>
                                                <WeeklyTimeComponent
                                                    weekday="monday"
                                                    setDayTimeSchedule={setDayTimeSchedule}
                                                    value={dayTimeSchedule}
                                                    industryType="Restaurant"
                                                />
                                                <WeeklyTimeComponent
                                                    weekday="tuesday"
                                                    setDayTimeSchedule={setDayTimeSchedule}
                                                    value={dayTimeSchedule}
                                                    industryType="Restaurant"
                                                />
                                                <WeeklyTimeComponent
                                                    weekday="wednesday"
                                                    setDayTimeSchedule={setDayTimeSchedule}
                                                    value={dayTimeSchedule}
                                                    industryType="Restaurant"
                                                />
                                                <WeeklyTimeComponent
                                                    weekday="thursday"
                                                    setDayTimeSchedule={setDayTimeSchedule}
                                                    value={dayTimeSchedule}
                                                    industryType="Restaurant"
                                                />
                                                <WeeklyTimeComponent
                                                    weekday="friday"
                                                    setDayTimeSchedule={setDayTimeSchedule}
                                                    value={dayTimeSchedule}
                                                    industryType="Restaurant"
                                                />
                                                <WeeklyTimeComponent
                                                    weekday="saturday"
                                                    setDayTimeSchedule={setDayTimeSchedule}
                                                    value={dayTimeSchedule}
                                                    industryType="Restaurant"
                                                />
                                                <WeeklyTimeComponent
                                                    weekday="sunday"
                                                    setDayTimeSchedule={setDayTimeSchedule}
                                                    value={dayTimeSchedule}
                                                    industryType="Restaurant"
                                                />
                                            </div>
                                        </div>

                                        <div className="col-md-4 col-lg-3">
                                            <FormField
                                                id="restaurantSpecialty"
                                                label="Cuisine/specialty?"
                                                name="restaurantSpecialty"
                                                placeholder="Enter Text"
                                                required={true}
                                                errorMessage="Required field"
                                            />
                                        </div>
                                        <div className="col-md-4 col-lg-3">
                                            <FormSelect
                                                name="tablesSeating"
                                                label="How many tables?"
                                                options={TablesSeatingOptions}
                                                defaultValue={TablesSeatingOptions[0]}
                                                required={true}
                                                errorMessage="Required field"
                                            />
                                        </div>

                                        <div className="col-md-4 col-lg-3">
                                            <FormRadio
                                                name="privateDiningRoom"
                                                label="Private dining room?"
                                                options={[
                                                    { label: 'Yes', value: 'true' },
                                                    { label: 'No', value: 'false' },
                                                ]}
                                            />
                                        </div>
                                        <div className="col-md-4 col-lg-3">
                                            <FormRadio
                                                name="isBarArea"
                                                label="Bar area?"
                                                options={[
                                                    { label: 'Yes', value: 'true' },
                                                    { label: 'No', value: 'false' },
                                                ]}
                                            />
                                        </div>
                                        <div className="col-md-4 col-lg-3">
                                            <FormField
                                                id="turnsWeekdayLunch"
                                                label="Total table turns per day?"
                                                name="turnsWeekdayLunch"
                                                placeholder="Enter Text"
                                                required={true}
                                                errorMessage="Required field"
                                            />
                                        </div>
                                        <div className="col-md-4 col-lg-3">
                                            <FormRadio
                                                name="isDailyBreakfast"
                                                label="Daily breakfast?"
                                                options={[
                                                    { label: 'Yes', value: 'true' },
                                                    { label: 'No', value: 'false' },
                                                ]}
                                            />
                                        </div>
                                        <div className="col-md-4 col-lg-3">
                                            <FormRadio
                                                name="isWeekendLunch"
                                                label="Weekend lunch?"
                                                options={[
                                                    { label: 'Yes', value: 'true' },
                                                    { label: 'No', value: 'false' },
                                                ]}
                                            />
                                        </div>
                                        <div className="col-md-4 col-lg-3">
                                            <FormRadio
                                                name="isWeekendDinner"
                                                label="Weekend dinner?"
                                                options={[
                                                    { label: 'Yes', value: 'true' },
                                                    { label: 'No', value: 'false' },
                                                ]}
                                            />
                                        </div>
                                        <div className="col-md-4 col-lg-3">
                                            <FormRadio
                                                name="cateringPerformed"
                                                label="Is catering performed on-site?"
                                                options={[
                                                    { label: 'Yes', value: 'true' },
                                                    { label: 'No', value: 'false' },
                                                ]}
                                            />
                                        </div>

                                        {/* <!--  lm common divider to break the section  --> */}
                                        <div className="lm-divider my-4 mx-3"></div>
                                        {/* <!--  lm common divider to break the section  --> */}

                                        <div className="col-md-6">
                                            <FormRadio
                                                name="seasonalBusiness"
                                                label="Is this a seasonal business?"
                                                options={[
                                                    { label: 'Yes', value: 'true' },
                                                    { label: 'No', value: 'false' },
                                                ]}
                                            />
                                        </div>

                                        {form.value.seasonalBusiness == "true" && <><div className="col-12">
                                            <div className="row">
                                                <div className="col-md-6">
                                                    <CreatableSelect
                                                        name="peakSeason"
                                                        label="Peak season"
                                                        value={selectOptions.peakSeason}
                                                        onChange={handleSelectChange}
                                                        placeholder="Select..."
                                                        options={PeakSeasonOptions}
                                                        isMulti
                                                        isClearable
                                                    />
                                                </div>
                                                <div className="col-md-6">
                                                    <CreatableSelect
                                                        name="lowSeason"
                                                        label="Off season"
                                                        value={selectOptions.lowSeason}
                                                        onChange={handleSelectChange}
                                                        placeholder="Select..."
                                                        options={LowSeasonOptions}
                                                        isMulti
                                                        isClearable
                                                    />
                                                </div>
                                            </div>
                                        </div>

                                        </>
                                        }


                                        {/* <!--  lm common divider to break the section  --> */}
                                        <div className="lm-divider my-4 mx-3"></div>
                                        {/* <!--  lm common divider to break the section  --> */}

                                        <div className="col-md-6">
                                            <div className="form-group AI_btn_field">
                                                <FormTextArea
                                                    id="comments"
                                                    label="Additional comments"
                                                    name="comments"
                                                    placeholder="Enter Text"
                                                    maxlength={500}
                                                />
                                                <OpenAIComponent
                                                    fieldName="comments"
                                                    formGroup={form}
                                                    type='textarea'
                                                />
                                            </div>
                                        </div>

                                    </div>
                                </div>
                                <div className="sticky-btn-footer d-flex justify-content-end">
                                    <button type="button" className="lm-button1 ml-3" onClick={handleSubmit}>Save</button>
                                </div>
                            </form>
                        )}
                    />
                </div>



            </div>)}

        </>
    );
}

export default IndustryRestaurant;