import React, { useState, useEffect, useCallback, useRef } from 'react';
import { Link } from "react-router-dom";
import Select from 'react-select';
import CreatableSelect from 'react-select/creatable';
import makeAnimated from 'react-select/animated';
import Modal from 'react-bootstrap/Modal'
import Dropdown from 'react-bootstrap/Dropdown'
import { SidePanel, ProfileHeader } from "@components";
import Preloader from '../../plugins/Preloader';
import Info_tooltip from '../../assets/icons/Info-tooltip.svg';
import Review_icon from '../../assets/icons/Review_icon.svg';
import download_icon from '../../assets/icons/download_icon.svg';
import send_email_icon from '../../assets/icons/send_email_icon.svg';
import version_history_icon from '../../assets/icons/version_history_icon.svg';
import set_esign_icon from '../../assets/icons/set_esign_icon.svg';
import mark_com_icon from '../../assets/icons/mark_com_icon.svg';
import close_popup from '../../assets/icons/close_popup.svg';
import Report_Icon from '../../assets/icons/report-icon.svg';
import pdf_file_icon from '../../assets/img/pdf-file-icon.svg';
import Cancel_e_signature from '../../assets/icons/Cancel_e_signature.svg';
import Cancel_sending_for_signature from '../../assets/icons/Cancel_sending_for_signature.svg';
import Send_to_signature from '../../assets/icons/Send_to_signature.svg';
import Remind_to_sign from '../../assets/icons/Remind_to_sign.svg';
import document_icon from '../../assets/icons/document-icon.svg';
import file_icon from '../../assets/icons/file-icon.svg';
import note_icon from '../../assets/icons/note-icon-dark.svg';

import pass_eye from '../../assets/img/pass_eye.svg';
import { getSessionId } from '../../services/storageData';
import { CancelHelloSignSignature, GetAGDDocumentDetailsById, GetAllDocumentList, GetDocumentList, GetDocumentsHistory, GetDownloadDocumentHistoryFile, GetEntityListofDocumentsByLoanId, InsertUpdateAGDDocument, MarkAsCompletedDocuments, MultipleDocumentSentSignature, SaveDocumentCoordinates, SendDocumentToEmail, SentSignatureForAGD, SentSignatureForBGD, UpdateDocumentVerify_Active, UploadDocument, ViewDocumentInBrowser } from '../../services/documentsServices';
import DynamicTable from '../../plugins/DynamicTable';
import { DocumentsListHeader } from '../../helpers/DropdownsAndMappers';
import { debounce } from 'lodash';
import Constants from '../../helpers/Constants';
import reducersAndActions from '../../_redux/slices';
import { useDispatch, useSelector } from 'react-redux';
import convertToLocalTime from '../user/commonFunctions/User';
import { pdfjs, Document, Page } from 'react-pdf';
import '../../assets/AnnotationLayer.css';
import '../../assets/TextLayer.css';
import * as pdfjsLib from "pdfjs-dist";

pdfjs.GlobalWorkerOptions.workerSrc = `//unpkg.com/pdfjs-dist@${pdfjs.version}/build/pdf.worker.min.mjs`;


const options = {
    cMapUrl: '/cmaps/',
    standardFontDataUrl: '/standard_fonts/',
};


function Documents(props) {
    const dispatch = useDispatch();

    const user = useSelector((state) => state.auth);
    const userRole = user.user.roleName;

    useEffect(() => {
        document.title = "Documents - Loan Application";
        getEntityList();
    }, []);

    const [loading, setLoading] = useState(true);

    useEffect(() => {
        dispatch(reducersAndActions.actions.sessionActions.setLoading(loading));
    }, [loading]);

    const [entityList, setEntityList] = useState([]);
    const [selectedEntity, setSelectedEntity] = useState(null);

    // Table check box functionallity
    const [allSelectedRows, setAllSelectedRows] = useState(false);
    const [selectedRows, setSelectedRows] = useState({});
    const [toggleRowSelectionStatuses, setToggleRowSelectionStatuses] = useState({})

    const [documents, setDocumentList] = useState([]);
    const [allDocumentsList, setAllDocumentsList] = useState([]);

    const [totalData, setTotalData] = useState(0);
    const [pageIndex, setPageIndex] = useState(1);
    const [pageSize, setPageSize] = useState(5);
    const [searchText, setSearchText] = useState("");
    const [status, setStatus] = useState("");

    // Fetch inquiries whenever page size or page index changes
    const debounceDoucmentList = useCallback(debounce(() => {
        setLoading(true);
        setSelectedRows([])
        setToggleRowSelectionStatuses({})
        setSelectedDocumentsForEsign({})
        getDoucmentsListFun()
    }, 1000), [selectedEntity, pageIndex, pageSize, status, searchText]);

    useEffect(() => {
        debounceDoucmentList();
        return () => {
            debounceDoucmentList.cancel();
        };
    }, [debounceDoucmentList]);

    const getEntityList = async () => {
        setLoading(true);
        setPageIndex(1);
        const params = {
            SessionId: getSessionId(),
            LoanId: props.loanid
        };
        const entityList = await GetEntityListofDocumentsByLoanId(params);
        if (entityList.status = 200) {
            const temp = JSON.parse(entityList.resultObject).map((entity) => {
                return { value: entity.Id, label: entity.EntityName }
            });
            setEntityList(temp);
            setSelectedEntity(temp[0]['value']);
            setSelectedEntityForAddDocument(temp[0]['value']);
        }
    }


    useEffect(() => {
        getAllDocumentsList();
    }, [selectedEntity]);

    const getAllDocumentsList = async () => {
        const documentList = await GetAllDocumentList();
        if (documentList.status = 200) {
            try {
                const temp = JSON.parse(documentList.resultObject) || [];
                const finalList = temp.map((doc) => ({
                    value: doc.DocumentId,
                    label: doc.DocumentName,
                }))
                setAllDocumentsList(finalList);
            } catch (error) {
                setAllDocumentsList([]);
                console.error(error)
            }
        }
    }


    const getDoucmentsListFun = async () => {
        const params = {
            SessionId: getSessionId(),
            LoanId: props.loanid,
            EntityId: selectedEntity,
            PageIndex: pageIndex,
            PageSize: pageSize,
            Status: status,
            SearchText: searchText,
            IsBorrower: userRole === "Borrower" ? 1 : 0
        };
        const documentList = await GetDocumentList(params);
        if (documentList.status = 200) {
            try {
                setDocumentList(JSON.parse(documentList.resultObject));
                let total = documentList.message.split(" ");
                setTotalData(Number(total[1]));
                setAllSelectedRows(false);
                console.log(JSON.parse(documentList.resultObject))
            } catch (error) {
                setDocumentList([]);
                setTotalData(0);
                setAllSelectedRows(false);
                console.error(error)
            }
        }
        setLoading(false);
    }



    const toggleRowSelection = (id, data) => {
        setSelectedRows((prev) => {
            prev[id] ? delete prev[id] : prev[id] = data;
            return { ...prev };
        });


        // Toggle status using the ID as the key
        setToggleRowSelectionStatuses(temp => {
            temp[id] ? delete temp[id] : temp[id] = data.Status;
            return { ...temp };
        });
    };

    // Popup function
    const [newDocumentPopupShow, setNewDocumentPopup] = useState(false);
    const newDocumentPopupClose = () => setNewDocumentPopup(false);

    const [markCompletePopupShow, setMarkCompletePopup] = useState(false);
    const markCompletePopupClose = () => setMarkCompletePopup(false);

    const [cancelESignaturePopupShow, setcancelESignaturePopup] = useState(false);
    const cancelESignaturePopupClose = () => setcancelESignaturePopup(false);

    const [generateReportPopupShow, setGenerateReportPopup] = useState(false);
    const generateReportPopupClose = () => setGenerateReportPopup(false);

    const [versionHistoryPopupShow, setVersionHistoryPopup] = useState(false);
    const versionHistoryPopupClose = () => setVersionHistoryPopup(false);

    const [uploadDocumentPopupShow, setUploadDocumentPopup] = useState(false);
    const uploadDocumentPopupClose = () => setUploadDocumentPopup(false);

    const [sendEmailPopupShow, setSendEmailPopup] = useState(false);
    const sendEmailPopupClose = () => setSendEmailPopup(false);

    const [ESignaturePopupShow, setESignaturePopup] = useState(false);
    const ESignaturePopupClose = () => setESignaturePopup(false);

    const [deleteLastSignPositionShow, setDeleteLastSignPosition] = useState(false);
    const deleteLastSignPositionClose = () => setDeleteLastSignPosition(false);

    const [deleteAllSignPositionShow, setDeleteAllSignPosition] = useState(false);
    const deleteAllSignPositionClose = () => setDeleteAllSignPosition(false);

    const [sendForSignaturePopUp, setSendForSignaturePopUp] = useState(false);
    const sendForSignatureClose = () => setSendForSignaturePopUp(false);

    // Requirements tabs
    const [activeTab, setActiveTab] = useState("");

    const handleTabClick = (tabId) => {
        setActiveTab(tabId);
        setSecondPersionSign(false)
        setSelectedTabOption(1)
    };

    const handlePreviousTab = () => {
        const documentIds = Object.keys(selectedDocumentForEsign);
        const currentIndex = documentIds.indexOf(activeTab);
        if (currentIndex > 0) {
            setActiveTab(documentIds[currentIndex - 1]);
            setSecondPersionSign(false)
            setSelectedTabOption(1)
        }
    };

    const handleNextTab = () => {
        const documentIds = Object.keys(selectedDocumentForEsign);
        const currentIndex = documentIds.indexOf(activeTab);
        if (currentIndex < documentIds.length - 1) {
            setActiveTab(documentIds[currentIndex + 1]);
            setSecondPersionSign(false)
            setSelectedTabOption(1)
        }
    };


    // Tooltip messages for different statuses
    const statusTooltipMessages = {
        "Sent for signature": "The document is out for borrower's signature",
        "Completed": "The document is ready for loan review",
        "Uploaded": "The document is uploaded and e-signature is required. Please set the e-signature block",
        "To sign": "The document is ready for sending for e-signature",
        "To upload": "File needs to be uploaded."
    };

    // select Dropdown options
    const statusOptions = [
        { value: '', label: 'All statuses' },
        { value: 'Completed', label: 'Completed' },
        { value: 'Uploaded', label: 'Uploaded' },
        { value: 'Sent for signature', label: 'Sent for signature' },
        { value: 'To sign', label: 'To sign' },
        { value: 'To upload', label: 'To upload' },
    ]

    const animatedComponents = makeAnimated();

    const showNotificationToaster = (message, type) => {
        dispatch(reducersAndActions.actions.toasterActions.showToaster({ visible: true, message: message, type: type }));
        setTimeout(() => dispatch(reducersAndActions.actions.toasterActions.hideToaster()), Constants.TOASTERHIDETIME);
    }

    // ------------------------------------------------
    const [selectedDocumentForCompelete, setSelectedDocumentForCompelete] = useState({});
    const handleConfirmMarkAsCompelete = async () => {
        const params = {
            id: parseInt(selectedDocumentForCompelete.Id),
            loanId: parseInt(props.loanid),
            entityId: parseInt(selectedEntity),
            documentId: parseInt(selectedDocumentForCompelete.DocumentId),
            userId: parseInt(user.user.id),
            fileName: selectedDocumentForCompelete.FileName
        }
        const changeStatus = await MarkAsCompletedDocuments(params);
        if (changeStatus.status === 200) {
            showNotificationToaster("Document marked as completed", "success");
            markCompletePopupClose();
            getDoucmentsListFun()
        } else {
            showNotificationToaster("An error occurred while updating the document status", "error");
        }
    }

    // ------------------------------------------------
    const [selectedDocumentForCancelSendToSing, setSelectedDocumentForCancelSendToSing] = useState([]);
    const handleConfirmCancelSendToSing = async () => {
        try {
            setLoading(true);
            const params = selectedDocumentForCancelSendToSing.map((element) => {
                return {
                    LoanId: Number(props.loanid),
                    OwnerId: Number(element.OwnerID),
                    EntityId: Number(selectedEntity),
                    DocumentId: Number(element.DocumentId),
                    DocumentName: element.Name,
                    DocumentType: element.DocumentType,
                }
            });

            const changeStatus = await CancelHelloSignSignature(params);
            if (changeStatus.status == 200) {
                showNotificationToaster("Document signature requests are cancelled", "success");
            } else {
                showNotificationToaster(`An error occurred while canceling the request`, "error");
            }

            setLoading(false);
            // Reset the state and close popup
            setSelectedDocumentForCancelSendToSing([]);
            setcancelESignaturePopup(false);
            getDoucmentsListFun(); // Refresh document list
            setSelectedRows({});
            setToggleRowSelectionStatuses({});
        } catch (error) {
            console.log(error);
            showNotificationToaster(`An error occurred while canceling the request`, "error");
        }
    };

    // ------------------------------------------------
    const [selectedEntityForAddDocument, setSelectedEntityForAddDocument] = useState();
    const [selectedRequiredDoucment, setSelectedRequiredDoucment] = useState([]);
    const [tempSelectedRequiredDoucment, setTempSelectedRequiredDoucment] = useState([]);

    const handleDocumentModalClose = () => {
        newDocumentPopupClose()
        setSelectedRequiredDoucment([...tempSelectedRequiredDoucment])
    }

    const getAGDDocumentsList = async () => {
        const params = {
            SessionId: getSessionId(),
            LoanId: props.loanid,
            EntityId: selectedEntityForAddDocument,
        }
        const documentList = await GetAGDDocumentDetailsById(params);
        if (documentList.status = 200) {
            try {
                // Replace escaped quotes with regular quotes
                const correctedString = documentList.resultObject.replace(/\\"/g, '"');
                const tempData = JSON.parse(correctedString) || [];
                const finalData = tempData.map((doc) => ({
                    value: doc.DocumentId,
                    label: doc.DocumentName,
                }));
                setTempSelectedRequiredDoucment(finalData);
                setSelectedRequiredDoucment(finalData);
            } catch (error) {
                setSelectedRequiredDoucment([]);
                console.error(error)
            }
        }
    }

    useEffect(() => {
        setSelectedRequiredDoucment([]);
        getAGDDocumentsList();
    }, [selectedEntityForAddDocument])

    const handleAddNewDocument = async () => {
        setLoading(true)
        const params = {
            LoanId: parseInt(props.loanid),
            EntityId: parseInt(selectedEntityForAddDocument),
            EntityName: entityList.find(entity => entity.value === selectedEntityForAddDocument).label
        }
        const finalData = selectedRequiredDoucment.map(doc => {
            return {
                documentName: doc.label,
                documentId: parseInt(doc.value),
            }
        })
        const updateDocuments = await InsertUpdateAGDDocument(finalData, params);
        if (updateDocuments.status === 200) {
            showNotificationToaster("Documents added successfully", "success");
            setTempSelectedRequiredDoucment([...selectedRequiredDoucment])
            setEmailRecipients([]);
            setEmailNotes("");
            newDocumentPopupClose();
            getDoucmentsListFun()
        } else {
            showNotificationToaster("An error occurred while updating the document status", "error");
        }
        setLoading(false);

    }



    // ------------------------------------------------
    const [sendingEmailDocument, setSendingEmailDocument] = useState([]);
    const [emailRecipients, setEmailRecipients] = useState([]);
    const [emailNotes, setEmailNotes] = useState("");

    const sendDocumentToEmail = async () => {
        if (!emailRecipients.length) {
            showNotificationToaster("Please select at least one recipient", "error");
            return;
        }
        setLoading(true);
        const params = { LoanId: props.loanid, MailIds: emailRecipients.map(recipient => recipient.value).toString() }
        const jsonBody = sendingEmailDocument.map(doc => {
            return {
                documentFileName: doc.DocumentType === "AGD" || (doc.DocumentType === "BGD" && doc.Status === "Completed") ? doc.FileName : doc.Name,
                emailid: emailRecipients.map(recipient => recipient.value).toString(),
                emailTextMessage: emailNotes,
                documentName: doc.DocumentType === "BGD" ? doc.TD_Name : doc.Name,
                ownerId: doc.OwnerID,
                loanId: props.loanid,
                docType: doc.DocType,
                documentId: doc.Id,
                entityId: selectedEntity,
                isSigned: doc.Status === "Completed",
                entityName: doc.Name,
                uuDocumentPath: "",
                email_guid: "",
                documentType: doc.DocumentType
            }
        });
        // Call API to send email
        const sendEmail = await SendDocumentToEmail(jsonBody, params);
        if (sendEmail.status === 200) {
            showNotificationToaster("Document sent to email address", "success");
            setEmailRecipients([]);
            setEmailNotes("");
        } else {
            showNotificationToaster("An error occurred while sending email", "error");
        }
        setLoading(false);
        setSendEmailPopup(false)
    }

    // ------------------------------------------------
    const [file, setFile] = useState(null);
    const [fileURL, setFileURL] = useState(null);
    const [uploadProgress, setUploadProgress] = useState(0);


    const handleFileChange = async (e) => {
        const selectedFile = e.target.files[0];
        const fileSizeLimit = 20 * 1024 * 1024; // 20 MB in bytes

        if (!selectedFile) return showNotificationToaster("Please select a file", "error");
        if (selectedFile.size > fileSizeLimit) return showNotificationToaster("File size should be less than 20MB", "error");
        if (selectedFile.type !== "application/pdf") return showNotificationToaster("Only PDF files are allowed", "error");


        const fileReader = new FileReader();

        fileReader.onload = async () => {
            try {
                const pdf = await pdfjsLib.getDocument({ data: fileReader.result }).promise;

                let isPortrait = true;
                for (let i = 1; i <= pdf.numPages; i++) {
                    const page = await pdf.getPage(i);
                    const { width, height } = page.getViewport({ scale: 1 });

                    if (width > height) {
                        isPortrait = false;
                        break;
                    }
                }

                if (!isPortrait) return showNotificationToaster("PDF must be in portrait orientation", "error");

                setFile(selectedFile);
                setFileURL(URL.createObjectURL(selectedFile));
                uploadFile(selectedFile);
            } catch (error) {
                showNotificationToaster("Unable to process PDF. Please try again.", "error");
                console.error("PDF processing error:", error);
            }
        };

        fileReader.readAsArrayBuffer(selectedFile);
    };


    const uploadFile = (file) => {
        // Mock upload process
        setUploadProgress(0);
        const uploadInterval = setInterval(() => {
            setUploadProgress((prevProgress) => {
                if (prevProgress >= 100) {
                    clearInterval(uploadInterval);
                    return 100;
                }
                return prevProgress + 10;
            });
        }, 100); // Update progress every 100ms
    };

    const removeDocument = () => {
        setFile(null);
        setUploadProgress(0);
    };

    const handleViewDocument = () => {
        if (fileURL) {
            window.open(fileURL, '_blank');
        }
    };

    // Initialize state with documents' initial values
    const [isActive, setIsActive] = useState([]);
    const [isVerified, setIsVerified] = useState([]);

    // Update state when documents change
    useEffect(() => {
        setIsActive(documents.map(doc => doc.IsActive === "1"));
        setIsVerified(documents.map(doc => doc.Verified === "1"));
    }, [documents]);

    // Handle checkbox state changes for 'isActive'
    const handleActiveChange = async (index) => {

        const params = {
            DocumentType: documents[index].DocumentType,
            Action: "Active",
            LoanId: props.loanid,
            DocumentId: documents[index].Id,
            OwnerId: documents[index].OwnerID,
            Status: !isActive[index],
            SessionId: getSessionId()
        };

        // Call API to update document status
        const updateStatus = await UpdateDocumentVerify_Active(params);
        if (updateStatus.status === 200) {
            setIsActive(prevStates => {
                const newStates = [...prevStates];
                newStates[index] = !newStates[index];
                return newStates;
            });
        } else {
            showNotificationToaster(updateStatus?.response?.data?.message || "An error occurred while updating the document status", "error");
        }
    };

    // ------------------------------------------------
    const handleVerifyChange = async (index) => {
        if (documents[index].Status !== "Completed") {
            showNotificationToaster("You can't verify until status is completed", "error");
            return false;
        }
        const params = {
            DocumentType: documents[index].DocumentType,
            Action: "Verify",
            LoanId: props.loanid,
            DocumentId: documents[index].Id,
            OwnerId: documents[index].OwnerID,
            Status: !isVerified[index],
            SessionId: getSessionId()
        };

        // Call API to update document status
        const updateStatus = await UpdateDocumentVerify_Active(params);
        if (updateStatus.status === 200) {
            setIsVerified(prevStates => {
                const newStates = [...prevStates];
                newStates[index] = !newStates[index];
                return newStates;
            });
        } else {
            showNotificationToaster(updateStatus?.response?.data?.message || "An error occurred while updating the document status", "error");
        }
    };


    const [borrowerNeedsSign, setBorrowerNeedsSign] = useState(false);
    const [uploadDocumentIndex, setUploadDocumentIndex] = useState(null);
    const [versionHistoryList, setVersionHistoryList] = useState([]);
    const [versionHistoryFileName, setVersionHistoryFileName] = useState("");

    const [selectedTabOption, setSelectedTabOption] = useState(1);
    const [secondPersionSign, setSecondPersionSign] = useState(false);
    const [secondPersonEmail, setSecondPersonEmail] = useState("");

    const [selectedDocumentForEsign, setSelectedDocumentsForEsign] = useState({})
    const [selectedTabOptionIcon, setSelectedTabOptionIcon] = useState({});
    const [totalSignedDocuments, setTotalSignedDocuments] = useState(0);

    const [eSignBlocksList, setESignBlocksList] = useState({});
    const [dateBlocksList, setESignDateBlocksList] = useState({});
    const [textBlocksList, setESignTextBlocksList] = useState({});
    const [secondPersonList, setSecondPersonList] = useState({});

    useEffect(() => {
        if (Object.keys(selectedDocumentForEsign).length) {
            setESignBlocksList({});
            setESignDateBlocksList({});
            setESignTextBlocksList({});
            setSecondPersonList({})
            setSecondPersonEmail("")
            setSecondPersionSign(false)
            setActiveTab(Object.values(selectedDocumentForEsign)[0]?.Id)
        }
    }, [selectedDocumentForEsign])

    useEffect(() => {
        if (selectedTabOption !== 4) {
            setSecondPersionSign(false)
        }
    }, [selectedTabOption])
    const [pagesPerDocument, setPagesPerDocument] = useState({});

    const refs = useRef({});

    // Callback to set refs dynamically
    const setRef = useCallback((key, ref) => {
        refs.current[key] = ref;
    }, []);

    function onDocumentLoadSuccess({ numPages: nextNumPages }, document) {
        setPagesPerDocument((prev) => ({ ...prev, [document.Id]: nextNumPages }));

        setTimeout(() => {
            window.URL.revokeObjectURL(document.url);
        }, 1000);
    }

    function handleMouseClick(event, docId) {
        if (!refs['current'][docId]) return;

        const rect = refs['current'][docId].getBoundingClientRect();
        const x = event.clientX - rect.left;

        // Iterate through each page to determine which page was clicked
        const pages = Array.from(refs['current'][docId].querySelectorAll('.react-pdf__Page'));
        let clickedPageNumber = null;
        let relativeY = null;
        let totalOffsetY = 0;
        let pageHeight = 0;

        for (let page of pages) {
            const pageRect = page.getBoundingClientRect();
            const marginBetweenPages = 40; // Define the margin between pages
            pageHeight = pageRect.height;

            if (
                event.clientX >= pageRect.left &&
                event.clientX <= pageRect.right &&
                event.clientY >= pageRect.top &&
                event.clientY <= pageRect.bottom
            ) {
                clickedPageNumber = parseInt(page.getAttribute('data-page-number'), 10);
                relativeY = event.clientY - pageRect.top; // Y relative to the current page
                break;
            }

            // Add the height of each page to the total offset for next page
            totalOffsetY += pageHeight + marginBetweenPages;
        }

        if (clickedPageNumber === null) {
            return showNotificationToaster("Please click inside the document", "error");
        }

        const pageWidth = 600;

        // Check if click is within the boundaries, leaving 50px margin on all sides
        if (x < 50 || x > (pageWidth - 50) || relativeY < 50 || relativeY > (pageHeight - 50)) {
            return showNotificationToaster("Please click inside the document area, within the margins", "error");
        }

        // If the click is valid, proceed to add the block
        const block = { x: x - 30, y: relativeY + totalOffsetY + 30, page: clickedPageNumber, pageHeight };
        console.log("Block:", block);
        // Handle adding block to the respective list
        if (selectedTabOption === 1) {
            const prevArray = eSignBlocksList[docId] || [];
            setESignBlocksList((prevBlocks) => ({ ...prevBlocks, [docId]: [...prevArray, block] }));
        } else if (selectedTabOption === 2) {
            const prevArray = dateBlocksList[docId] || [];
            setESignDateBlocksList((prevBlocks) => ({ ...prevBlocks, [docId]: [...prevArray, block] }));
        } else if (selectedTabOption === 3) {
            const prevArray = textBlocksList[docId] || [];
            setESignTextBlocksList((prevBlocks) => ({ ...prevBlocks, [docId]: [...prevArray, block] }));
        } else if (selectedTabOption === 4) {
            const prevArray = secondPersonList[docId] || [];
            setSecondPersonList((prevBlocks) => ({ ...prevBlocks, [docId]: [...prevArray, block] }));
        }
    }


    const showRectangleBlocks = () => {
        const temp = selectedTabOption === 1 ? eSignBlocksList : selectedTabOption === 2 ? dateBlocksList : selectedTabOption === 3 ? textBlocksList : secondPersonList
        return (
            <div>
                {temp.hasOwnProperty(activeTab) && temp[activeTab].map((rect, index) => (
                    <div
                        key={index}
                        style={{
                            position: 'absolute',
                            top: rect.y + 5 - 30,
                            left: rect.x + 100,
                            width: '100px',
                            height: '30px',
                            border: '2px solid #4057E3',
                            pointerEvents: 'none',
                        }}

                    />
                ))}
            </div>
        );
    }


    const handleRemoveLastSign = () => {
        let checkLength = false
        if (selectedTabOption === 1) {
            checkLength = eSignBlocksList[activeTab].length === 1 ? true : false
            setESignBlocksList((prev) => ({ ...prev, [activeTab]: eSignBlocksList[activeTab].slice(0, -1) }));
        } else if (selectedTabOption === 2) {
            checkLength = dateBlocksList[activeTab].length === 1 ? true : false
            setESignDateBlocksList((prev) => ({ ...prev, [activeTab]: dateBlocksList[activeTab].slice(0, -1) }));
        } else if (selectedTabOption === 3) {
            checkLength = textBlocksList[activeTab].length === 1 ? true : false
            setESignTextBlocksList((prev) => ({ ...prev, [activeTab]: textBlocksList[activeTab].slice(0, -1) }));
        } else if (selectedTabOption === 4) {
            checkLength = secondPersonList[activeTab].length === 1 ? true : false
            setSecondPersonList((prev) => ({ ...prev, [activeTab]: secondPersonList[activeTab].slice(0, -1) }));
        }

        if (checkLength) {
            setSelectedTabOptionIcon((pre) => {
                const currentTabData = { ...selectedTabOptionIcon[activeTab], [selectedTabOption]: "date-block-icon" };
                return { ...pre, [activeTab]: currentTabData }
            })
        }
        deleteLastSignPositionClose()
    }

    const handleRemoveAllSign = () => {
        if (selectedTabOption === 1) {
            setESignBlocksList([]);
        } else if (selectedTabOption === 2) {
            setESignDateBlocksList([]);
        } else if (selectedTabOption === 3) {
            setESignTextBlocksList([]);
        } else if (selectedTabOption === 4) {
            setSecondPersonList([])
        }
        setSelectedTabOptionIcon((pre) => {
            const currentTabData = { ...selectedTabOptionIcon[activeTab], [selectedTabOption]: "date-block-icon" };
            return { ...pre, [activeTab]: currentTabData }
        })
        deleteAllSignPositionClose()
    }

    const handleSetSignPositions = () => {
        const getTabData = selectedTabOption === 1 ? eSignBlocksList : selectedTabOption === 2 ? dateBlocksList : textBlocksList;
        if (!getTabData[activeTab]?.length && selectedTabOption !== 4) {
            showNotificationToaster("Please set at least one position", "error");
            return;
        }
        setSelectedTabOptionIcon((pre) => {
            const currentTabData = { ...selectedTabOptionIcon[activeTab], [selectedTabOption]: "mark-complete-icon" };
            return { ...pre, [activeTab]: currentTabData }
        })
    }

    useEffect(() => {
        if (Object.keys(selectedTabOptionIcon).length) {
            let totalCount = 0
            const check = Object.values(selectedTabOptionIcon).map(item => {
                if (item['1'] === "mark-complete-icon") {
                    totalCount++
                }
            })
            if (check) {
                setTotalSignedDocuments(totalCount)
            }
        }
    }, [selectedTabOptionIcon])

    const handleReCalculateYAxis = (yaxis, pageNumber, pageHeight) => {
        const marginBetweenPages = (pageNumber - 1) * 40
        const withoutOffset = pageNumber > 1 ? pageHeight - Math.abs((yaxis - (marginBetweenPages + (pageNumber * pageHeight)))) : yaxis
        const finalOffset = pageHeight > 800 && withoutOffset > 500 ? withoutOffset - 50 : withoutOffset
        return finalOffset
    }

    const handleSubmitSetEsignature = async () => {
        setLoading(true);

        const formatPositions = (blocksList, blockId) => {
            const pages = blocksList[blockId]?.map(item => item.page).join(',') || "";
            const xPositions = blocksList[blockId]?.map(item => item.x).join(',') || "";
            const yPositions = blocksList[blockId]?.map(item => handleReCalculateYAxis(item.y, item.page, item.pageHeight)).join(',') || "";


            // If either xPositions or yPositions are blank, signPositions will be blank
            const signPositions = xPositions && yPositions ? `${xPositions}#${yPositions}` : "";
            return { pageNo: pages, signPos: signPositions };
        };


        const finalData = Object.values(selectedDocumentForEsign).map(block => {
            const { FileName, Id, Name } = block;
            const eSignPositions = formatPositions(eSignBlocksList, Id);
            const datePositions = formatPositions(dateBlocksList, Id);
            const textPositions = formatPositions(textBlocksList, Id);
            const signPositions = formatPositions(secondPersonList, Id);

            return {
                fileName: FileName,
                loanId: props.loanid,
                entityId: selectedEntity,
                documentId: block.DocumentId,
                entityName: entityList.find(entity => entity.value === selectedEntity).label,
                isSP: isValidEmailOption(secondPersonEmail),
                emailSP: secondPersonEmail,
                signPositions: {
                    pageNo: eSignPositions.pageNo,
                    signPos: eSignPositions.signPos,
                    spPageNo: signPositions.pageNo,
                    spSignPos: signPositions.signPos
                },
                datePositions: {
                    pageNo: datePositions.pageNo,
                    datePos: datePositions.signPos,
                },
                textboxPositions: {
                    pageNo: textPositions.pageNo,
                    textPos: textPositions.signPos,
                }
            };
        });

        const setCordinates = await SaveDocumentCoordinates(finalData);
        if (setCordinates.status === 200) {
            showNotificationToaster("Coordinates has been save successfully", "success");
            getDoucmentsListFun()
        } else {
            showNotificationToaster(setCordinates?.response?.data?.message || "An error occurred while set position", "error");
        }
        setLoading(false);
        setESignaturePopup(false);
        setSelectedDocumentsForEsign({})
        setESignBlocksList({});
        setESignDateBlocksList({});
        setESignTextBlocksList({});
        setSecondPersonList({})
        setSecondPersonEmail("")
        setSelectedRows({})
        setToggleRowSelectionStatuses({})
    }


    // -------------------------------------------------------------------------------------
    const [selectedDocumentForSendToSignature, setSelectedDocumentForSendToSignature] = useState([]);
    const [emailRecipientsForSign, setEmailRecipientsForSign] = useState("");
    const [emailNotesForSign, setEmailNotesForSign] = useState("");

    const handleSendToSignature = async () => {
        if (!isValidEmailOption(emailRecipientsForSign)) {
            showNotificationToaster("Please enter a valid recipient email", "error");
            return;
        }

        setLoading(true)
        const finalData = selectedDocumentForSendToSignature.map(doc => {
            return {
                documentFileName: doc.DocumentType === "AGD" ? doc.FileName : doc.Name,
                emailid: emailRecipientsForSign,
                emailTextMessage: emailNotesForSign,
                documentName: doc.DocumentType === "BGD" ? doc.TD_Name : doc.Name,
                ownerId: parseInt(doc.OwnerID),
                loanId: parseInt(props.loanid),
                docType: doc.DocumentType === "AGD" ? "AGD" : doc.DocType,
                documentId: parseInt(doc.DocumentId),
                entityId: parseInt(selectedEntity),
                isSigned: true,
                entityName: entityList.find(entity => entity.value === selectedEntity).label,
                uuDocumentpath: "",
                email_guid: "",
                documentType: doc.DocumentType
            }
        })

        await MultipleDocumentSentSignature(finalData, user.user.id);
        showNotificationToaster("Document sent for e-signature", "success");

        setLoading(false);

        getDoucmentsListFun()
        setEmailNotesForSign("");
        setEmailRecipientsForSign("");
        setSendForSignaturePopUp(false);
        setSelectedDocumentForSendToSignature([]);
        setSelectedRows({})
        setToggleRowSelectionStatuses({})
    }

    // ---------------------------------------------------------------------
    const handleUploadDocument = async (event) => {
        setLoading(true)
        event.preventDefault();

        let formData = new FormData();
        formData.append("File", file);

        const params = {
            Id: documents[uploadDocumentIndex]?.Id,
            LoanId: props.loanid,
            OnwerId: documents[uploadDocumentIndex].OwnerID,
            EntityId: selectedEntity,
            DocumentId: documents[uploadDocumentIndex].DocumentId,
            isSigned: borrowerNeedsSign, // send true in case of AGD only if selected && false in case of boororwer update
            Verified: documents[uploadDocumentIndex]['DocumentType'] === "AGD" ? false : true, // send false in case of AGD, true in case of BGD
            Name: documents[uploadDocumentIndex].Name,
            UUDocumentPath: "",
            DocumentType: documents[uploadDocumentIndex].DocumentType,
            UserId: user.user.id,
            SessionId: getSessionId()
        }

        // Add any further form submission logic here (e.g., API call)
        const uploadDoc = await UploadDocument(formData, params);
        if (uploadDoc.status === 200) {
            showNotificationToaster("Document uploaded successfully", "success");
            setFile(null);
            setFileURL(null);
            setUploadProgress(0);
            uploadDocumentPopupClose();
            getDoucmentsListFun()
        } else {
            showNotificationToaster(uploadDoc?.response?.data?.message || "An error occurred while updating the document", "error");
        }
        setLoading(false);
    };



    const handleDownloadDocument = async (rows) => {
        setLoading(true)
        const downloadDoc = await GetDownloadDocumentHistoryFile(rows)
        if (downloadDoc) {
            showNotificationToaster("Document downloaded successfully", "success");
        } else {
            showNotificationToaster("An error occurred while downloading the document", "error");
        }
        setLoading(false);

    }

    const handleViewVerisonHistory = async (row) => {
        setLoading(true)
        setVersionHistoryPopup(true);
        setVersionHistoryList([]);
        setVersionHistoryFileName(row.Name);
        const params = {
            SessionId: getSessionId(),
            LoanId: selectedEntity,
            DocumentId: row.Id,
            DocumentType: row.DocumentType,
            OwnerId: row.OwnerID
        }
        const data = await GetDocumentsHistory(params)
        if (data.status === 200) {
            if (data.resultObject) {
                console.log(JSON.parse(data.resultObject))
                if (row.DocumentType === "BGD") {
                    setVersionHistoryList(JSON.parse(data.resultObject));
                } else {
                    // setVersionHistoryList(JSON.parse(data.resultObject).slice(0, -1));
                    setVersionHistoryList(JSON.parse(data.resultObject));
                }
            } else {
                setVersionHistoryList([])
            }
        }
        setLoading(false)
    }

    const handleVersionReview = async (file) => {
        setLoading(true);
        try {
            for (const element of Object.values(file)) {
                let data = null;
                if (element.DocumentType === "BGD" && element.Status === "To sign" && (element.FileName === "" || element.DownloadFileName === "")) {
                    const params = {
                        LoanId: element.LoanId,
                        fileName: element.TD_Name || element.FileName1,
                        DwnFileName: element.Name || element.DownloadFileName,
                        ownerId: element.OwnerID || element.OwnerId,
                        UserId: user.user.id,
                        Doctype: element.DocType
                    };
                    data = await ViewDocumentInBrowser(params, true);
                } else {
                    data = await ViewDocumentInBrowser({
                        filename: element.DownloadFileName || element.FileName,
                        isSigned: element.Status === "Completed" || (element.DocumentType === "BGD" && element.Status === "To sign" && element.DownloadFileName !== ""),
                        Status: element.Status,
                        DocumentType: element.DocumentType
                    });
                }

                if (data.status === 200) {
                    const blob = new Blob([data.data], { type: 'application/pdf' });
                    const url = window.URL.createObjectURL(blob);
                    window.open(url, '_blank');
                    // Revoke the object URL after a delay to ensure the document is loaded
                    setTimeout(() => window.URL.revokeObjectURL(url), 10 * 60 * 1000);
                } else {
                    showNotificationToaster(`${element?.Name || element?.FileName} document not available`, "error");
                }
            }
        } catch (error) {
            console.error("Error handling version review:", error);
        } finally {
            setLoading(false);  // Ensure loading is set to false after all operations
        }
    };

    const extraHeaderRow = (entityList, selectedOption) => {
        return (
            <tr className="document_table_heading">
                <td colSpan="7">
                    <div className="d-flex justify-content-between align-items-center">
                        <div>
                            <Select
                                className="basic-single"
                                classNamePrefix="select"
                                id="businessOptions"
                                name="businessOptions"
                                menuPortalTarget={document.body}
                                styles={{
                                    menuPortal: (base) => ({ ...base, zIndex: 9999 }),
                                }}
                                options={entityList}
                                defaultValue={entityList.find(entity => entity.value === selectedEntity)}
                                onChange={(selectedOption) => {
                                    setPageIndex(1);
                                    setSelectedEntity(selectedOption.value)
                                }
                                }
                            />
                        </div>
                        {/* {userRole !== 'Borrower' && (
                            <Link onClick={() => setGenerateReportPopup(true)} className="grid-link">Generate report</Link>
                        )} */}
                    </div>
                </td>
            </tr>
        )
    }

    const handleESignPositions = async (document) => {
        setLoading(true);
        const finalDataPromises = Object.keys(document).map(async (element) => {
            const params = { SessionId: getSessionId(), filename: document[element].FileName, isSigned: false };
            const data = await ViewDocumentInBrowser(params);

            if (data.status === 200) {
                const blob = new Blob([data.data], { type: 'application/pdf' });
                const url = window.URL.createObjectURL(blob);
                return { url: url, ...document[element] };
            }
            return null;
        });

        // Wait for all promises to resolve
        const finalData = await Promise.all(finalDataPromises);

        let filteredData = {};
        let tabOptionIcon = {};
        finalData.filter(item => item !== null).forEach(item => {
            filteredData[item.Id] = item;
            tabOptionIcon[item.Id] = { 1: "date-block-icon", 2: "date-block-icon", 3: "date-block-icon", 4: "date-block-icon" };
        });

        setLoading(false);
        setSelectedTabOption(1);
        setESignaturePopup(true);
        setSelectedDocumentsForEsign(filteredData);
        setSelectedTabOptionIcon(tabOptionIcon);
    }

    const isValidEmailOption = (email) => {
        const emailRegex = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,6}$/;
        return emailRegex.test(email);
    }

    const showActionButtons = (document, status, role, index) => {
        const canUpload = status === 'To upload' || (status === 'To sign' && document.DocumentType === 'BGD');
        const canDropdown = status !== 'To upload'
        const isAgdDocument = document.DocumentType === 'AGD';

        return (
            <div className="action-col d-flex justify-content-end">
                {/* Upload/Reupload Link */}
                <Link
                    onClick={() => {
                        setFile(null);
                        setFileURL(null);
                        setUploadProgress(0);
                        setBorrowerNeedsSign(false);
                        setUploadDocumentPopup(true);
                        setUploadDocumentIndex(index);
                    }}
                    className="grid-link mr-4 mt-1"
                >
                    {canUpload ? 'Upload' : 'Reupload'}
                </Link>

                {/* Dropdown for additional actions */}
                <Dropdown>
                    <Dropdown.Toggle className={`doc_option_dots ${!canDropdown ? 'mr-3' : ''}`}>
                        {canDropdown && '...'}
                    </Dropdown.Toggle>
                    {canDropdown &&
                        <Dropdown.Menu className="doc_app_options">
                            {/* Review Option */}
                            <div onClick={() => handleVersionReview({ [document.Id]: document })}>
                                <img className="mr-2" src={Review_icon} alt="Review" />
                                Review
                            </div>

                            {/* Download Option */}
                            <div onClick={() => handleDownloadDocument({ [document.Id]: document })}>
                                <img className="mr-2" src={download_icon} alt="Download" />
                                Download
                            </div>

                            {/* Send Email Option */}
                            <div onClick={() => {
                                setSendingEmailDocument([document]);
                                setSendEmailPopup(true);
                            }}>
                                <img className="mr-2" src={send_email_icon} alt="Send email" />
                                Send email
                            </div>

                            {/* Version History Option */}
                            <div onClick={() => handleViewVerisonHistory(document)}>
                                <img className="mr-2" src={version_history_icon} alt="Version history" />
                                Version history
                            </div>

                            {/* Actions for 'Uploaded' Status and AGD Document */}
                            {status === 'Uploaded' && isAgdDocument && role !== "Borrower" && (
                                <>
                                    <div onClick={() => handleESignPositions({ [document.Id]: document })}>
                                        <img className="mr-2" src={set_esign_icon} alt=" Set e-signature" />
                                        Set e-signature
                                    </div>
                                    <div onClick={() => {
                                        setSelectedDocumentForCompelete(document);
                                        setMarkCompletePopup(true);
                                    }}>
                                        <img className="mr-2" src={mark_com_icon} alt="Mark as completed" />
                                        Mark as completed
                                    </div>
                                </>
                            )}

                            {/* Actions for 'To Sign' Status */}
                            {status === 'To sign' && role !== "Borrower" && (
                                <>
                                    <div onClick={() => {
                                        setSendForSignaturePopUp(true);
                                        setSelectedDocumentForSendToSignature([document]);
                                    }}>
                                        <img className="mr-2" src={Send_to_signature} alt="Send for signature" />
                                        Send for signature
                                    </div>
                                    {isAgdDocument && (
                                        <div onClick={() => {
                                            setSelectedDocumentForCompelete(document);
                                            setMarkCompletePopup(true);
                                        }}>
                                            <img className="mr-2" src={mark_com_icon} alt="Mark as completed" />
                                            Mark as completed
                                        </div>
                                    )}
                                </>
                            )}

                            {/* Actions for 'Sent for Signature' Status */}
                            {status === 'Sent for signature' && role !== "Borrower" && (
                                <>
                                    {/* <div>
                                        <img className="mr-2" src={Remind_to_sign} alt="Remind to sign" />
                                        Remind to sign
                                    </div> */}
                                    <div onClick={() => {
                                        setcancelESignaturePopup(true)
                                        setSelectedDocumentForCancelSendToSing([document])
                                    }
                                    }>
                                        <img className="mr-2" src={Cancel_sending_for_signature} alt="Cancel sending for signature" />
                                        Cancel sending for signature
                                    </div>
                                </>
                            )
                            }
                        </Dropdown.Menu>
                    }
                </Dropdown>
            </div>
        )
    };

    const selectAllRowsHeader = () => {
        return (
            <input
                type="checkbox"
                className="form-check-input ml-1"
                checked={allSelectedRows}
                onChange={(e) => {
                    setAllSelectedRows(e.target.checked);
                    const alreadySelectedRows = Object.keys(selectedRows);
                    if (e.target.checked) {
                        documents.forEach(element => {
                            if (!alreadySelectedRows.includes(element.Id)) {
                                toggleRowSelection(element.Id, element);
                            }
                        })
                    } else {
                        documents.forEach(element => {
                            if (alreadySelectedRows.includes(element.Id)) {
                                toggleRowSelection(element.Id, element);
                            }
                        })
                    }
                }}
            />
        )
    }

    useEffect(() => {
        setAllSelectedRows(documents.length === Object.keys(selectedRows).length);
    }, [selectedRows])

    const dynamicTableDisplay = (document, index) => {
        return (
            <tr key={document?.Id} className={`${selectedRows.hasOwnProperty(document?.Id) ? "selected-row " : ""}${document?.status?.replace(/\s+/g, '_')?.toLowerCase()}`}>
                <td>
                    <input
                        type="checkbox"
                        className="form-check-input ml-1"
                        checked={selectedRows.hasOwnProperty(document?.Id)}
                        onChange={() => toggleRowSelection(document?.Id, document)}
                    />
                </td>
                <td>{document?.Name}</td>
                <td><span className="text-light doc-date">{document?.UploadedDate}</span></td>
                <td className={document?.Status.replaceAll(" ", "_")}>
                    <div className="doc-status">
                        {document?.Status}
                        <span className="lm-tooltip ml-1" data-tooltip={statusTooltipMessages[document?.Status]} data-tooltip-pos="up" data-tooltip-length="cutsom"> <img src={Info_tooltip} alt="" /> </span>
                    </div>
                </td>
                {userRole !== 'Borrower' && (
                    <>
                        <td>
                            <div className="lm-toggle-switch">
                                <input
                                    type="checkbox"
                                    id={`${document.Id}-activate`}
                                    checked={isActive[index] || false}
                                    onChange={() => handleActiveChange(index)}
                                />
                                <label htmlFor={`${document.Id}-activate`}>Activate</label>
                            </div>
                        </td>
                        <td>
                            <div key={document.Id} className="lm-toggle-switch">
                                <div className="lm-toggle-switch">
                                    <input
                                        type="checkbox"
                                        id={`${document.Id}-verify`}
                                        checked={isVerified[index] || false}
                                        onChange={() => handleVerifyChange(index)}
                                    />
                                    <label htmlFor={`${document.Id}-verify`}>Verify</label>
                                </div>
                            </div>
                        </td>
                    </>
                )}
                <td>
                    {showActionButtons(document, document?.Status, userRole, index)}
                </td>
            </tr>
        )
    }


    const ActionFooter = ({ selectedRows, toggleRowSelectionStatuses, handleVersionReview, handleDownloadDocument, setSendingEmailDocument, setSendEmailPopup, handleESignPositions, setSendForSignaturePopUp, setSelectedDocumentForSendToSignature }) => {
        const selectedRowCount = Object.keys(selectedRows).length;
        const uniqueStatuses = [...new Set(Object.values(toggleRowSelectionStatuses))];
        const hasSingleStatus = uniqueStatuses.length === 1;
        const firstStatus = uniqueStatuses[0];
        const isNotToUpload = firstStatus && !firstStatus.includes("To upload");

        return (
            <>
                {selectedRowCount > 0 && (
                    <div className="sticky-btn-footer sticky-slected-rows d-flex">
                        <p><b>{selectedRowCount}</b> items selected</p>

                        {isNotToUpload && (
                            <>
                                <button type="button" className="lm-button2 ml-5" onClick={(e) => handleVersionReview(selectedRows)}>Review</button>
                                <button type="button" className="lm-button2 ml-3" onClick={(e) => handleDownloadDocument(selectedRows)}>Download</button>
                                <button
                                    type="button"
                                    className="lm-button2 ml-3"
                                    onClick={() => {
                                        setSendingEmailDocument(Object.values(selectedRows));
                                        setSendEmailPopup(true);
                                    }}
                                >
                                    Send Email
                                </button>

                                {hasSingleStatus && (
                                    <>
                                        {firstStatus === "Uploaded" && userRole !== "Borrower" && (
                                            <button type="button" className="lm-button2 ml-3" onClick={() => handleESignPositions(selectedRows)}>
                                                Set Signature Fields
                                            </button>
                                        )}
                                        {firstStatus === "To sign" && userRole !== "Borrower" && (
                                            <>
                                                <button
                                                    type="button"
                                                    className="lm-button2 ml-3"
                                                    onClick={() => {
                                                        setSendForSignaturePopUp(true);
                                                        setSelectedDocumentForSendToSignature(Object.values(selectedRows));
                                                    }}
                                                >
                                                    Send for Signature
                                                </button>
                                                {/* <button type="button" className="lm-button2 ml-3">Mark as completed</button> */}
                                            </>
                                        )}
                                        {firstStatus === "Sent for signature" && userRole !== "Borrower" && (
                                            <>
                                                {/* <button type="button" className="lm-button2 ml-3">Reminder</button> */}
                                                <button type="button" className="lm-button2 ml-3"
                                                    onClick={() => {
                                                        setcancelESignaturePopup(true)
                                                        setSelectedDocumentForCancelSendToSing(Object.values(selectedRows))
                                                    }}
                                                >
                                                    Cancel Signature Request
                                                </button>
                                            </>
                                        )}
                                    </>
                                )}
                            </>
                        )}
                    </div>
                )}
            </>
        );
    };



    return (
        <>
            <SidePanel />

            <ProfileHeader />

            {loading ? (
                <Preloader />
            ) : (
                <div>
                    <div className="pb-5 documet-wrap overflow-hidden">
                        <div className="grid-head">
                            <div className="d-flex justify-content-between">
                                <h3 className="mb-3">Documents ({totalData})</h3>
                                {userRole !== 'Borrower' && (
                                    <a onClick={() => setNewDocumentPopup(true)} className="lm-button3 mb-3 ml-auto w-max-content">
                                        <span>+</span> Add document
                                    </a>
                                )}
                            </div>
                            <div className="filter-search-group d-flex justify-content-between">
                                <Select
                                    className="basic-single"
                                    classNamePrefix="select"
                                    id="status"
                                    name="status"
                                    options={statusOptions}
                                    defaultValue={statusOptions.find(obj => obj.value === status)}
                                    onChange={(selectedOption) => setStatus(selectedOption.value)}
                                />
                                <div className="lm-search-bar mt-3 mt-sm-0 mb-0">
                                    <input type="text" id="searchInput" placeholder="Search by name" value={searchText}
                                        onChange={(e) => {
                                            setPageIndex(1)
                                            setSearchText(e.target.value)
                                        }}
                                    />
                                </div>
                            </div>
                        </div>

                        <DynamicTable
                            data={documents}
                            dynamicTableDisplay={dynamicTableDisplay}
                            columns={DocumentsListHeader}
                            changePageSize={setPageSize}
                            pageSize={pageSize}
                            total={totalData}
                            setList={setDocumentList}
                            setPageIndex={setPageIndex}
                            pageIndex={pageIndex}
                            extraRow={extraHeaderRow}
                            entityList={entityList}
                            selectedOption={selectedEntity}
                            selectAllRowsHeader={selectAllRowsHeader}
                        />

                    </div>

                    {/* this div will visible if any row selected from the the above table */}
                    <ActionFooter
                        selectedRows={selectedRows}
                        toggleRowSelectionStatuses={toggleRowSelectionStatuses}
                        handleVersionReview={handleVersionReview}
                        handleDownloadDocument={handleDownloadDocument}
                        setSendingEmailDocument={setSendingEmailDocument}
                        setSendEmailPopup={setSendEmailPopup}
                        handleESignPositions={handleESignPositions}
                        setSendForSignaturePopUp={setSendForSignaturePopUp}
                        setSelectedDocumentForSendToSignature={setSelectedDocumentForSendToSignature}
                    />


                    {/*Add New document Popup / Modal */}
                    <Modal
                        // size="md"
                        show={newDocumentPopupShow}
                        onHide={() => setNewDocumentPopup(false)}
                        aria-labelledby="newDocumentPopupLabel"
                        backdrop="static"
                        keyboard={false}
                        centered
                    >
                        <div className="modal-dialog modal-dialog-centered modal-dialog-scrollable">
                            <div className="modal-content">
                                <div className="modal-header">
                                    <h5 className="modal-title" id="newDocumentPopupLabel">New document</h5>
                                    <button type="button" className="close"
                                        onClick={handleDocumentModalClose}
                                    >
                                        <span aria-hidden="true"><img src={close_popup} alt="" /></span>
                                    </button>
                                </div>
                                <form>
                                    <Modal.Body>
                                        <div className="form-group">
                                            <label htmlFor="Business_entity">Business entity</label>
                                            <Select
                                                className="basic-single"
                                                classNamePrefix="select"
                                                menuPortalTarget={document.body}
                                                styles={{
                                                    menuPortal: base => ({ ...base, zIndex: 9999 })
                                                }}
                                                id="Business_entity"
                                                name="Business_entity"
                                                options={entityList}
                                                defaultValue={entityList[0]}
                                                value={entityList.find(obj => obj.value === selectedEntityForAddDocument)}
                                                onChange={(e) => {
                                                    setSelectedEntityForAddDocument(e.value);
                                                }}
                                            />
                                        </div>
                                        <div className="form-group mb-0">
                                            <label htmlFor="Required_documents">Required documents</label>
                                            <Select
                                                className="basic-single"
                                                classNamePrefix="select"
                                                menuPortalTarget={document.body}
                                                styles={{
                                                    menuPortal: base => ({ ...base, zIndex: 9999 })
                                                }}
                                                closeMenuOnSelect={false}
                                                components={animatedComponents}
                                                isMulti
                                                options={allDocumentsList}
                                                value={selectedRequiredDoucment}
                                                onChange={(e) => {
                                                    const newValues = e.map((item) => item.value);
                                                    const updatedSelection = selectedRequiredDoucment.filter((doc) => newValues.includes(doc.value));

                                                    // Add new selected documents that are not in the current selection
                                                    e.forEach((item) => {
                                                        if (!selectedRequiredDoucment.find((doc) => doc.value === item.value)) {
                                                            updatedSelection.push(item);
                                                        }
                                                    });
                                                    setSelectedRequiredDoucment(updatedSelection);
                                                }}
                                            />
                                        </div>
                                    </Modal.Body>
                                    <div className="modal-footer la-app-modal-footer">
                                        <button type="button" className="lm-button1" onClick={handleAddNewDocument}>Save</button>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </Modal>
                    {/* Add New document Popup / Modal */}


                    {/* <!--Generate report drawer--> */}
                    <Modal
                        className="fade-right drawer-modal generate-report-modal"
                        show={generateReportPopupShow}
                        onHide={() => setGenerateReportPopup(false)}
                        aria-labelledby="generateReportPopupLabel"
                    >
                        <div className="modal-dialog">
                            <div className="modal-content">
                                <div className="drawer-head d-flex justify-content-between">
                                    <div>
                                        <h3>Generate report</h3>
                                    </div>
                                    <button type="button" className="close" onClick={generateReportPopupClose}>
                                        <span aria-hidden="true"><img src={close_popup} alt="" /></span>
                                    </button>
                                </div>
                                <div className="drawer-body">
                                    {/* search bar */}
                                    <div className="lm-search-bar mt-2 mb-4">
                                        <input type="text" id="searchInput" placeholder="Search a report" />
                                    </div>
                                    {/* single box */}
                                    <div className="report-doc-box d-flex justify-content-between">
                                        <div className="report-doc-name d-flex">
                                            <img src={Report_Icon} alt="" />
                                            <p>
                                                Paynet Report
                                            </p>
                                        </div>
                                        <button type="button" className="lm-button1">Generate</button>
                                    </div>

                                    {/* single report generated box */}
                                    <div className="report-doc-box d-flex justify-content-between">
                                        <div className="report-doc-name d-flex">
                                            <img src={Report_Icon} alt="" />
                                            <div>
                                                <p>Paynet Report</p>
                                                <div className="text-light">Generated on Nov 10, 2023</div>
                                            </div>

                                        </div>
                                        <button type="button" className="lm-button1 disabled-btn">Generate</button>
                                    </div>

                                    {/* bottom overlay effect dont remove */}
                                    <div className="generate-report-bottom-overlay"></div>
                                </div>
                            </div>
                        </div>
                    </Modal>
                    {/* <!--Generate report drawer--> */}


                    {/* <!--version History drawer--> */}
                    <Modal
                        className="fade-right drawer-modal version-history-modal"
                        show={versionHistoryPopupShow}
                        onHide={() => setVersionHistoryPopup(false)}
                        aria-labelledby="versionHistoryPopupLabel"
                    >
                        <div className="modal-dialog">
                            <div className="modal-content">
                                <div className="drawer-head d-flex justify-content-between">
                                    <div className="drawer-title">
                                        <h3>{versionHistoryFileName}</h3>
                                        <span className="text-light">Version history</span>
                                    </div>
                                    <button type="button" className="close" onClick={versionHistoryPopupClose}>
                                        <span aria-hidden="true"><img src={close_popup} alt="" /></span>
                                    </button>
                                </div>
                                <div className="drawer-body mt-3">
                                    {
                                        versionHistoryList.length ? versionHistoryList.map(list => {
                                            return (
                                                <div className="version-history-table-row">
                                                    <div className="version-histry-date text-light">
                                                        {convertToLocalTime(list?.CreatedOn)}
                                                    </div>
                                                    <div className="version-history-name">
                                                        <b>{list?.Status}</b>
                                                        <br></br>
                                                        {list?.UploadedBy}
                                                    </div>
                                                    <div>
                                                        <Link className="grid-link mr-4 mt-1" onClick={(e) => handleVersionReview({ [list.DocumentId]: list })}>Review</Link>
                                                    </div>
                                                </div>
                                            )
                                        }) : ""}

                                </div>
                                <div className="drawer-footer d-flex">
                                    <button type="button" className="lm-button1 ml-auto">Save</button>
                                </div>
                            </div>
                        </div>
                    </Modal>
                    {/* <!--version History drawer--> */}


                    {/*Upload a document Popup / Modal */}
                    <Modal
                        size="md"
                        show={uploadDocumentPopupShow}
                        onHide={() => setUploadDocumentPopup(false)}
                        aria-labelledby="uploadDocumentPopupLabel"
                        backdrop="static"
                        keyboard={false}
                        centered
                    >
                        <div className="modal-dialog modal-md modal-dialog-centered modal-dialog-scrollable upload-doc-popup">
                            <div className="modal-content">
                                <div className="modal-header">
                                    <h5 className="modal-title" id="uploadDocumentPopupLabel">Upload a document
                                        <br></br>
                                        <span className="text-light uploadDocumentPopup-subtitle">
                                            <img className="mr-1" src={document_icon} alt="" />{documents[uploadDocumentIndex]?.Name}</span>
                                    </h5>

                                    <button type="button" className="close" onClick={uploadDocumentPopupClose}>
                                        <span aria-hidden="true"><img src={close_popup} alt="" /></span>
                                    </button>
                                </div>
                                <form >
                                    <Modal.Body>

                                        <div className="mb-3">
                                            <div className="upload-box py-4">
                                                <input type="file" id="upload-file" accept=".pdf" onChange={handleFileChange} />
                                                <div className="mx-auto mb-2">
                                                    <svg className="upload-icon" width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                        <path d="M6.42725 4.66665L9.99867 1.11108L13.5701 4.66665" stroke="#4057E3" strokeWidth="1.5" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
                                                        <path d="M9.99902 1.11108V12.963" stroke="#4057E3" strokeWidth="1.5" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
                                                        <path d="M13.5713 8.22217H17.1427L18.3332 18.8889H1.6665L2.85698 8.22217H6.42841" stroke="#4057E3" strokeWidth="1.5" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
                                                    </svg>
                                                </div>
                                                <div className="upload-text">
                                                    Drop PDF file here to upload or <button type="button" id="upload-button">choose file</button>
                                                </div>
                                            </div>
                                        </div>
                                        {
                                            file ? (
                                                <>
                                                    <div className="uploaded-file-box d-flex justify-content-between">
                                                        <div className="d-flex">
                                                            <img src={pdf_file_icon} alt="PDF Icon" />
                                                            <div className="ml-2">
                                                                <div className="file-name">{file?.name}</div>
                                                                <span className="text-light file-size">{(file?.size / (1024 * 1024)).toFixed(2)} MB</span>
                                                            </div>
                                                        </div>

                                                        <div className="d-flex place-items-center">
                                                            <span className="doc-upload-percentage">{uploadProgress}%</span>
                                                            <button type='button' className="view-doc-button" onClick={handleViewDocument}>
                                                                <img className="mx-2" src={pass_eye} alt="View Document" />
                                                            </button>
                                                            <button type="button" className="close" onClick={removeDocument}>
                                                                <span><img src={close_popup} alt="Close" /></span>
                                                            </button>
                                                        </div>
                                                    </div>

                                                    <div className="upload-progress">
                                                        <div className="upload-progress-bar" style={{ width: `${uploadProgress}%` }}></div>
                                                    </div>
                                                    {
                                                        documents[uploadDocumentIndex]?.DocumentType === "AGD" && userRole !== "Borrower" ? (
                                                            <div className="form-group mb-0 mt-3">
                                                                <div className="label pb-1">Does the borrower need to sign this document?</div>
                                                                <div className="form-check form-check-inline">
                                                                    <input
                                                                        className="form-check-input"
                                                                        type="radio"
                                                                        name="borrower_need_sign"
                                                                        id="borrower_need_sign_Yes"
                                                                        value={true}
                                                                        checked={borrowerNeedsSign === true}
                                                                        onChange={(e) => setBorrowerNeedsSign(e.target.value === 'true')}
                                                                    />
                                                                    <label className="form-check-label" htmlFor="borrower_need_sign_Yes">Yes</label>
                                                                </div>
                                                                <div className="form-check form-check-inline">
                                                                    <input
                                                                        className="form-check-input"
                                                                        type="radio"
                                                                        name="borrower_need_sign"
                                                                        id="borrower_need_sign_No"
                                                                        value={false}
                                                                        checked={borrowerNeedsSign === false}
                                                                        onChange={(e) => setBorrowerNeedsSign(e.target.value === 'true')}
                                                                    />
                                                                    <label className="form-check-label" htmlFor="borrower_need_sign_No">No</label>
                                                                </div>
                                                            </div>
                                                        ) : ""
                                                    }

                                                </>
                                            ) : ""
                                        }

                                    </Modal.Body>
                                    <div className="modal-footer la-app-modal-footer">
                                        <button type="button" className="lm-button2" onClick={uploadDocumentPopupClose}>Cancel</button>
                                        <button type="submit" className="lm-button1" onClick={handleUploadDocument}>Save</button>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </Modal>
                    {/* Upload a document Popup / Modal */}


                    {/* Send Email to Sign Position Popup / Modal */}
                    <Modal
                        size="md"
                        show={sendForSignaturePopUp}
                        onHide={() => setSendForSignaturePopUp(false)}
                        aria-labelledby="sendEmailPopupLabel"
                        backdrop="static"
                        keyboard={false}
                        centered
                    >
                        <div className="modal-dialog modal-md modal-dialog-centered modal-dialog-scrollable">
                            <div className="modal-content">
                                <div className="modal-header">
                                    <h5 className="modal-title" id="sendEmailPopupLabel">Send for Signature</h5>
                                    <button type="button" className="close" onClick={sendForSignatureClose}>
                                        <span aria-hidden="true"><img src={close_popup} alt="" /></span>
                                    </button>
                                </div>
                                <form>
                                    <Modal.Body>
                                        <div className="form-group">
                                            <label htmlFor="Recipient">Recipient</label>
                                            <input
                                                type="text"
                                                id="Recipient"
                                                className='form-control'
                                                value={emailRecipientsForSign}
                                                onChange={(e) => setEmailRecipientsForSign(e.target.value)}
                                                maxLength={50}
                                            />
                                        </div>

                                        <div className="form-group">
                                            <label htmlFor="Notes">Notes</label>
                                            <textarea
                                                className="form-control"
                                                id="Notes"
                                                value={emailNotesForSign}
                                                onChange={(e) => setEmailNotesForSign(e.target.value)}
                                                maxLength={250}
                                            />

                                        </div>
                                    </Modal.Body>
                                    <div className="modal-footer la-app-modal-footer">
                                        <button type="button" className="lm-button2" onClick={sendForSignatureClose}>Cancel</button>
                                        <button type="button" className="lm-button1" onClick={handleSendToSignature}>Send</button>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </Modal>
                    {/* Send Email to Sign Position Popup / Modal */}


                    {/*Send Email Popup / Modal */}
                    <Modal
                        size="md"
                        show={sendEmailPopupShow}
                        onHide={() => setSendEmailPopup(false)}
                        aria-labelledby="sendEmailPopupLabel"
                        backdrop="static"
                        keyboard={false}
                        centered
                    >
                        <div className="modal-dialog modal-md modal-dialog-centered modal-dialog-scrollable">
                            <div className="modal-content">
                                <div className="modal-header">
                                    <h5 className="modal-title" id="sendEmailPopupLabel">Send email</h5>
                                    <button type="button" className="close" onClick={sendEmailPopupClose}>
                                        <span aria-hidden="true"><img src={close_popup} alt="" /></span>
                                    </button>
                                </div>
                                <form>
                                    <Modal.Body>
                                        <div className="form-group">
                                            <label htmlFor="Recipient">Recipient (s)</label>
                                            <CreatableSelect
                                                className="basic-single emailbox-input"
                                                classNamePrefix="select"
                                                placeholder={<div className="email-list-placeholder"><span className="placeholder-add-sign">+</span> Add</div>}
                                                isMulti
                                                // options={RecipientsOptions}
                                                onChange={(selectedOption) => setEmailRecipients(selectedOption)}
                                                isValidNewOption={isValidEmailOption}
                                            />
                                        </div>

                                        <div className="form-group">
                                            <label htmlFor="Notes">Notes</label>
                                            <textarea
                                                className="form-control"
                                                id="Notes" value={emailNotes}
                                                onChange={(e) => setEmailNotes(e.target.value)}
                                            />

                                        </div>
                                    </Modal.Body>
                                    <div className="modal-footer la-app-modal-footer">
                                        <button type="button" className="lm-button2" onClick={sendEmailPopupClose}>Cancel</button>
                                        <button type="button" className="lm-button1" onClick={sendDocumentToEmail}>Send</button>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </Modal>
                    {/* Send Email Popup / Modal */}


                    {/* <!--set e-Signature drawer--> */}
                    <Modal
                        className="fade-right drawer-modal e-signature-drawer"
                        show={ESignaturePopupShow}
                        onHide={() => setESignaturePopup(false)}
                        aria-labelledby="ESignaturePopupLabel"
                    >
                        <div className="drawer-head d-flex justify-content-between">
                            <div>
                                <h3>Set e-signature</h3>
                            </div>
                            <button type="button" className="close" onClick={ESignaturePopupClose}>
                                <span aria-hidden="true"><img src={close_popup} alt="" /></span>
                            </button>
                        </div>
                        <div className="drawer-body">
                            {
                                Object.keys(selectedDocumentForEsign).length > 1 && (
                                    <div className="d-flex pb-3 align-items-center justify-content-between">
                                        <div className="lm-nav-tabs">
                                            <ul className="nav nav-tabs" id="laTab" role="tablist">
                                                {
                                                    Object.values(selectedDocumentForEsign).map(element => {
                                                        return (
                                                            <li key={element.Id} className="nav-item">
                                                                <button className={`nav-link ${activeTab === element.Id ? "active" : ""}`} onClick={() => handleTabClick(element.Id)}>
                                                                    {element.Name}
                                                                </button>
                                                            </li>
                                                        )
                                                    })
                                                }
                                            </ul>
                                        </div>
                                        <div className="d-flex place-items-center">
                                            <div className="doc-progress mr-3">
                                                <svg>
                                                    <circle cx="10" cy="10" r="8"></circle>
                                                    <circle cx="10" cy="10" r="8"></circle>
                                                </svg>
                                                <span>{totalSignedDocuments} / {Object.keys(selectedDocumentForEsign).length} documents completed </span>
                                            </div>
                                            {/* manage "disabled-btn" class for button */}
                                            <button type="button" className="lm-button1 disabled-btn" onClick={handlePreviousTab}>Previous</button>
                                            <button type="button" className="lm-button2 ml-2" onClick={handleNextTab}>Next</button>
                                        </div>
                                    </div>
                                )
                            }

                            <div className="tab-content" id="laTabContent">
                                {
                                    Object.keys(selectedDocumentForEsign).length > 0 && Object.values(selectedDocumentForEsign).map(document => {
                                        return (
                                            <div className={`tab-pane fade ${activeTab === document.Id ? "show active" : ""}`} id={document.Id}>
                                                <div className="mb-4 d-flex justify-content-between">
                                                    <span className="e-Signature-title">
                                                        <img src={file_icon} alt="" />
                                                        {document.Name}</span>
                                                    <div>
                                                        {
                                                            Object.keys(selectedDocumentForEsign).length === 1 && (
                                                                <div className="doc-progress">
                                                                    <svg>
                                                                        <circle cx="10" cy="10" r="8"></circle>
                                                                        <circle cx="10" cy="10" r="8"></circle>
                                                                    </svg>
                                                                    <span>{totalSignedDocuments} / 1 documents completed </span>
                                                                </div>
                                                            )
                                                        }
                                                    </div>
                                                </div>
                                                <div className="esign-category-tab-sec mb-3">
                                                    {/* add / remove class "current-tab" for acvtive tab */}
                                                    <div className={`${selectedTabOption === 1 ? "current-tab" : ""} esign-category-tab`} onClick={(e) => setSelectedTabOption(1)}>
                                                        <i className={selectedTabOptionIcon?.[activeTab]?.['1']}></i>
                                                        Set e-signature block
                                                    </div>
                                                    <div className={`${selectedTabOption === 2 ? "current-tab" : ""} esign-category-tab`} onClick={(e) => setSelectedTabOption(2)}>
                                                        <i className={selectedTabOptionIcon?.[activeTab]?.['2']}></i>
                                                        Set date block
                                                    </div>
                                                    <div className={`${selectedTabOption === 3 ? "current-tab" : ""} esign-category-tab`} onClick={(e) => setSelectedTabOption(3)}>
                                                        <i className={selectedTabOptionIcon?.[activeTab]?.['3']}></i>
                                                        Set text block
                                                    </div>
                                                </div>
                                                <div className="row">
                                                    <div className="col-md-8">
                                                        <div className="doc-preview-box mb-2" style={{ padding: "20px 102px" }}>
                                                            <div className="doc-preview"
                                                                ref={(el) => setRef(document.Id, el)}
                                                                onClick={(e) => handleMouseClick(e, document.Id)}
                                                            >
                                                                <Document
                                                                    file={document.url}
                                                                    onLoadSuccess={(props) => onDocumentLoadSuccess(props, document)}
                                                                    options={options}
                                                                >
                                                                    {Array.from(new Array(pagesPerDocument[document.Id]), (_el, index) => (
                                                                        <div
                                                                            key={`page_${index + 1}`}
                                                                            style={
                                                                                index + 1 !== pagesPerDocument[document.Id] ? {
                                                                                    marginBottom: '20px',
                                                                                    borderBottom: '1px solid #ccc',
                                                                                    paddingBottom: '20px'
                                                                                } : {}
                                                                            }
                                                                        >
                                                                            <Page pageNumber={index + 1} width={600} scale={1} />
                                                                        </div>
                                                                    ))}

                                                                </Document>
                                                                {showRectangleBlocks()}
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="col-md-4">
                                                        <div className="lm-card">
                                                            <div className="note-box d-flex">
                                                                <img src={note_icon} alt="" />
                                                                <div>
                                                                    <p>
                                                                        Click on document to set position.
                                                                    </p>
                                                                </div>
                                                            </div>
                                                            <button onClick={handleSetSignPositions} type="button" className="lm-button1 w-100 mb-3">Set Position</button>
                                                            <button onClick={() => setDeleteAllSignPosition(true)} type="button" className="lm-button2 w-100 mb-2">Clear All Position(s)</button>
                                                            <button onClick={() => setDeleteLastSignPosition(true)} type="button" className="lm-button2 w-100 mb-3">Clear Last Position</button>

                                                            {
                                                                [1, 4].includes(selectedTabOption) && (
                                                                    <>
                                                                        <div className="form-group form-check">
                                                                            <input
                                                                                type="checkbox"
                                                                                className="form-check-input"
                                                                                id="Second_Person_Signature"
                                                                                checked={secondPersionSign}
                                                                                onChange={(e) => {
                                                                                    setSelectedTabOption(4)
                                                                                    setSecondPersionSign(e.target.checked)
                                                                                    if (e.target.checked === false) {
                                                                                        setSelectedTabOption(1)
                                                                                    }
                                                                                }}
                                                                            />
                                                                            <label className="form-check-label" htmlFor="Second_Person_Signature">Second Person Signature</label>
                                                                        </div>
                                                                        <input
                                                                            type="email"
                                                                            className="form-control"
                                                                            placeholder="Second Person Email"
                                                                            disabled={secondPersionSign ? false : true}
                                                                            value={secondPersonEmail}
                                                                            onChange={(e) => setSecondPersonEmail(e.target.value)}
                                                                        />
                                                                    </>
                                                                )
                                                            }
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        )
                                    })
                                }

                            </div>
                        </div>
                        <div className="drawer-footer d-flex">
                            <button type="button" className="lm-button1 ml-auto" onClick={handleSubmitSetEsignature}>Save</button>
                        </div>
                    </Modal>
                    {/* <!--set e-Signature drawer--> */}

                    {/* delete the last sign popup */}
                    <Modal
                        className='mark-comp-popup-cust-size'
                        show={deleteLastSignPositionShow}
                        onHide={() => setDeleteLastSignPosition(false)}
                        aria-labelledby="deleteSignPositionLabel"
                        backdrop="static"
                        keyboard={false}
                        centered
                    >
                        <div className="modal-dialog modal-dialog-centered modal-dialog-scrollable">
                            <div className="modal-content">
                                <div className="modal-body signout-modal">
                                    <h3 className="pt-4">
                                        Are you sure you want to delete the last sign position?
                                    </h3>
                                </div>
                                <div className="modal-footer la-app-modal-footer">
                                    <button type="button" className="lm-button2" onClick={deleteLastSignPositionClose}>Cancel</button>
                                    <button type="button" className="lm-button1" onClick={handleRemoveLastSign}>Confirm</button>
                                </div>
                            </div>
                        </div>
                    </Modal>
                    {/* delete the last sign popup */}

                    {/* delete the last sign popup */}
                    <Modal
                        className='mark-comp-popup-cust-size'
                        show={deleteAllSignPositionShow}
                        onHide={() => setDeleteAllSignPosition(false)}
                        aria-labelledby="deleteSignPositionLabel"
                        backdrop="static"
                        keyboard={false}
                        centered
                    >
                        <div className="modal-dialog modal-dialog-centered modal-dialog-scrollable">
                            <div className="modal-content">
                                <div className="modal-body signout-modal">
                                    <h3 className="pt-4">
                                        Are you sure you want to delete the all sign position?
                                    </h3>
                                </div>
                                <div className="modal-footer la-app-modal-footer">
                                    <button type="button" className="lm-button2" onClick={deleteAllSignPositionClose}>Cancel</button>
                                    <button type="button" className="lm-button1" onClick={handleRemoveAllSign}>Confirm</button>
                                </div>
                            </div>
                        </div>
                    </Modal>
                    {/* delete the last sign popup */}


                    {/* <!--cancel e-Signature Popup / Modal --> */}
                    <Modal
                        className='mark-comp-popup-cust-size'
                        show={cancelESignaturePopupShow}
                        onHide={() => setcancelESignaturePopup(false)}
                        aria-labelledby="cancelESignaturePopupLabel"
                        backdrop="static"
                        keyboard={false}
                        centered
                    >
                        <div className="modal-dialog modal-dialog-centered modal-dialog-scrollable">
                            <div className="modal-content">
                                <div className="modal-body signout-modal">
                                    <h3 className="pt-4">
                                        Do you confirm to cancel e-Signature?
                                    </h3>
                                    <p className="text-light modal-text-info">
                                        If you cancel the e-signature you will cancel it
                                        <span className="text-red"> for the other documents </span>
                                        you set e-signature for
                                    </p>
                                </div>
                                <div className="modal-footer la-app-modal-footer">
                                    <button type="button" className="lm-button2" onClick={cancelESignaturePopupClose}>Cancel</button>
                                    <button type="button" className="lm-button1" onClick={handleConfirmCancelSendToSing}>Confirm</button>
                                </div>
                            </div>
                        </div>
                    </Modal>
                    {/* <!--cancel e-Signature Popup / Modal --> */}


                    {/* <!--mark complete Popup / Modal --> */}
                    <Modal
                        className='mark-comp-popup-cust-size'
                        show={markCompletePopupShow}
                        onHide={() => setMarkCompletePopup(false)}
                        aria-labelledby="markCompletePopupLabel"
                        backdrop="static"
                        keyboard={false}
                        centered
                    >
                        <div className="modal-dialog modal-dialog-centered modal-dialog-scrollable">
                            <div className="modal-content">
                                <div className="modal-body signout-modal">
                                    <h3 className="pt-4">
                                        Are you sure you want to mark "Business History" as completed?
                                    </h3>
                                </div>
                                <div className="modal-footer la-app-modal-footer">
                                    <button type="button" className="lm-button2" onClick={markCompletePopupClose}>Cancel</button>
                                    <button type="button" className="lm-button1" onClick={handleConfirmMarkAsCompelete}>Confirm</button>
                                </div>
                            </div>
                        </div>
                    </Modal>
                    {/* <!--mark complete Popup / Modal --> */}


                </div>
            )}
        </>
    );
}

export default Documents;
