import React, { useState, useEffect } from 'react';
import Preloader from '../plugins/Preloader';

import no_data_icon from '../assets/img/no_data_icon.svg';
import './styles/Users.css';
import { Link } from "react-router-dom";
import phone_icon_grid from '../assets/icons/phone_icon_grid.svg';
import fax_icon_grid from '../assets/icons/fax_icon_grid.svg';
import axiosInstance from '../helpers/axiosConfig';
import { useDispatch } from 'react-redux';
import reducersAndActions from '../_redux/slices';
import Constants from '../helpers/Constants';
import DynamicTable from '../plugins/DynamicTable';
import { AdminandAdviserHeader, BorrowersHeader } from '../helpers/DropdownsAndMappers';
import { getAdminAdviserList } from '../services/inquiryServices';
import { SidePanel, ProfileHeader } from "@components";
import './styles/Users.css';
import convertToIST from './user/commonFunctions/User';

function UserBorrowers() {
    // Set meta title
    useEffect(() => {
        document.title = "Borrowers";
    }, []);

    const dispatch = useDispatch();
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        dispatch(reducersAndActions.actions.sessionActions.setLoading(loading));
    }, [loading]);


    const [totalData, setTotalData] = useState(0);
    const [inquiries, setInquiries] = useState([]);
    const [pageIndex, setPageIndex] = useState(1);
    const [pageSize, setPageSize] = useState(10);
    const [searchText, setSearchText] = useState("");
    const [modalData, setModalData] = useState();
    const [transactionsPopupShow, setTransactionsPopup] = useState(false);
    const transactionsPopupClose = () => setTransactionsPopup(false);
    const [requirementsPopupShow, setRequirementsPopup] = useState(false);
    const requirementsPopupClose = () => setRequirementsPopup(false);

    // Fetch inquiries on component mount
    useEffect(() => {
        getInquiries();
    }, []);

    // Fetch inquiries whenever page size or page index changes
    useEffect(() => {
        getInquiries();
    }, [pageSize, pageIndex]);

    // Function to fetch inquiries
    const getInquiries = async () => {
        try {
            setLoading(true);
            const storedUserId = localStorage.getItem('user');
            const parsedUserId = storedUserId ? JSON.parse(storedUserId) : null;
            const storedSessionId = localStorage.getItem('sessionId');

            let queryObj = {
                PageIndex: pageIndex,
                PageSize: pageSize,
                RoleName: "Borrower",
                SessionId: storedSessionId
            };

            if (searchText) {
                queryObj["SearchText"] = searchText;
            }

            let response = await getAdminAdviserList(queryObj);
            if (response.status === 200 && response.resultObject) {
                setInquiries(response.resultObject);
                let total = response.message.split(" ");
                setTotalData(Number(total[1]));
            } else {
                dispatch(reducersAndActions.actions.toasterActions.showToaster({
                    visible: true,
                    message: response.message,
                    type: "error"
                }));
                setTimeout(() => dispatch(reducersAndActions.actions.toasterActions.hideToaster()), Constants.TOASTERHIDETIME);
                setTotalData(0);
                setInquiries([]);
            }
            setLoading(false);
        } catch (err) {
            console.log(err);
            setLoading(false);
            setTotalData(0);
        }
    }

    // Format date time string
    const formatDateTime = (dateTimeString) => {
        const date = new Date(dateTimeString);
        const options = {
            month: 'short',
            day: 'numeric',
            year: 'numeric',
            hour: 'numeric',
            minute: 'numeric',
            second: 'numeric',
            hour12: true
        };
        return date.toLocaleString('en-US', options);
    };

    // Function to render dynamic table rows
    const dynamicTableDisplay = (data) => {
        return (
            <tr className={data.statusDesc === "Active" ? "active" : "inactive"}>
                <td>
                    <Link
                        to={`/users/borrowers-detail`}
                        state={{ data }}
                        className="user_name"
                    >
                        {data.firstName} {data.lastName}
                    </Link>
                    <br />
                    <span className="user_email text-light">{data.email}</span>
                </td>
                <td>{data.companyName}</td>
                <td>

                    <div className="tel_number"><img src={phone_icon_grid} alt="" /> {data.mobile}</div>
                </td>
                <td>{data.roleName}</td>
                <td>
                    <div className="status">{data.statusDesc}</div>
                </td>
                <td className="text-light">
                    <span>
                        {(data?.lastLoginDateTime || data?.loginedUserCity || data?.loginedUserState || data?.loginedUserCountry)
                            ? (
                                <>
                                    {data?.lastLoginDateTime ? convertToIST(data.lastLoginDateTime) : null}
                                    {data?.lastLoginDateTime && (data?.loginedUserCity || data?.loginedUserState || data?.loginedUserCountry) ? <br /> : null}
                                    {[
                                        data?.loginedUserCity,
                                        data?.loginedUserState,
                                        data?.loginedUserCountry
                                    ]
                                        .filter(Boolean)
                                        .join(', ')}
                                </>
                            )
                            : 'NA'}
                    </span>
                </td>
            </tr>
        )
    };

    const handleChangeInput = async (e) => {
        setSearchText(e.target.value)
        try {
            // const storedSessionId = localStorage.getItem('sessionId');
            const storedUserId = localStorage.getItem('user');
            const parsedUserId = storedUserId ? JSON.parse(storedUserId) : null;

            const storedSessionId = localStorage.getItem('sessionId');

            // setLoading(true);
            let queryObj = {

                PageIndex: pageIndex,
                PageSize: pageSize,
                RoleName: "Borrower",
                SessionId: storedSessionId
            };

            if (searchText) {
                queryObj["SearchText"] = e.target.value;
            }
            let response = await getAdminAdviserList(queryObj);

            if (response.status == 200 && response.resultObject) {
                setInquiries(response.resultObject);
                let total = response.message.split(" ");
                setTotalData(Number(total[1]));
                setLoading(false);
            } else {
                dispatch(reducersAndActions.actions.toasterActions.showToaster({ visible: true, message: response.message, type: "error" }));
                setTimeout(() => dispatch(reducersAndActions.actions.toasterActions.hideToaster()), Constants.TOASTERHIDETIME);
                setLoading(false);
                setTotalData(0);
                setInquiries([]);
            }
        } catch (err) {
            console.log(err);
            setLoading(false);
            setTotalData(0);
        }

    }
    return (
        <>
            {/* SidePanel */}
            <SidePanel />

            {/* ProfileHeader */}
            <ProfileHeader />

            {loading ? (
                <Preloader />
            ) : (
                <div>
                    <div className="dashboard-wrap users-screen">
                        <div className="d-flex justify-content-between filter-search-group">
                            <h3 className="user-page-heading">Borrowers</h3>
                            <div className="d-flex justify-content-between search-button-group">
                                <div className="lm-search-bar">
                                    <input
                                        type="text"
                                        id="searchInput"
                                        autoComplete="off"
                                        placeholder="Search by number, name, or other"
                                        value={searchText}
                                        onChange={handleChangeInput}


                                    />
                                </div>
                            </div>
                        </div>
                        {
                            inquiries.length > 0 ? <DynamicTable
                                data={inquiries}
                                dynamicTableDisplay={dynamicTableDisplay}
                                columns={BorrowersHeader}
                                changePageSize={setPageSize}
                                pageSize={pageSize}
                                total={totalData}
                                setList={setInquiries}
                                setPageIndex={setPageIndex}
                                pageIndex={pageIndex}
                            />
                                :
                                <div className='db-card mt-4'>
                                    <div className='no-data-card text-center place-content-center'>
                                        <img src={no_data_icon} alt="" />
                                        <h3 className='f-700 mb-2'>No data found</h3>
                                        <p className='text-light'>All relevant information will be displayed
                                            <br />
                                            here in the nearest future</p>
                                    </div>
                                </div>


                        }


                    </div>
                </div>
            )}
        </>
    );
}

export default UserBorrowers;
