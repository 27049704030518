import React, { useState, useEffect, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { actions } from '../../_redux/store';
import { SidePanel, ProfileHeader } from "@components";
import Preloader from '../../plugins/Preloader';
import Constants from "../../helpers/Constants";
import { FieldGroup, FormBuilder, Validators, FieldControl } from "react-reactive-form";
import FormSelect from "../../reusablecomponets/FormSelect";
import FormRadio from "../../reusablecomponets/FormRadio";
import CreatableSelect from "../../reusablecomponets/CreatableSelect";
import { useParams } from "react-router-dom";
import { decryptText, validStringValidator } from "../../helpers/DynamicFunctions";
import reducersAndActions from "../../_redux/slices/index";
import FormTextarea from '../../reusablecomponets/FormTextArea';
import { TermsForPaymentOptions, PeakSeasonOptions, LowSeasonOptions, AvrgTermContractOptions, CurrentEmployeesOptions } from '../../screens/loan-app-tabs/personalProfile/commonFunction';
import FormField from '../../components/FormField/FormField';
import OpenAIComponent from '../../components/OpenAI/OpenAIComponent';
import WeeklyTimeComponent from '../../components/WeeklyTime/WeeklyTImeComponent.jsx';

let tempDaySchedule = {}
function IndustryContractor() {

    const dispatch = useDispatch();
    const params = useParams();
    const parsedLoanId = decryptText(params.loanId);

    const [form, setForm] = React.useState(
        FormBuilder.group({
            id: [0],
            loanId: [parsedLoanId],
            geographicMarket: ["", [Validators.required, validStringValidator]],
            suppliersVendorTerms: ["", [Validators.required, validStringValidator]],
            customersSellingTerms: ["", [Validators.required, validStringValidator]],
            keyCompetitors: ["", [Validators.required, validStringValidator]],
            competitiveAdvantages: ["", [Validators.required, validStringValidator]],
            contractorSpecialty: ["", [Validators.required, validStringValidator]],
            termsForPayments: [""],
            seasonalBusiness: [""],
            peakSeason: [""],
            lowSeason: [""],
            specialLicensing: [""],
            whatLicencing: [""],
            generalContractor: [""],
            averageTermContract: [""],
            minarityDesignation: [""],
            whatDesignations: [""],
            directNegotiation: [""],
            explainDetail: [""],
            isCompanyBonded: [""],
            bondingCompany: [""],
            maximumCoverage: [""],
            particularUnions: [""],
            whatUnions: [""],
            additionalComments: [""],
            modifiedBy: [0]
        })
    );

    const [dayTimeSchedule, setDayTimeSchedule] = useState({ monday: {}, tuesday: {}, wednesday: {}, thursday: {}, friday: {}, saturday: {}, sunday: {} });

    useEffect(() => {
        document.title = "Contractor Information - Loan Application";
        setShowErrorMessage(false);
        setSelectOptions({ geographicMarket: [], customerVendors: [], customersSellingTerms: [], peakSeason: [], lowSeason: [] });
        dispatch(actions.contractorForm.fetchContractorData({ loanId: parsedLoanId, type: 'Contractor' }));
    }, [dispatch, parsedLoanId]);

    const { data, loading, error } = useSelector((state) => state.contractorForm);


    const [selectOptions, setSelectOptions] = useState({ geographicMarket: [], customerVendors: [], customersSellingTerms: [], peakSeason: [], lowSeason: [] });
    const [showErrorMessage, setShowErrorMessage] = useState(false);

    useEffect(() => {
        try {
            if (data && data.resultObject && Object.keys(data.resultObject).length > 0) {
                const resultObject = data.resultObject
                console.log(resultObject)

                const geographicMarketOptions = resultObject.geographicMarket ? resultObject.geographicMarket.split(',').map(value => ({ label: value, value })) : [];
                const customerVendorsOptions = resultObject.customerVendors ? resultObject.customerVendors.split(',').map(value => ({ label: value, value })) : [];
                const customersSellingTermsOptions = resultObject.customersSellingTerms ? resultObject.customersSellingTerms.split(',').map(value => ({ label: value, value })) : [];
                const peakSeasonOptions = resultObject.peakSeason ? resultObject.peakSeason.split(',').map(value => ({
                    value,
                    label: PeakSeasonOptions.find(option => option.value === value)?.label || value
                })) : [];
                const lowSeasonOptions = resultObject.lowSeason ? resultObject.lowSeason.split(',').map(value => ({
                    value,
                    label: LowSeasonOptions.find(option => option.value === value)?.label || value
                })) : [];

                setSelectOptions({
                    geographicMarket: geographicMarketOptions,
                    customerVendors: customerVendorsOptions,
                    customersSellingTerms: customersSellingTermsOptions,
                    peakSeason: peakSeasonOptions,
                    lowSeason: lowSeasonOptions
                });

                form.patchValue({
                    ...resultObject,
                    loanId: resultObject.loanId || parsedLoanId,
                    seasonalBusiness: resultObject.seasonalBusiness === 'true' ? 'true' : 'false',
                    specialLicensing: resultObject.specialLicensing === 'true' ? 'true' : 'false',
                    minarityDesignation: resultObject.minarityDesignation === 'true' ? 'true' : 'false',
                    particularUnions: resultObject.particularUnions === 'true' ? 'true' : 'false',
                    isCompanyBonded: resultObject.isCompanyBonded === 'true' ? 'true' : 'false',
                    directNegotiation: resultObject.directNegotiation || 'bidding',
                    generalContractor: resultObject.generalContractor || 'Subcontractor',
                });

                const dayTimeSchedule = resultObject.lstHoursOperation.reduce((acc, item) => {
                    acc[item.daysName] = item;
                    return acc;
                }, {});

                setDayTimeSchedule((prev) => ({ ...prev, ...dayTimeSchedule }));
            } else {
                form.reset({ id: 0, loanId: parsedLoanId, modifiedBy: 0, seasonalBusiness: 'false', specialLicensing: 'false', minarityDesignation: 'false', isCompanyBonded: 'false', particularUnions: 'false', generalContractor: 'Subcontractor', directNegotiation: 'bidding' });
            }
        } catch (error) {
            console.log("error", error);
        }
    }, [data, setSelectOptions, form]);


    useEffect(() => {
        tempDaySchedule = { ...dayTimeSchedule };
    }, [dayTimeSchedule]);

    const handleSubmit = () => {
        setShowErrorMessage(true);

        if (form.status !== "INVALID") {
            const weekDays = ['monday', 'tuesday', 'wednesday', 'thursday', 'friday', 'saturday', 'sunday'];
            weekDays.forEach(day => {
                if (!tempDaySchedule.hasOwnProperty(day)) {
                    tempDaySchedule[day] = { startDate: '09:00 AM', endDate: '06:00 PM', timeHour: '12:00', status: true, daysName: day, id: 0, industryId: 0 };
                }
            })

            let finalData = { ...form.value, lstHoursOperation: Object.values(tempDaySchedule) };

            finalData['peakSeason'] = finalData['seasonalBusiness'] === 'true' ? finalData['peakSeason'] : '';
            finalData['lowSeason'] = finalData['seasonalBusiness'] === 'true' ? finalData['lowSeason'] : '';
            finalData['whatLicencing'] = finalData['specialLicensing'] === 'true' ? finalData['whatLicencing'] : '';
            finalData['whatDesignations'] = finalData['minarityDesignation'] === 'true' ? finalData['whatDesignations'] : '';
            finalData['bondingCompany'] = finalData['isCompanyBonded'] === 'true' ? finalData['bondingCompany'] : '';
            finalData['maximumCoverage'] = finalData['isCompanyBonded'] === 'true' ? finalData['maximumCoverage'] : '';
            finalData['whatUnions'] = finalData['particularUnions'] === 'true' ? finalData['whatUnions'] : '';

            dispatch(actions.contractorForm.submitForm(finalData))
                .then((response) => {
                    dispatch(reducersAndActions.actions.toasterActions.showToaster({ visible: true, message: response.payload.message, type: "success" }));
                    dispatch(actions.contractorForm.fetchContractorData({ loanId: parsedLoanId, type: 'Contractor' }));
                    setTimeout(() => dispatch(reducersAndActions.actions.toasterActions.hideToaster()), Constants.TOASTERHIDETIME);
                    setShowErrorMessage(false);
                })
                .catch((error) => {
                    dispatch(reducersAndActions.actions.toasterActions.showToaster({ visible: true, message: "Error submitting form", type: "error", }));
                    setTimeout(() => dispatch(reducersAndActions.actions.toasterActions.hideToaster()), Constants.TOASTERHIDETIME);
                });
        } else {
            markAllAsTouched(form);
            dispatch(reducersAndActions.actions.toasterActions.showToaster({ visible: true, message: "Please filled required fields", type: "error" }));
            setTimeout(() => dispatch(reducersAndActions.actions.toasterActions.hideToaster()), Constants.TOASTERHIDETIME);
        }
    };

    const handleSelectChange = (name, selectedOption) => {
        const currentSelectedValues = selectedOption || [];
        const selectedValues = currentSelectedValues.map(option => option.value);

        // Allow removal if the new selection is less than or equal to the current one
        if (currentSelectedValues.length <= 10) {
            setSelectOptions(prevOptions => ({ ...prevOptions, [name]: currentSelectedValues.filter(opt => opt.value.trim() !== "" && opt.value.length <= 50) }));
            form.controls[name].setValue(selectedValues.join(','));
        } else {
            if (selectOptions[name]?.length < currentSelectedValues.length) {
                dispatch(reducersAndActions.actions.toasterActions.showToaster({ visible: true, message: "You can't select more than 10 options", type: "error" }));
                setTimeout(() => dispatch(reducersAndActions.actions.toasterActions.hideToaster()), Constants.TOASTERHIDETIME);

                setSelectOptions(prevOptions => ({ ...prevOptions, [name]: prevOptions[name] }));
            }
        }
    };

    const markAllAsTouched = (formGroup) => {
        Object.keys(formGroup.controls).forEach((controlName) => {
            const control = formGroup.get(controlName);
            if (control && control.status === "INVALID") {
                control.markAsTouched();
                control.updateValueAndValidity();
                if (document.getElementById(controlName)) {
                    document.getElementById(controlName).focus();
                }
            }
        });
    };

    return (
        <>
            {/* SidePanel */}
            <SidePanel />

            {/* ProfileHeader */}
            <ProfileHeader />

            {loading ? (<Preloader />) : (<div>


                <div className="lm-form-box pb-5">
                    <FieldGroup
                        control={form}
                        render={({ get }) => (
                            <form>
                                <div className="lm-card">
                                    <div className="row">
                                        <div className="col-12">
                                            <h3 className="lm-card-heading">Contractor Profile</h3>
                                        </div>
                                        <div className="col-md-6">
                                            <CreatableSelect
                                                name="geographicMarket"
                                                label="Geographic markets"
                                                value={selectOptions.geographicMarket}
                                                onChange={handleSelectChange}
                                                placeholder="+ Add"
                                                isClearable
                                                required={true}
                                            />
                                            {showErrorMessage && !form.value.geographicMarket && <div className='error-message mt-2 mb-3'>Required field</div>}
                                        </div>
                                        <div className="col-md-6">
                                            <FormField
                                                id="suppliersVendorTerms"
                                                label="Major suppliers"
                                                name="suppliersVendorTerms"
                                                placeholder="Enter Text"
                                                required={true}
                                                errorMessage="Required field"
                                            />
                                        </div>
                                        <div className="col-md-6">
                                            <CreatableSelect
                                                name="customersSellingTerms"
                                                label="Major customers"
                                                value={selectOptions.customersSellingTerms}
                                                onChange={handleSelectChange}
                                                placeholder="+ Add"
                                                isClearable
                                                required={true}
                                            />
                                            {showErrorMessage && !form.value.customersSellingTerms && <div className='error-message mt-2 mb-3'>Required field</div>}
                                        </div>
                                        <div className="col-md-6">
                                            <div className="form-group AI_btn_field">
                                                <FormField
                                                    id="keyCompetitors"
                                                    label="Key competitors"
                                                    name="keyCompetitors"
                                                    placeholder="Enter Text"
                                                    required={true}
                                                    errorMessage="Required field"
                                                />
                                                <OpenAIComponent
                                                    fieldName="keyCompetitors"
                                                    formGroup={form}
                                                />
                                            </div>
                                        </div>
                                        <div className="col-md-6">
                                            <div className="form-group AI_btn_field">
                                                <FormTextarea
                                                    id="competitiveAdvantages"
                                                    label="Competitive advantage"
                                                    name="competitiveAdvantages"
                                                    placeholder="Enter Text"
                                                    required={true}
                                                    errorMessage="Required field"
                                                />
                                                <OpenAIComponent
                                                    fieldName="competitiveAdvantages"
                                                    formGroup={form}
                                                    type="textarea"
                                                />
                                            </div>
                                        </div>
                                        <div className="col-md-6">
                                            <div className="form-group AI_btn_field">
                                                <FormTextarea
                                                    id="contractorSpecialty"
                                                    label="What is contractor’s specialty?"
                                                    name="contractorSpecialty"
                                                    placeholder="Enter Text"
                                                    required={true}
                                                    errorMessage="Required field"
                                                />
                                                <OpenAIComponent
                                                    fieldName="contractorSpecialty"
                                                    formGroup={form}
                                                    type="textarea"
                                                />
                                            </div>
                                        </div>

                                        <div className='col-12'>
                                            <h3 className="lm-card-heading mt-2">Hours of operation</h3>
                                            <div className='row'>
                                                <WeeklyTimeComponent
                                                    weekday="monday"
                                                    setDayTimeSchedule={setDayTimeSchedule}
                                                    value={dayTimeSchedule}
                                                    industryType="Contractor"
                                                />
                                                <WeeklyTimeComponent
                                                    weekday="tuesday"
                                                    setDayTimeSchedule={setDayTimeSchedule}
                                                    value={dayTimeSchedule}
                                                    industryType="Contractor"
                                                />
                                                <WeeklyTimeComponent
                                                    weekday="wednesday"
                                                    setDayTimeSchedule={setDayTimeSchedule}
                                                    value={dayTimeSchedule}
                                                    industryType="Contractor"
                                                />
                                                <WeeklyTimeComponent
                                                    weekday="thursday"
                                                    setDayTimeSchedule={setDayTimeSchedule}
                                                    value={dayTimeSchedule}
                                                    industryType="Contractor"
                                                />
                                                <WeeklyTimeComponent
                                                    weekday="friday"
                                                    setDayTimeSchedule={setDayTimeSchedule}
                                                    value={dayTimeSchedule}
                                                    industryType="Contractor"
                                                />
                                                <WeeklyTimeComponent
                                                    weekday="saturday"
                                                    setDayTimeSchedule={setDayTimeSchedule}
                                                    value={dayTimeSchedule}
                                                    industryType="Contractor"
                                                />
                                                <WeeklyTimeComponent
                                                    weekday="sunday"
                                                    setDayTimeSchedule={setDayTimeSchedule}
                                                    value={dayTimeSchedule}
                                                    industryType="Contractor"
                                                />
                                            </div>
                                        </div>
                                        <div className="col-md-4 col-lg-3">
                                            <FormSelect
                                                name="averageTermContract"
                                                label="What is an average term of contract?"
                                                options={AvrgTermContractOptions}
                                                defaultValue={AvrgTermContractOptions[0]}
                                            />
                                        </div>
                                        <div className="col-md-4 col-lg-3">
                                            <FormSelect
                                                name="termsForPayments"
                                                label="What are the payment terms?"
                                                options={TermsForPaymentOptions}
                                                defaultValue={TermsForPaymentOptions[0]}
                                            />
                                        </div>

                                        {/* <!--  lm common divider to break the section  --> */}
                                        <div className="lm-divider my-4 mx-3"></div>
                                        {/* <!--  lm common divider to break the section  --> */}

                                        <div className="col-md-6">
                                            <FormRadio
                                                name="seasonalBusiness"
                                                label="Is this a seasonal business?"
                                                options={[
                                                    { label: 'Yes', value: 'true' },
                                                    { label: 'No', value: 'false' },
                                                ]}
                                            />

                                        </div>

                                        {form.value.seasonalBusiness == "true" && <> <div className="col-12">
                                            <div className="row">
                                                <div className="col-md-6">
                                                    <CreatableSelect
                                                        name="peakSeason"
                                                        label="Peak season"
                                                        value={selectOptions.peakSeason}
                                                        onChange={handleSelectChange}
                                                        placeholder="Select..."
                                                        options={PeakSeasonOptions}
                                                        isMulti
                                                        isClearable
                                                    />
                                                </div>
                                                <div className="col-md-6">
                                                    <CreatableSelect
                                                        name="lowSeason"
                                                        label="Low season"
                                                        value={selectOptions.lowSeason}
                                                        onChange={handleSelectChange}
                                                        placeholder="Select..."
                                                        options={LowSeasonOptions}
                                                        isMulti
                                                        isClearable
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                        </>
                                        }
                                        {/* <!--  lm common divider to break the section  --> */}
                                        <div className="lm-divider my-4 mx-3"></div>
                                        {/* <!--  lm common divider to break the section  --> */}

                                        <div className="col-md-12">
                                            <FormRadio
                                                name="specialLicensing"
                                                label="Is any special licensing required?"
                                                options={[
                                                    { label: 'Yes', value: 'true' },
                                                    { label: 'No', value: 'false' },
                                                ]}
                                            />
                                        </div>

                                        {
                                            form.value.specialLicensing == "true" && (
                                                <div className="col-md-6">
                                                    <FormField
                                                        id="whatLicencing"
                                                        label="Provide details"
                                                        name="whatLicencing"
                                                        placeholder="Enter Text"
                                                    />
                                                </div>
                                            )

                                        }

                                        {/* <!--  lm common divider to break the section  --> */}
                                        <div className="lm-divider my-4 mx-3"></div>
                                        {/* <!--  lm common divider to break the section  --> */}

                                        <div className="col-md-12">
                                            <div className="form-group mb-1">
                                                <div className="label pb-1">Typically operate as:</div>
                                                <div className="form-check form-check-inline">
                                                    <input
                                                        className="form-check-input"
                                                        type="radio"
                                                        name="generalContractor"
                                                        id="general_contractor"
                                                        value="General contractor"
                                                        checked={form.get('generalContractor').value === 'General contractor'}
                                                        onChange={(e) => form.patchValue({ generalContractor: e.target.value })}
                                                    />
                                                    <label className="form-check-label" htmlFor="general_contractor">General contractor</label>
                                                </div>
                                                <div className="form-check form-check-inline">
                                                    <input
                                                        className="form-check-input"
                                                        type="radio"
                                                        name="generalContractor"
                                                        id="subcontractor"
                                                        value="Subcontractor"
                                                        checked={form.get('generalContractor').value === 'Subcontractor'}
                                                        onChange={(e) => form.patchValue({ generalContractor: e.target.value })}
                                                    />
                                                    <label className="form-check-label" htmlFor="subcontractor">Subcontractor</label>
                                                </div>
                                            </div>

                                        </div>

                                        {/* <!--  lm common divider to break the section  --> */}
                                        <div className="lm-divider my-4 mx-3"></div>
                                        {/* <!--  lm common divider to break the section  --> */}

                                        <div className="col-md-12">
                                            <FormRadio
                                                name="minarityDesignation"
                                                label="Does the company have minority designation or preferred status?"
                                                options={[
                                                    { label: 'Yes', value: 'true' },
                                                    { label: 'No', value: 'false' },
                                                ]}
                                                required={true}
                                                errorMessage="This field is required."
                                            />
                                        </div>

                                        {
                                            form.value.minarityDesignation == "true" && (
                                                <div className="col-md-6">
                                                    <FormField
                                                        id="whatDesignations"
                                                        label="Provide details"
                                                        name="whatDesignations"
                                                        placeholder="Enter Text"
                                                    />
                                                </div>
                                            )
                                        }


                                        {/* <!--  lm common divider to break the section  --> */}
                                        <div className="lm-divider my-4 mx-3"></div>
                                        {/* <!--  lm common divider to break the section  --> */}

                                        <div className="col-md-12">
                                            <FormRadio
                                                name="directNegotiation"
                                                label="Jobs are awarded via:"
                                                options={[
                                                    { label: 'Bidding', value: 'bidding' },
                                                    { label: 'Direct negotiation', value: 'Direct_negotiation' },
                                                    { label: 'Both', value: 'Both' },
                                                ]}
                                            />
                                        </div>

                                        <div className="col-md-6">
                                            <FormField
                                                id="explainDetail"
                                                label="Provide details"
                                                name="explainDetail"
                                                placeholder="Enter Text"
                                            />
                                        </div>

                                        {/* <!--  lm common divider to break the section  --> */}
                                        <div className="lm-divider my-4 mx-3"></div>
                                        {/* <!--  lm common divider to break the section  --> */}

                                        <div className="col-md-12">
                                            <FormRadio
                                                name="isCompanyBonded"
                                                label="Is the company bonded?"
                                                options={[
                                                    { label: 'Yes', value: 'true' },
                                                    { label: 'No', value: 'false' },
                                                ]}
                                            />
                                        </div>
                                        {
                                            form.value.isCompanyBonded == "true" &&
                                            <>
                                                <div className="col-md-6">
                                                    <FormField
                                                        id="bondingCompany"
                                                        label="Who is the bonding company?"
                                                        name="bondingCompany"
                                                        placeholder="Enter Text"
                                                    />
                                                </div>

                                                <div className="col-md-6">
                                                    <FormField
                                                        id="maximumCoverage"
                                                        label="What is the maximum coverage?"
                                                        name="maximumCoverage"
                                                        placeholder="Enter Text"
                                                    />
                                                </div>
                                            </>
                                        }

                                        {/* <!--  lm common divider to break the section  --> */}
                                        <div className="lm-divider my-4 mx-3"></div>
                                        {/* <!--  lm common divider to break the section  --> */}

                                        <div className="col-md-12">
                                            <FormRadio
                                                name="particularUnions"
                                                label="Are employees affiliated with any particular unions?"
                                                options={[
                                                    { label: 'Yes', value: 'true' },
                                                    { label: 'No', value: 'false' },
                                                ]}
                                            />
                                        </div>
                                        {form.value.particularUnions == "true" &&
                                            <div className="col-md-6">
                                                <FormField
                                                    id="whatUnions"
                                                    label="Affiliation"
                                                    name="whatUnions"
                                                    placeholder="Enter Text"
                                                />
                                            </div>}

                                        {/* <!--  lm common divider to break the section  --> */}
                                        <div className="lm-divider my-4 mx-3"></div>
                                        {/* <!--  lm common divider to break the section  --> */}

                                        <div className="col-md-6">
                                            <div className="form-group AI_btn_field">
                                                <FormTextarea
                                                    id="additionalComments"
                                                    label="Additional comments"
                                                    name="additionalComments"
                                                    placeholder="Enter Text"
                                                    maxlength={250}
                                                />
                                                <OpenAIComponent
                                                    fieldName="additionalComments"
                                                    formGroup={form}
                                                    type="textarea"
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="sticky-btn-footer d-flex justify-content-end">
                                    <button type="button" className="lm-button1 ml-3" onClick={handleSubmit}>Save</button>
                                </div>
                            </form>
                        )}
                    />
                </div>
            </div>)}

        </>
    )
}

export default IndustryContractor;