import axios from '../helpers/axiosConfig';

export const GetBussinessProfileByLoanId = async (data) => {
    try {
        const response = await axios.get(`/api/LoanApplication/GetBussinessProfileByLoanId`, { params: data });
        if (response.data) {
            return response.data;
        }
    } catch (err) {
        console.log(err);
        throw err;
    }
}

export const GetOwnersListofPersonalResumeById = async (data) => {
    try {
        const response = await axios.get(`/api/LoanApplication/GetOwnersListofPersonalResumeById`, { params: data });
        if (response.data) {
            return response.data;
        }
    } catch (err) {
        console.log(err);
        throw err;
    }
}
// import Constants from "../helpers/Constants";
// WebURL: [Constants.FRONTEND_URL]
// const response = await axiosInstance.post("/api/User/InsertUpdateAdminUserInfo", formDataToSend, {
//     params: {
//         WebURL: "http://localhost:3000/",
//         SessionId: storedSessionId
//     }
export const InsertUpdateBusinessProfile = async (data) => {
    try {
        const response = await axios.get(`/api/LoanApplication/InsertUpdateBusinessProfile`, { params: data });
        if (response.data) {
            return response.data;
        }
    } catch (err) {
        console.log(err);
        throw err;
    }
}
