import axios from "../helpers/axiosConfig";

// Get Logs listing
export const GetLMLogList = async (data) => {
    try {
        const response = await axios.get(`/api/Log/GetLMLogList`, { params: data });
        if (response.data) {
            return response.data;
        }
    } catch (err) {
        console.log(err);
        throw err;
    }
}

export const GetUserList = async (data) => {
    try {
        const response = await axios.get(`/api/User/GetUserList`, { params: data });
        if (response.data) {
            return response.data;
        }
    } catch (err) {
        console.log(err);
        throw err;
    }
}