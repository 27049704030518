import React, { useState, useEffect } from 'react';
import { Modal } from 'react-bootstrap';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import edit_pen from '../../../../assets/icons/edit-pen.svg';
import close_popup from '../../../../assets/icons/close_popup.svg';
import moment from 'moment';

const WorkExperience = ({ onUpdate, neweducationData }) => {
    const [workExperiencePopupShow, setWorkExperiencePopupShow] = useState(false);
    const [experienceStartDate, setExperienceStartDate] = useState(null);
    const [experienceEndDate, setExperienceEndDate] = useState(null);
    const [workExperiences, setWorkExperiences] = useState([]);
    const [type, setType] = useState('Add');

    const [formData, setFormData] = useState({
        ownerId: 0,
        institutionName: '',
        startDate: null,
        endDate: null,
        location: '',
        major: '',
        position: '',
        degreeCertificate: '',
        isDeleted: 1
    });
    const [editIndex, setEditIndex] = useState(null);


    useEffect(() => {
        setWorkExperiences(neweducationData);
    }, [neweducationData]);


    const handleInputChange = (e) => {
        const { id, value } = e.target;
        setFormData({ ...formData, [id]: value });
    };

    const handleDateChange = (dates) => {
        const [start, end] = dates;
        setExperienceStartDate(start);
        setExperienceEndDate(end);
        setFormData({ ...formData, startDate: start, endDate: end });
    };

    const handleSubmit = () => {
        setWorkExperiencePopupShow(false);
        if (formData.degreeCertificate.length > 0 || formData.institutionName.length > 0 || formData.location.length > 0 || formData.major.length > 0 || formData.position.length > 0 || (formData.startDate !== null && formData.endDate !== null)) {
            const updatedExperiences = [...workExperiences];
            if (editIndex !== null) {
                updatedExperiences[editIndex] = formData;
            } else {
                updatedExperiences.push(formData);
            }
            setWorkExperiences(updatedExperiences);
            onUpdate(updatedExperiences);
            setWorkExperiencePopupShow(false);
            setFormData({
                ownerId: 0,
                institutionName: '',
                startDate: null,
                endDate: null,
                location: '',
                major: '',
                position: '',
                degreeCertificate: '',
                isDeleted: 1
            });
            setEditIndex(null);
            setExperienceStartDate(null);
            setExperienceEndDate(null);
        }
    };

    const handleEdit = (index) => {
        const experience = workExperiences[index];
        setFormData(experience);
        setExperienceStartDate(experience.startDate);
        setExperienceEndDate(experience.endDate);
        setEditIndex(index);
        setType('Edit');
        setWorkExperiencePopupShow(true);
    };

    const handleRemove = () => {
        let indexData = { ...workExperiences[editIndex], isDeleted: 1 };
        const removedData = workExperiences.filter((item, index) => index !== editIndex);
        const updatedExperiences = [...removedData, indexData];
        setWorkExperiences(updatedExperiences);
        onUpdate(updatedExperiences);
        setWorkExperiencePopupShow(false);
        setFormData({
            ownerId: 0,
            institutionName: '',
            startDate: null,
            endDate: null,
            location: '',
            major: '',
            position: '',
            degreeCertificate: '',
            isDeleted: 0
        });
        setEditIndex(null);
        setExperienceStartDate(null);
        setExperienceEndDate(null);
    };

    const addEducationHandler = () => {
        setExperienceStartDate(null);
        setExperienceEndDate(null);
        setType('Add');
        setWorkExperiencePopupShow(true);
        setEditIndex(null);
        setFormData({
            ownerId: 0,
            institutionName: '',
            startDate: null,
            endDate: null,
            location: '',
            major: '',
            position: '',
            degreeCertificate: '',
            isDeleted: 0
        });
    };

    const showPeriodDate = (formData) => {
        const [startDate, endDate] = [formData.startDate, formData.endDate];
        if (!startDate) return '';
        if (endDate) {
            return `${new Date(startDate).toLocaleDateString('en-US', { year: 'numeric', month: 'short' })} - ${new Date(endDate).toLocaleDateString('en-US', { year: 'numeric', month: 'short' })}`;
        } else {
            return `${new Date(startDate).toLocaleDateString('en-US', { year: 'numeric', month: 'short' })} - Present`;
        }
    }
    return (
        <div className="col-12 mt-3">
            <div className="lm-card p-0">
                <div className="d-flex justify-content-between p-3">
                    <h3 className="lm-card-heading mb-0">Education</h3>
                    <a className="lm-button3" onClick={addEducationHandler}><span>+</span> Add education</a>
                </div>
                <table className="lm-inner-form-grid">
                    <thead>
                        <tr>
                            <th>Institution Name</th>
                            <th>Period</th>
                            <th>Location</th>
                            <th>Major</th>
                            <th>Degree or Certificate</th>
                            <th>Action</th>
                        </tr>
                    </thead>
                    <tbody>
                        {workExperiences.map((experience, index) => {
                            if (experience.isDeleted === 1) return null;
                            return (
                                <tr key={index}>
                                    <td><b>{experience.institutionName}</b></td>
                                    <td>{showPeriodDate(experience)}</td>
                                    <td>{experience.location}</td>
                                    <td>{experience.major}</td>
                                    <td>{experience.degreeCertificate}</td>
                                    <td>
                                        <img title="Edit" className="lm-grid-edit-icon" onClick={() => handleEdit(index)} src={edit_pen} alt="Edit" />
                                    </td>
                                </tr>
                            );
                        })}
                    </tbody>
                </table>
            </div>

            {/* This is customized modal used for datepicker isuue purpose don't use this anywhere else */}
            <div className={`custom-modal cust-modal-md modal-md-education modal-open modal-backdrop ${workExperiencePopupShow ? "cust-modal-show" : "cust-modal-hide"}`}>
                <div className="modal-box">
                    <div className="modal-header">
                        <h5 className="modal-title" id="workExperiencePopupLabel">{type} Education</h5>
                        <button type="button" className="close" onClick={() => setWorkExperiencePopupShow(false)}>
                            <span aria-hidden="true"><img src={close_popup} alt="Close" /></span>
                        </button>
                    </div>
                    <form>
                        <Modal.Body>
                            <div className="row">
                                <div className="col-md-12">
                                    <div className="form-group">
                                        <label htmlFor="institutionName">Institution Name</label>
                                        <input type="text" className="form-control"
                                            maxLength={100}
                                            id="institutionName" value={formData.institutionName} onChange={handleInputChange} placeholder="Enter a Institution Name" />
                                    </div>
                                </div>
                                <div className="col-md-12">
                                    <div className="form-group input-date two-month-datepicker">
                                        <label htmlFor="startingEndDates">Starting/End Dates</label>
                                        <DatePicker
                                            showIcon
                                            toggleCalendarOnIconClick
                                            selected={experienceStartDate}
                                            selectsRange={true}
                                            startDate={experienceStartDate}
                                            endDate={experienceEndDate}
                                            onChange={handleDateChange}
                                            monthsShown={2}
                                            isClearable={true}
                                            dateFormat="MMM d, yyyy"
                                            placeholderText="Month DD, YYYY - Month DD, YYYY"
                                            yearDropdownItemNumber={100}
                                            scrollableYearDropdown
                                            showMonthDropdown
                                            showYearDropdown
                                            onKeyDown={(e) => {
                                                e.preventDefault();
                                            }}
                                            onBlur={(e) => {
                                                if (!experienceEndDate || !experienceStartDate) {
                                                    setExperienceStartDate(null);
                                                    setExperienceEndDate(null);
                                                    setFormData({ ...formData, startDate: null, endDate: null });
                                                }
                                            }}
                                        />
                                    </div>
                                </div>

                                <div className="col-md-12">
                                    <div className="form-group">
                                        <label htmlFor="location">Location</label>
                                        <input type="text" maxLength={100} className="form-control" id="location" value={formData.location} onChange={handleInputChange} placeholder="Enter your Location" />
                                    </div>
                                </div>


                                <div className="col-md-12">
                                    <div className="form-group">
                                        <label htmlFor="major">Major</label>
                                        <input type="text" maxLength={100} className="form-control" id="major" value={formData.major} onChange={handleInputChange} placeholder="Enter your Major" />
                                    </div>
                                </div>
                                <div className="col-md-12">
                                    <div className="form-group">
                                        <label htmlFor="title">Degree or certificate</label>
                                        <input type="text"
                                            maxLength={100} className="form-control" id="degreeCertificate" value={formData.degreeCertificate} onChange={handleInputChange} placeholder="Enter a Degree Certificate" />
                                    </div>
                                </div>
                            </div>
                        </Modal.Body>
                        <div className="modal-footer la-app-modal-footer">
                            {editIndex !== null ? (
                                <button onClick={handleRemove} type="button" className="lm-button2 text-red">Remove</button>
                            ) : <button type="button" className="lm-button2" onClick={() => setWorkExperiencePopupShow(false)}>Cancel</button>}
                            <button onClick={handleSubmit} type="button" className="lm-button1">Save</button>

                        </div>
                    </form>
                </div>
            </div>
        </div>
    );
};

export default WorkExperience;
