import React, { useEffect, useState, useContext } from 'react';
import { Header, Footer } from "@components";
import './styles/LoginScreen.css';
import './styles/WebsiteStyle.css';
import Modal from 'react-bootstrap/Modal'
import { Link, useNavigate } from "react-router-dom";
import { FieldGroup, FormBuilder, Validators, FieldControl } from "react-reactive-form";
import reducersAndActions from '../_redux/slices/index';
import pass_eye from '../assets/img/pass_eye.svg';
import login_error_icon from '../assets/icons/login-error-icon.svg';
import ReCAPTCHA from 'react-google-recaptcha';
import { ChangeLockStatus, generateUniqueId, LockAccount, login, logout, ReSendVerificationCodeEmail } from '../services/commonService';
import { autoSetSessionId, getSessionId, removeLocalStorageData } from '../services/storageData';
import { useDispatch, useSelector } from 'react-redux';
import Constants from '../helpers/Constants';
import Preloader from '../plugins/Preloader';
import { encryptText } from '../helpers/DynamicFunctions';

const loginForm = FormBuilder.group({
  email: ["", [Validators.required, Validators.pattern('^[a-zA-Z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$')]],
  password: ["", Validators.required]
});

function LoginScreen() {
  // Meta Title
  useEffect(() => {
    document.title = "Log In - Loan Mantra";
    console.log("Here, new code added")
    window.scrollTo(0, 0);
  }, []);

  // Defining Variables --------------------------------
  const [showActiveSession, setActiveSession] = useState(false)
  const [activeEmail, setActiveEmail] = useState("");
  const [loading, setLoading] = useState(false);


  const navigate = useNavigate();
  const dispatch = useDispatch();
  const user = useSelector((state) => state.auth);
  const errorbox = useSelector((state) => state.errorbox);
  let showPassword = false;
  let isVerified = false;

  const recaptchaRef = React.createRef();

  const sitekey = Constants.ReCAPTCHAKEY;

  // MAS Popup
  const [MASPopupShow, setMASPopup] = useState(true);
  const MASPopupClose = () => setMASPopup(false);

  // Defining Hooks --------------------------------
  useEffect(() => {
    if (user.isLoggedIn) {
      navigate("/loan-application");
    }
  }, []);

  // Unmounting the component below
  useEffect(() => {
    return () => {
      loginForm.reset();
    }
  }, []);

  // Defining Functions --------------------------------

  const togglePassword = () => {
    showPassword = !showPassword;
    document.getElementById("password").focus();
  }

  // Dynamic TextField for Email and Password
  const TextInput = ({ handler, touched, hasError, meta }) => (
    <div className={meta.type == "password" ? ((touched && (hasError("required") || hasError("pattern"))) ? "form-group input-password error-field" : "form-group input-password") : ((touched && (hasError("required") || hasError("pattern"))) ? "form-group error-field" : "form-group")}>
      {/* Label below */}
      <label htmlFor={meta.type}>{meta.label}</label>

      {/* Input below */}
      <input type={meta.type == "password" ? (!showPassword ? meta.type : "text") : meta.type} maxLength={meta.maxLength} className="form-control password-input mb-1" id={meta.type} placeholder={meta.placeholder} {...handler()} />
      {meta.type == "password" && <img className={`pass_eye ${showPassword ? 'active_pass' : ''}`} id="pass_eye" src={pass_eye} alt="" onClick={() => togglePassword()} />}

      {/* Error messages below */}
      <span className="error-message">{touched && hasError("required") && `${meta.label} is required`}</span>
      <span className="error-message">{touched && hasError("pattern") && `${meta.label} is incorrect`}</span>
    </div>
  )

  // Form Submit function
  const [failedAttempts, setFailedAttempts] = useState(0);

  const [failedAttemptsCount, setFailedAttemptsCount] = useState(0);

  const handleError = () => {
    dispatch(reducersAndActions.actions.errorActions.showError({ visible: true, message: "Please verify the CAPTCHA." }));
    setTimeout(() => dispatch(reducersAndActions.actions.errorActions.hideError()), Constants.TOASTERHIDETIME);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const recaptchaValue = recaptchaRef.current.getValue();
    if (Constants.isCheckCaptcha && !recaptchaValue) {
      handleError();
      return;
    }

    autoSetSessionId(generateUniqueId());

    if (loginForm.status !== "INVALID") {
      setLoading(true);
      const userData = {
        EmailAddress: loginForm.value.email,
        Password: loginForm.value.password,
        SessionId: getSessionId(),
      };

      login(userData)
        .then((data) => {
          if (data.message == "Two Factor Authentication Login") {
            setLoading(true)
            ReSendVerificationCodeEmail(data.resultObject)
              .then((res) => {
                dispatch(reducersAndActions.actions.errorActions.showError({ visible: true, message: res.message }));
                setTimeout(() => dispatch(reducersAndActions.actions.errorActions.hideError()), Constants.TOASTERHIDETIME);
                setLoading(false)
              })

            return navigate("/email-verification", { state: data.resultObject });
          } else if (data.resultObject && data.status !== 404 && data.message !== "Two Factor Authentication Login") {
            loginForm.reset();
            setLoading(false);
            dispatch(reducersAndActions.actions.authActions.saveUser(data.resultObject));
            setTimeout(() => dispatch(reducersAndActions.actions.toasterActions.hideToaster()), Constants.TOASTERHIDETIME);
            dispatch(reducersAndActions.actions.sessionActions.startSessionTimeout());
            navigate("/loan-application");
            setFailedAttempts(0); // Reset failed attempts on successful login
          } else {
            setFailedAttemptsCount(failedAttemptsCount + 1)
            handleFailedAttempt(data.message);
            setLoading(false);
          }
        })
        .catch((err) => {
          if (err.response.data.message === "New Paasword link" && err.response.data.status === 404) {
            navigate(`/ResetPasswordLink?data=${encryptText(loginForm.value.email)}`);
          } else if (err.response?.status === 401 && err.response.data.message === "User already logged") {
            setActiveSession(true);
            setLoading(false);
          } else if (err.response && (err.response.status === 401 || err.response.status === 404)) {
            handleFailedAttempt(err.response.data.message);
            removeLocalStorageData("token");
            setLoading(false);
          } else {
            dispatch(reducersAndActions.actions.errorActions.showError({ visible: true, message: "two attempt left" }));
            setTimeout(() => dispatch(reducersAndActions.actions.errorActions.hideError()), Constants.TOASTERHIDETIME);
            setLoading(false);
          }
        });
    } else {
      markAllAsTouched(loginForm);
      setLoading(false);
    }
  };

  let emailAttempts = [];
  let failedAttemptsmail = [];
  if (failedAttemptsCount == 1) {
    dispatch(reducersAndActions.actions.errorActions.showError({ visible: true, message: "two attempt left" }));
    setTimeout(() => dispatch(reducersAndActions.actions.errorActions.hideError()), Constants.TOASTERHIDETIME);

  }
  const handleFailedAttempt = (message) => {
    const currentEmail = loginForm.value.email;
    const emailIndex = emailAttempts.indexOf(currentEmail);

    if (emailIndex !== -1) {
      failedAttemptsmail[emailIndex] += 1;
      if (failedAttemptsmail[emailIndex] >= 3) {
        changeLockStatus(currentEmail);
      }
    } else {
      emailAttempts.push(currentEmail);
      failedAttemptsmail.push(1);
    }
    setFailedAttempts((prev) => prev + 1);
    dispatch(reducersAndActions.actions.errorActions.showError({ visible: true, message: failedAttemptsmail[emailIndex] == undefined ? `Email address or password is incorrect. "2" attempt(s) left.` : failedAttemptsmail[emailIndex] == 2 ? `Email address or password is incorrect. "1" attempt(s) left.` : failedAttemptsmail[emailIndex] >= 3 ? `Your account is temporarily locked. Please try back in 30 minutes. In a rush, please call a Loan Mantra advisor for help.` : message }));
    // setTimeout(() => dispatch(reducersAndActions.actions.errorActions.hideError()), Constants.TOASTERHIDETIME);

    if (failedAttempts + 1 >= 3) {
      changeLockStatus();
    }
  };

  const changeLockStatus = () => {

    const lockStatusData = {
      EmailAddress: loginForm.value.email,
      SessionId: getSessionId(),
    };
    ChangeLockStatus(lockStatusData)
      .then(response => { })
      .catch(error => {
        console.error("Error changing lock status:", error);
      });


  };


  // For starting a new session from this browser
  const handleSession = async (e) => {
    try {
      e.preventDefault();
      setActiveSession(false);
      setLoading(true);
      const signout = await logout(loginForm.value.email);

      if (signout.status == 200) {
        autoSetSessionId(generateUniqueId());
        const userData = {
          EmailAddress: loginForm.value.email,
          Password: loginForm.value.password,
          SessionId: getSessionId(),
        };
        const loginData = await login(userData);

        if (loginData.message === "Two Factor Authentication Login") {
          setLoading(true);
          ReSendVerificationCodeEmail(loginData.resultObject)
            .then((res) => {
              dispatch(reducersAndActions.actions.errorActions.showError({ visible: true, message: res.message }));
              setTimeout(() => dispatch(reducersAndActions.actions.errorActions.hideError(), Constants.TOASTERHIDETIME));
              setLoading(false);
            });
          return navigate("/email-verification", { state: loginData.resultObject });
        } else if (loginData.resultObject && loginData.status !== 404) {
          loginForm.reset();
          setLoading(false);
          dispatch(reducersAndActions.actions.authActions.saveUser(loginData.resultObject));
          navigate("/loan-application");
        } else {
          if ((loginData.message == "New Paasword link" || loginData.message == "New Password link") && loginData.status == 404) {
            navigate(`/ResetPasswordLink?email=${loginData.resultObject.email}`);
          } else {
            dispatch(reducersAndActions.actions.errorActions.showError({ visible: true, message: loginData.message }));
            setTimeout(() => dispatch(reducersAndActions.actions.errorActions.hideError()), Constants.TOASTERHIDETIME);
          }
          setLoading(false);
        }
      } else {
        setLoading(false);
      }
    } catch (err) {
      console.log(err);
      dispatch(reducersAndActions.actions.errorActions.showError({ visible: true, message: err.message }));
      setTimeout(() => dispatch(reducersAndActions.actions.errorActions.hideError()), Constants.TOASTERHIDETIME);
      setLoading(false);
    }
  }


  // Marking all input fields as touched if the form is not valid
  const markAllAsTouched = (formGroup) => {
    Object.keys(formGroup.controls).forEach(controlName => {
      const control = formGroup.get(controlName);
      control.active = true;
      control.touched = true;
      document.getElementById(controlName).focus();
    });
  }

  return (
    <>
      {/* Header */}
      <Header />

      {/* Loader */}
      {loading ? <Preloader /> : null}

      {/* Main Body */}
      <div className="website-wrap">
        <section className="login-body">
          <div className="container">
            <h2 className="heading-1">Log In</h2>
            <p className='mb-5 mt-3'>Democratized lending for all</p>
            {/* validation-box start*/}
            {
              errorbox.visible && <div className="login-validation-box">
                <img className="mr-2" src={login_error_icon} alt="" />
                {errorbox.message}
              </div>
            }
            {/* validation-box end*/}
            <div className="login-box">
              {/* Login Form */}
              <FieldGroup
                control={loginForm}
                render={({ get, invalid }) => (
                  <form onSubmit={handleSubmit}>
                    <FieldControl
                      name="email"
                      render={TextInput}
                      meta={{ type: "email", placeholder: "Enter your email", label: "Email address", maxLength: "100" }}
                    />
                    <FieldControl
                      name="password"
                      render={TextInput}
                      meta={{ type: "password", placeholder: "Enter a password", label: "Password", maxLength: "100" }}
                    />
                    <div>
                      <button type="submit" onClick={handleSubmit} className="btn btn-login"> Log In </button>
                    </div>
                    <div className="captcha-box w-100">
                      <ReCAPTCHA
                        ref={recaptchaRef}
                        sitekey={sitekey}
                      />
                    </div>
                  </form>
                )}
              />

              <div className="text-center mt-4">
                <Link className="link-secondary" to="/forgot-password">Forgot password?</Link>
              </div>
            </div>
          </div>
        </section>

        {/* Active Sesion PopUp */}
        {
          showActiveSession &&
          <Modal
            className='mas-popup'
            show={MASPopupShow}
            onHide={() => setMASPopup(false)}
            aria-labelledby="MASPopupLabel"
            backdrop="static"
            keyboard={false}
            centered
          >
            {/* <img className="mb-3 mas-icon" src={mas_icon} alt="" /> */}
            {/* adding svg code to avoid loading issue */}
            <svg className="mb-3" width="56" height="56" viewBox="0 0 56 56" fill="none" xmlns="http://www.w3.org/2000/svg">
              <circle cx="28" cy="28" r="28" fill="#F0F2FF" />
              <g clipPath="url(#clip0_2920_9759)">
                <path d="M28 32.6V28.1M28.45 25.4C28.45 25.6485 28.2485 25.85 28 25.85C27.7515 25.85 27.55 25.6485 27.55 25.4M28.45 25.4C28.45 25.1515 28.2485 24.95 28 24.95C27.7515 24.95 27.55 25.1515 27.55 25.4M28.45 25.4H27.55M37 29C37 33.9706 32.9706 38 28 38C23.0294 38 19 33.9706 19 29C19 24.0294 23.0294 20 28 20C32.9706 20 37 24.0294 37 29Z" stroke="#22315D" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
              </g>
              <defs>
                <clipPath id="clip0_2920_9759">
                  <rect width="20" height="20" fill="white" transform="translate(18 19)" />
                </clipPath>
              </defs>
            </svg>
            <h3>Multiple Active Sessions</h3>
            <p>It seems that you have an active login session. Do you want to close out the prior session?</p>
            <div className="d-flex">

            </div>
            <div className="modal-footer">
              <button className="back-btn" onClick={() => setActiveSession(false)}>Cancel</button>
              <button type="button" className="btn btn-login btn-confirm" onClick={(e) => handleSession(e)}>
                Confirm
              </button>
            </div>
          </Modal>
        }

      </div>

      {/* Footer */}
      <Footer />
    </>
  );
}

export default LoginScreen;
