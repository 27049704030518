import React, { useState, useEffect } from 'react';
import Modal from 'react-bootstrap/Modal'
import { Link, useLocation, useParams } from "react-router-dom";
import { SidePanel, ProfileHeader } from "@components";
import Preloader from '../../plugins/Preloader';
import edit_pen from '../../assets/icons/edit-pen.svg';
import email_icon from '../../assets/icons/email_icon.svg';
import axiosInstance from '../../helpers/axiosConfig';
import { decryptText, encryptText } from '../../helpers/DynamicFunctions';
import Constants from '../../helpers/Constants';
import reducersAndActions from '../../_redux/slices';
import { useDispatch } from 'react-redux';
function PersonalProfile() {
  const dispatch = useDispatch()

  const [loading, setLoading] = useState(true);

  useEffect(() => {
    dispatch(reducersAndActions.actions.sessionActions.setLoading(loading));
  }, [loading]);

  const [personalProfileData, setPersonalProfileData] = useState([])

  useEffect(() => {
    document.title = "Personal Profile - Loan Application";
    getInquiries();
  }, []);

  const [sentEmailPopupShow, setSentEmailPopup] = useState(false);
  const [loanIddata, setLoanIddata] = useState()
  const [emailSenderData, setEmailSenderData] = useState({})
  const sentEmailPopupClose = () => setSentEmailPopup(false);

  const Emailsender = async () => {
    try {
      setLoading(true);
      let oid = decryptText(params.loanId);
      loanId = userdetails ? userdetails.ApplicationNumber.slice(-4) : oid

      const storedSessionId = localStorage.getItem('sessionId');
      let WebURL = `/personal-profile/${encryptText(`${loanIddata}&${emailSenderData.ID}&${emailSenderData?.ApplicationNumber}&Guest`)}`

      const apiUrl = '/api/LoanApplication/SendOwnerEmailonPersonalResume';
      const queryParams = `?LoanId=${emailSenderData.loanId}&SessionId=${storedSessionId}&OwnerId=${emailSenderData.ID}&Email=${emailSenderData.Email}&WebURL=${WebURL}`;

      let response = await axiosInstance.get(`${apiUrl}${queryParams}`)

      setLoading(false);
      if (response.status == '200') {
        setEmailSenderData({})
        dispatch(reducersAndActions.actions.toasterActions.showToaster({ visible: true, message: response.data.message, type: "success" }));
        setTimeout(() => dispatch(reducersAndActions.actions.toasterActions.hideToaster()), Constants.TOASTERHIDETIME);
      } else {
        dispatch(reducersAndActions.actions.toasterActions.showToaster({ visible: true, message: response.message, type: 'error' }));
        setTimeout(() => dispatch(reducersAndActions.actions.toasterActions.hideToaster()), Constants.TOASTERHIDETIME);
      }
    } catch (err) {
      console.log(err);
      dispatch(reducersAndActions.actions.toasterActions.showToaster({ visible: true, message: err.message, type: 'error' }));
      setTimeout(() => dispatch(reducersAndActions.actions.toasterActions.hideToaster()), Constants.TOASTERHIDETIME);
    }
  }
  const sentEmailPopupConfirm = () => {
    Emailsender()
    setSentEmailPopup(false)
  }

  const location = useLocation()
  const params = useParams()
  var userdetails = location.state?.data

  var loanId;
  const getInquiries = async () => {
    try {
      let oid = decryptText(params.loanId);
      if (userdetails) {
        loanId = userdetails.data ? userdetails.data.loanId : userdetails.ApplicationNumber.slice(-4)
      } else {
        loanId = oid;
      }

      setLoanIddata(loanId)
      const storedSessionId = localStorage.getItem('sessionId');
      setLoading(true);

      const apiUrl = '/api/LoanApplication/GetOwnersListofPersonalResumeById';
      const queryParams = `?LoanId=${loanId}&SessionId=${storedSessionId}`;

      let response = await axiosInstance.get(`${apiUrl}${queryParams}`)
      let data = response.data.resultObject ? JSON.parse(response.data.resultObject) : [];

      setLoading(false)
      setPersonalProfileData(data)
    } catch (err) {
      setLoading(false)
      console.log(err);
      dispatch(reducersAndActions.actions.toasterActions.showToaster({ visible: true, message: err.message, type: 'error' }));
      setTimeout(() => dispatch(reducersAndActions.actions.toasterActions.hideToaster()), Constants.TOASTERHIDETIME);
    }
  }

  const [sortConfig, setSortConfig] = useState({ key: null, direction: 'ascending' });

  const sortedData = React.useMemo(() => {
    let sortableItems = [...personalProfileData];
    if (sortConfig !== null) {
      sortableItems.sort((a, b) => {
        if (a[sortConfig.key] < b[sortConfig.key]) {
          return sortConfig.direction === 'ascending' ? -1 : 1;
        }
        if (a[sortConfig.key] > b[sortConfig.key]) {
          return sortConfig.direction === 'ascending' ? 1 : -1;
        }
        return 0;
      });
    }
    return sortableItems;
  }, [personalProfileData, sortConfig]);

  const requestSort = (key) => {
    let direction = 'ascending';
    if (sortConfig.key === key && sortConfig.direction === 'ascending') {
      direction = 'descending';
    }
    setSortConfig({ key, direction });
  };

  return (
    <>
      {/* SidePanel */}
      <SidePanel />

      {/* ProfileHeader */}
      <ProfileHeader />

      {loading ? (<Preloader />) : (<div className='pb-5'>

        {/* <!-- owner form card --> */}
        <div className="lm-card p-0">
          <div className="d-flex justify-content-between p-3">
            <h3 className="lm-card-heading mb-0">Owners</h3>
          </div>

          <table className="lm-inner-form-grid">
            <thead>
              <tr>
                <th>
                  <div className="d-flex pointer-cursor" onClick={() => requestSort('Name')}>
                    Name
                    <span className="sort-arrow">
                      <div className={`lm-angle-up-icon ${sortConfig.key === 'Name' && sortConfig.direction === 'ascending' ? 'active' : ''}`}></div>
                      <div className={`lm-angle-down-icon ${sortConfig.key === 'Name' && sortConfig.direction === 'descending' ? 'active' : ''}`}></div>
                    </span>
                  </div>
                </th>
                <th>
                  <div className="d-flex pointer-cursor " onClick={() => requestSort('Title')}>
                    Title
                    <span className="sort-arrow">
                      <div className={`lm-angle-up-icon ${sortConfig.key === 'Title' && sortConfig.direction === 'ascending' ? 'active' : ''}`}></div>
                      <div className={`lm-angle-down-icon ${sortConfig.key === 'Title' && sortConfig.direction === 'descending' ? 'active' : ''}`}></div>
                    </span>
                  </div>
                </th>
                <th>
                  <div className="d-flex pointer-cursor " onClick={() => requestSort('Email')}>
                    Email
                    <span className="sort-arrow">
                      <div className={`lm-angle-up-icon ${sortConfig.key === 'Email' && sortConfig.direction === 'ascending' ? 'active' : ''}`}></div>
                      <div className={`lm-angle-down-icon ${sortConfig.key === 'Email' && sortConfig.direction === 'descending' ? 'active' : ''}`}></div>
                    </span>
                  </div>
                </th>
                <th>
                  <div className="d-flex pointer-cursor " onClick={() => requestSort('Ownership')}>
                    Ownership (%)
                    <span className="sort-arrow">
                      <div className={`lm-angle-up-icon ${sortConfig.key === 'Ownership' && sortConfig.direction === 'ascending' ? 'active' : ''}`}></div>
                      <div className={`lm-angle-down-icon ${sortConfig.key === 'Ownership' && sortConfig.direction === 'descending' ? 'active' : ''}`}></div>
                    </span>
                  </div>
                </th>
                <th></th>
              </tr>
            </thead>
            <tbody>
              {
                sortedData.map((data) => {
                  data.loanId = loanIddata;
                  data.params = params;
                  if (data) {
                    return (
                      <tr key={data.Email}>
                        <td>{data.Name} {data.LastName}</td>
                        <td>
                          {data.Title}
                        </td>
                        <td>{data.Email}</td>
                        <td>{data.Ownership}</td>
                        <td className="text-right pr-4">
                          <Link title="Send Email" href="#" onClick={() => {
                            setSentEmailPopup(true);
                            setEmailSenderData(data);
                          }}><img className="lm-grid-edit-icon mr-2" src={email_icon} alt="" /></Link>
                          <Link to={`/personal-profile/${encryptText(`${loanIddata}&${data.ID}&${data.ApplicationNumber}&User`)}`} title="Edit"><img className="lm-grid-edit-icon" src={edit_pen} alt="" /></Link>
                        </td>
                      </tr>
                    );
                  }

                })
              }
            </tbody>
          </table>
        </div>
        {/* <!-- owner form card --> */}

        {/* <!--sent an email Popup / Modal --> */}
        <Modal
          className='mark-comp-popup-cust-size'
          show={sentEmailPopupShow}
          onHide={() => setSentEmailPopup(false)}
          aria-labelledby="sentEmailPopupLabel"
          backdrop="static"
          keyboard={false}
          centered
        >
          <div className="modal-dialog modal-dialog-centered modal-dialog-scrollable">
            <div className="modal-content">
              <div className="modal-body signout-modal">
                <h3 className="pt-4">
                  Are you sure you want to sent an email?
                </h3>
              </div>
              <div className="modal-footer la-app-modal-footer">
                <button type="button" className="lm-button2" onClick={sentEmailPopupClose}>Cancel</button>
                <button type="button" className="lm-button1" onClick={sentEmailPopupConfirm}>Confirm</button>
              </div>
            </div>
          </div>
        </Modal>
        {/* <!--sent an email Popup / Modal --> */}

      </div>)}

    </>
  );
}

export default PersonalProfile;