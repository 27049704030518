import React from 'react';
import { Modal } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import reducersAndActions from './_redux/slices/index'; // Adjust the path to reducersAndActions

const SessionManagement = () => {
    const dispatch = useDispatch();
    const { sessionActions } = reducersAndActions.actions;

    // Access session state from Redux
    const showWarning = useSelector((state) => state.session.showWarning);
    const isLoading = useSelector((state) => state.session.isLoading);

    // Dispatch actions for confirming session and clearing timeout
    const handleConfirmSession = () => {
        dispatch(sessionActions.handleConfirmSession(dispatch));
    };

    const clearSessionTimeout = () => {
        dispatch(sessionActions.clearSessionTimeout());
    };

    return (
        <>
            <Modal
                className='mark-comp-popup-cust-size'
                show={showWarning && !isLoading}
                onHide={clearSessionTimeout}
                aria-labelledby="markCompletePopupLabel"
                backdrop="static"
                keyboard={false}
                centered
            >
                <div className="modal-dialog modal-dialog-centered modal-dialog-scrollable">
                    <div className="modal-content">
                        <div className="modal-body signout-modal">
                            <h3 className="pt-4">
                                Your session is about to expire. Do you want to extend your session?
                            </h3>
                        </div>
                        <div className="modal-footer la-app-modal-footer">
                            <button type="button" className="lm-button2" onClick={clearSessionTimeout}>Cancel</button>
                            <button type="button" className="lm-button1" onClick={handleConfirmSession}>Continue</button>
                        </div>
                    </div>
                </div>
            </Modal>
        </>
    );
};

export default SessionManagement;